// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store, select } from '@ngrx/store';
// Auth
import { AuthNoticeService, AuthService, Login, User } from '../../../../../@core/auth';
import { AppState } from '../../../../../@core/reducers';
import { LayoutConfigModel, LayoutConfigService, MenuAsideService, TranslationService } from '../../../../../@core/layout';
import { log } from 'console';

interface LanguageFlag {
	lang: string;
	name: string;
	flag: string;
	active?: boolean;
}
/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
	EMAIL: '',
	PASSWORD: ''
};


@Component({
  selector: 'kt-login-v2',
  templateUrl: './login-v2.component.html',
  styleUrls: ['./login-v2.component.scss']
})
export class LoginV2Component implements OnInit {
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	language: LanguageFlag;

	languages: LanguageFlag[] = [
		{
			lang: 'en',
			name: 'English',
			flag: './assets/media/flags/012-uk.svg'
		},
		{
			lang: 'es',
			name: 'Spanish',
			flag: './assets/media/flags/016-spain.svg'
		},
		{
			lang: 'vn',
			name: 'Việt Nam',
			flag: './assets/media/flags/001-vietnam.svg'
		},
	];

	private unsubscribe: Subject<any>;

	private returnUrl: any;
  public logo:string;
  public layout:LayoutConfigModel;
  public loginPage:any;
  public backgroundImage:string = './assets/media/bg/bg-8.jpg';
  public backgroundImageStyle:any;
  public iosUrl:string;
  public androidUrl:string;

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private translationService: TranslationService,
		private layoutConfigService: LayoutConfigService,
		private menuAsideService:MenuAsideService
	) {
		this.unsubscribe = new Subject();	
    	this.logo =  this.layoutConfigService.getLogo(); 
    	this.layout = this.layoutConfigService.getConfig();
		this.loginPage = this.layoutConfigService.getLoginPage();
		if(this.loginPage.background && this.loginPage.background.length > 0){
			this.backgroundImage = this.loginPage.background[0]
			this.backgroundImageStyle = { 'background-image': 'url(' + this.backgroundImage + ')' }
		}
		this.iosUrl = this.loginPage.iosUrl;
		this.androidUrl = this.loginPage.androidUrl;
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.initLoginForm();
		this.setSelectedLanguage();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params['returnUrl'] || '/';
		});
		
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	// language
	setLanguage(lang) {
		this.languages.forEach((language: LanguageFlag) => {
			if (language.lang === lang) {
				language.active = true;
				this.language = language;

			} else {
				language.active = false;
			}
		});
		this.translationService.setDefaultLang(lang);
		this.translationService.setLanguage(lang);
	}

	
	setSelectedLanguage(): any {
		this.setLanguage(this.translationService.getSelectedLanguage());
	}

	//end language

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {	
		this.loginForm = this.fb.group({
			email: ["", Validators.compose([
				Validators.required,
				// Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			],
			password: ["", Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const authData = {
			email: controls['email'].value,
			password: controls['password'].value
		};
		this.auth
			.newLogin(authData.email, authData.password)
			.pipe(
				tap(data => {
					let user = data.data as User;
					if (user.accessToken != null) {
						this.store.dispatch(new Login({ authToken: user.accessToken }));
						this.router.navigateByUrl(this.menuAsideService.getPageMainAfterLogin(user['pageMain'], this.returnUrl)); // Main page			
					} else {
						this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
					}
					
				},
				error=>{
					// INVALID_LOGIN
					// AUTH__EXPIRE_DATE  
					let messageCode ="MESSEAGE_CODE." +  error.error.messageCode.toUpperCase().replace("__", ".");
					let messageTranslate = this.translate.instant(messageCode);
					if(messageCode != messageTranslate){
						this.authNoticeService.setNotice(messageTranslate, 'danger');	
					}
					else {
						this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');	
					}					
				}),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			)
			.subscribe();
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
