// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Material
// import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule } from '@angular/material';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule, EffectsRootModule } from '@ngrx/effects';
// CRUD
// Module components
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { AuthNoticeComponent } from './auth-notice/auth-notice.component';
// Auth
import { AuthEffects, AuthGuard, AuthService, ModuleGuard, PermissionEffects, RoleEffects, authReducer, permissionsReducer, rolesReducer } from '../../@core/auth';

import { LogoutComponent } from './logout/logout.component';
// import { SimTypeService } from '../../../core/admin';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginV1Component } from './login/layout/login-v1/login-v1.component';
import { LoginV2Component } from './login/layout/login-v2/login-v2.component';
import { LoginV3Component } from './login/layout/login-v3/login-v3.component';
import { InterceptService } from '../../@core/models';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { NbLayoutModule, NbSelectModule, NbSidebarModule, NbThemeModule, NbToastrModule } from '@nebular/theme';

const routes: Routes = [
	{
		path: '',
		component: AuthComponent,		
		children: [
			{
				path: '',
				canActivate:[ModuleGuard],
				redirectTo: 'login',
				pathMatch: 'full'
			},
			{
				path: 'login',
				canActivate:[ModuleGuard],
				component: LoginComponent,
				data: {returnUrl: window.location.pathname}
			},
			{
				path: 'reset-password',
				component: ResetPasswordComponent
			},
			{
				path: 'register',
				component: RegisterComponent
			},
			{
				path: 'forgot-password',
				component: ForgotPasswordComponent,
			},
			{
				path: 'logout',
				component: LogoutComponent,
			}
		]
	}
];


@NgModule({
	imports: [		
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(routes),
		MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		NgbDropdownModule,
		TranslateModule.forChild(),
		StoreModule.forFeature('auth', authReducer),
		StoreModule.forFeature('permissions', permissionsReducer),
		// StoreModule.forFeature('roles', rolesReducer),
		EffectsModule.forFeature([AuthEffects,PermissionEffects]),
		NbThemeModule,
        NbSidebarModule,
        NbToastrModule,
        NbLayoutModule,
        NbSelectModule  
	],
	providers: [
		InterceptService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true
		},
	],
	exports: [
		AuthComponent,
		
	],
	declarations: [
		AuthComponent,
		LoginComponent,
		RegisterComponent,
		ForgotPasswordComponent,
		AuthNoticeComponent,
		LogoutComponent,
		ResetPasswordComponent,
		LoginV1Component,
		LoginV2Component,
		LoginV3Component,
		SplashScreenComponent
	],

})

export class AuthModule {
	static forRoot(): ModuleWithProviders<AuthModule> {
		return {
			ngModule: AuthModule,
			providers: [
				AuthService,
				AuthGuard,
				ModuleGuard
			]
		};
	}
}
