import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorCustomService {

  constructor() { }
  select(c: AbstractControl) {
    if (c.value.length == 0) {
      return { select: true }
    }
    return null;
  }

  number(c: AbstractControl) {
    if (Number.parseInt(c.value) == c.value) {
      return null;
    }
    return { number: true }
  }
  float(c: AbstractControl) {
    if (Number.parseFloat(c.value) == c.value) {
      return null;
    }
    return { number: true }
  }
  formula(c: AbstractControl) {
    if (!String(c.value).includes("[value]")) {
      return { formula: true }
    }
    else {
      let formula = c.value;
      while (String(formula).includes("[value]")) {
        formula = String(formula).replace("[value]", "100");
      }
      try {
        let result = eval(formula);
        if (Number.parseFloat(result) == Infinity) {
          return { formula: true }
        }
      }
      catch{
        return { formula: true }
      }
    }

    return null;

  }
  calibration(c: AbstractControl) {
    if (String(c.value).trim().length > 0) {
      let temp = c.value.replace(/\r|\t| /g, "").replace(/;/g, "\n");
      let arr = temp.split('\n');
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] != "") {
          let arrDetail = arr[i].split(',');
          if (arrDetail.length != 2) {
            return { calibration: true }
          }
          if (isNaN(Number.parseInt(arrDetail[0])) || isNaN(Number.parseInt(arrDetail[1]))) {
            return { calibration: true }
          }
        }

      }
    }
    return null;
  }
  rangeLength(min: number, max: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (String(c.value).trim().length > 0) {
        let value = String(c.value).trim();
        if (value.length < min || value.length > max) {
          return { rangeLength: { min: min, max: max } };
        }
      }

      return null;
    };
  }
  minLength(min: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (String(c.value).trim().length > 0) {
        let value = String(c.value).trim();
        if (value.length < min) {
          return { minLength: { min: min } };
        }
      }
      return null;
    };

  }
  maxLength(max: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (String(c.value).trim().length > 0) {
        let value = String(c.value).trim();
        if (value.length > max) {
          return { maxLength: { max: max } };
        }
      }

      return null;
    };
  }
  required(c: AbstractControl) {
    if ( String(c.value).trim().length == 0) {
      return { required: true }
    }
    return null;
  }
  
  max(max: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (String(c.value).length > 0) {
        let number = Number.parseInt(c.value);
        if (Number.parseInt(c.value) == c.value) {
          if (number > max) {
            return { max: { max: max } };
          }
        }
        else {
          return { max: { max: max } };
        }
      }

      return null;
    };

  }
  maxFloat(max: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (String(c.value).length > 0) {
        let number = Number.parseFloat(c.value);
        if (Number.parseFloat(c.value) == c.value) {
          if (number > max) {
            return { max: { max: max } };
          }
        }
        else {
          return { max: { max: max } };
        }
      }

      return null;
    };

  }
  min(min: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (String(c.value).length > 0) {
        let number = Number.parseInt(c.value);
        if (Number.parseInt(c.value) == c.value) {
          if (number < min) {
            return { min: { min: min } };
          }
        }
        else {
          return { min: { min: min } };
        }
      }

      return null;
    };
  }
  minFloat(min: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (String(c.value).length > 0) {
        let number = Number.parseFloat(c.value);
        if (Number.parseFloat(c.value) == c.value) {
          if (number < min) {
            return { min: { min: min } };
          }
        }
        else {
          return { min: { min: min } };
        }
      }

      return null;
    };
  }
  min2(min: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (String(c.value).length > 0) {
        let number = Number.parseFloat(c.value);
        if (Number.parseFloat(c.value) == c.value) {
          if (number < min) {
            return { min: { min: min } };
          }
        }
        else {
          return { min: { min: min } };
        }
      }
      return null;
    };
  }
  range(min: number, max: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (String(c.value).length > 0) {
        let number = Number.parseInt(c.value);
        if (Number.parseInt(c.value) == c.value) {
          if (number < min || number > max) {
            return { range: { min: min, max: max } };
          }
        }
        else {
          return { range: { min: min, max: max } };
        }
      }
      return null;
    };
  }
  phone(c: AbstractControl) {
    if(typeof c.value == "string"){
      let value = String(c.value);
      if (c.value !=null && c.value !=undefined  && value.length > 0) {
        if (value.length > 15 || value.length < 8) {
          return { phone: true };
        }
      }
    }    
    return null;
  }
  phones(c: AbstractControl) {
    if(typeof c.value == "string"){ 
      let value = String(c.value);
      if (c.value !=null && c.value !=undefined  && value.length > 0) {
        let arr:Array<string> = value.split(",");
        for (let i = 0; i < arr.length; i++) {
          const x = arr[i].trim();
          if (x.length > 0 &&(x.length > 15 || x.length < 8)) {
            return { phone: true };
          }
        }             
      }
    }    
    return null;
  }  
  emails(c: AbstractControl) {    
    if(typeof c.value == "string"){
      let value = String(c.value);
      if (c.value !=null && c.value !=undefined  && value.length > 0) {       
        let arr:Array<string> = value.split(",");             
        for (let i = 0; i < arr.length; i++) {
          const x = arr[i].trim();
          const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/igm;
          if (x.length > 0 && !regex.test(x)) {           
            return { email: true };                          
          }
        }         
      }        
    }   
    return null;
  }
  email(c: AbstractControl) {
    if(typeof c.value == "string"){
      let value = String(c.value);
      if (value.length > 0) {
        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/igm;
        if (value.length > 0 && !regex.test(value)) {
          return { email: true };
        }
      }
    }
   
    return null;
  }
  password(passNew:string,passComfirm:string = '')
  {
    if(typeof passNew == "string"){
      if(passNew != '' && passNew != null && passNew != undefined)
      {
        if(passNew.length < 6)
        {
          return { password:false};
        }
        if(passNew != passComfirm)
        {
          return {confirm:false}
        }
      }
    }

    return {password:true};

  }
  checkEmail(email:string)
  {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  divisionBy(division: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (String(c.value).length > 0) {
        let number = Number.parseInt(c.value);
        if (Number.parseInt(c.value) == c.value) {
          if (number%division !==0) {
            return { division: { division: division } };
          }
        }
        else {
          return { division: { division: division } };
        }
      }

      return null;
    };
  }
}
