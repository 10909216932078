// Angular
import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';

@Pipe({
  name: 'currencyPipe'
})
export class CurrencyPipe implements PipeTransform {
  constructor() {
 
  }

  transform(value: number): any {
    return new Intl.NumberFormat('en-US').format(value);
  }

}