import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import { BaseOptionAPI } from '../../@core/models/query-models/_base.option-api';
import { Observable } from 'rxjs';
import { TypesUtilsService } from '../../@core/models';
import { CurrentUserService } from './current-user.service';
import { DataUtil } from '../utils/data-utils';
const API_URL: string = environment.api.host;

@Injectable({
  providedIn: 'root'
})
export class MapUserService {

  constructor(private http: HttpClient,private utils: TypesUtilsService, private currentUserService: CurrentUserService,
    private dataUtil: DataUtil) { }

  getAllConfig(params) {
    return this.http.get(API_URL + '/settings/devices/config-user', {params: params});
  }
  getConfig() {
    return this.http.get(API_URL + '/settings/devices/config');
  }
  getEvCharge(body) {
    const userCurrent:any = this.currentUserService.currentUser;
    if(userCurrent && userCurrent.type == 3){
      body.status = [0,1]
    }
    return this.http.post(API_URL + '/map/ev-charge', this.dataUtil.removeNullUndefined(body));
  }
  getEvDetail(id) {
    return this.http.get(API_URL + `/map/ev-charge/${id}`);
  }
  getRelatedEv(body, params) {
    return this.http.post(API_URL + '/map/ev-charge/nearest', body, {params});
  }
  chargePhoto(body, params) {
    return this.http.post(API_URL + '/settings/devices/charge-photo', body, {params});
  }
  deleteChargePhoto( params) {
    return this.http.delete(API_URL + '/settings/devices/charge-photo', {params});
  }
  changeEvChargeStatus(param: any, option?: BaseOptionAPI): Observable<Response> {
    return this.http.post<Response>(API_URL + '/map/ev-charge/change-status', param, {
      params: this.utils.processBassOptionApi(option),
    });
  }
}
