// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * https://github.com/AndrewPoyntz/time-ago-pipe
 * An Angular pipe for converting a date string into a time ago
 */
@Pipe({ name: 'filter' })

export class FilterPipe implements PipeTransform {
	/**
	 * Transform
	 *
	 * @param value: string
	 */
	transform(items: any[], searchText: string,field:string = 'name', group:boolean = false): any[] {
		// console.log('items',items);

		if(!items) return [];
		if(!searchText) return items;
		// let data = JSON.parse(JSON.stringify(items));

		searchText = searchText.toLowerCase();
		if(group)
		{
			return items.filter(it=>{
				let found = false;
			    it.listDevice.filter(device=>{
					if(device[field].toLowerCase().includes(searchText)) found = true;
					return device[field].toLowerCase().includes(searchText);
				});
				// console.log('list-device',it.listDevice);
				return found;
			})
		
		}else
		{
			return items.filter( it => {
			  return it[field].toLowerCase().includes(searchText);
			});
		}

	   } 
	

}
