<!--begin::Head-->

<div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1" id="kt_login">
	<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
		<div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper"
			[ngStyle]="backgroundImageStyle">
			<div class=" select-default-language kt-align-right">

				<div ngbDropdown placement="bottom-right" class="kt-header__topbar-item kt-header__topbar-item--langs">
					<div ngbDropdownToggle class="kt-header__topbar-wrapper">
						<span class="kt-header__topbar-icon" style="cursor: pointer;">
							<img class="" src="{{language?.flag}}" alt="" /> <span>{{language.name}}</span>
						</span>
					</div>
					<div ngbDropdownMenu aria-labelledby="dropdownBasic1"
						class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
						<ul class="kt-nav kt-margin-t-10 kt-margin-b-10">
							<ng-container *ngFor="let language of languages">
								<li class="kt-nav__item" [ngClass]="{'kt-nav__item--active': language.active}">
									<a ngbDropdownToggle href="javascript:;" (click)="setLanguage(language.lang)"
										[ngClass]="{'kt-nav__link--active': language.active}" class="kt-nav__link">
										<span class="kt-nav__link-icon">
											<img src="{{language.flag}}">
										</span>
										<span class="kt-nav__link-text">{{language.name}}</span>
									</a>
								</li>
							</ng-container>
						</ul>
					</div>
				</div>
			</div>

			<!--end::Head-->

			<!--begin::Body-->
			<div class="login-body">
				<div class="kt-login__body">
					<!--begin::Signin-->
					<div class="kt-login__form">
						<div class="kt-login__logo" style="text-align: center;">
							<a href="javascript:;" class="">
								<img [src]="logo" alt="" style="max-width: 100px;max-height: 150px;">
							</a>
						</div>
						<div class="kt-login__title">
							<h3 class="login-title">{{ 'AUTH.GENERAL.SIGNIN' | translate }}</h3>
						</div>

						<kt-auth-notice></kt-auth-notice>
						<form [formGroup]="loginForm" novalidate="novalidate">
							<div class="form-group validate is-invalid">
								<label class="label-login">{{ 'AUTH.INPUT.USERNAME' | translate }}</label>
								<input type="text" formControlName="email" name="email" autocomplete="off"
									class="form-control input-login" placeholder="{{ 'AUTH.INPUT.USERNAME' | translate }}"/>
								<div class="error invalid-feedback" *ngIf="isControlHasError('email','required')">
									<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate}}</strong>
								</div>
								<!-- <mat-error *ngIf="isControlHasError('email','email')">
									<strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
								</mat-error> -->
								<div class="error invalid-feedback" *ngIf="isControlHasError('email','minLength')">
									<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
								</div>
								<div class="error invalid-feedback" *ngIf="isControlHasError('email','maxLength')">
									<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
								</div>
							</div>
							<div class="form-group validate is-invalid" style="margin-bottom: 0.5rem!important;">
								<label class="label-login">{{ 'AUTH.INPUT.PASSWORD' | translate }}</label>
								<input type="password" formControlName="firstName" class="form-control input-login" name="password"
									formControlName="password" autocomplete="off" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"/>
								<div class="error invalid-feedback" *ngIf="isControlHasError('password','required')">
									<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
								</div>
								<div class="error invalid-feedback" *ngIf="isControlHasError('password','minLength')">
									<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
								</div>
								<div class="error invalid-feedback" *ngIf="isControlHasError('password','maxLength')">
									<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
								</div>
							</div>
							<!--begin::Action-->
							<div class="kt-login__actions" style="margin-top: 0.5rem;">
								<a href="javascript:;" routerLink="/auth/forgot-password"
									class="kt-link kt-login__link-forgot label-login">
									{{ 'AUTH.GENERAL.FORGOT_BUTTON' | translate }}
								</a>
							</div>
							<div class="kt-login__actions">
								<button (click)="submit()"
									[ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}"
									id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary">
									{{'AUTH.LOGIN.BUTTON' | translate }}</button>
							</div>
							<!--end::Action-->
						</form>
						<!--begin::Form-->

						<!--end::Form-->

						<div class="kt-login__divider">
							<div class="kt-divider">
								<span></span>
								<span style="color: #67666e;">{{'AUTH.DOWNLOAD.TITLE' | translate}}</span>
								<span></span>
							</div>
						</div>

						<div class="kt-login__options">
							<a target=”_blank” href="{{iosUrl}}" class="btn btn-primary kt-btn a-login" style="margin-right: 15px;">
								<i style="font-size: 1.1rem;" class="icon-app-store-ios"></i>
								App Store
							</a>

							<a target=”_blank” href="{{androidUrl}}" class="btn btn-primary kt-btn a-login">
								<i style="font-size: 1.1rem;" class="socicon-android"></i>
								Google Play
							</a>
						</div>

					</div>
					<!--end::Signin-->
				</div>
			</div>
			<!--end::Body-->
		</div>
	</div>
</div>