import {ChangeDetectorRef, Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {finalize, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {NbDialogRef} from "@nebular/theme";
import { ServicePriceService } from '../../../shared/services/service-price.service';
import { CurrentUserService } from '../../../shared/services/current-user.service';
import { MENU_ITEMS } from '../../../pages/pages-menu';
import { UserManageService } from '../../../@core/services/user-manage.service';
import { AuthService } from '../../../@core/auth';

const TITLE_FORM_ADD: string = "COMMON.ACTIONS.ADD";
const TITLE_FORM_DELETE: string = "COMMON.ACTIONS.DELETE";

interface JQuery {
  selectpicker: () => void;
}

@Component({
  selector: 'kt-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],

})
export class UserProfileComponent {
  saveForm: FormGroup;
  user: any;
  private unsubscribe: Subject<any> = new Subject();
  listPageMain = MENU_ITEMS.filter(e => e.data && e.data.pageMain);
  listTimeZone: any = [];
  languageList: any = [];
  weekFirstDay: any = [];
  decimalSeprerator: any = [];
  unitVolume: any = [];
  unitWeight: any = [];
  unitTemperature: any = [];
  unitDistance: any = [];
  dateFormat: any = [];
  timeFormat: any = [];

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    protected dialogRef: NbDialogRef<UserProfileComponent>,
    private servicePriceService: ServicePriceService,
    private currentUserService: CurrentUserService,
    private userManage: UserManageService,
    private authService: AuthService
  ) {
    this.saveForm = this.fb.group({
      username: [null, [Validators.required]],
      name: [null, Validators.required],
      phone: [null],
      email: [null, [Validators.email]],
      description: [null],
      address: [null],
      pageMain: [null],
      timezone: [null],
      language: [null],
      distance: [null],
      volume: [null],
      weight: [null],
      date: [null],
      time: [null],
      temperature: [null],      
    });
    
  }
  
  ngOnInit() {
    this.listTimeZone = this.currentUserService.listTimeZone;
    this.languageList = this.currentUserService.listLanguage;
    this.weekFirstDay = this.currentUserService.listWeekFirst;
    this.decimalSeprerator = this.currentUserService.listDecimal;
    this.unitVolume = this.currentUserService.listVolume;
    this.unitWeight = this.currentUserService.listWeight;
    this.unitDistance = this.currentUserService.listDistance;
    this.unitTemperature = this.currentUserService.listTemperature;
    this.timeFormat = this.currentUserService.listTimeFormat;
    this.dateFormat = this.currentUserService.listDateFormat;
    this.authService.profile({}).subscribe((data) => {
      this.user = data.data
      this.saveForm.patchValue({
        ...this.user,
        timezone: this.user.timezone,
        language: this.user.language,
        distance: this.user.unitDistance,
        volume: this.user.unitVolume,
        temperature: this.user.unitTemperature,
        weight: this.user.unitWeight,
        date: this.user.dateFormat,
        time: this.user.timeFormat,
        role: this.user.roleId,
      });
    });
  }

  ngOnDestroy(){
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onSubmit(form: any) {
    if (this.saveForm.invalid) {
      return;
    }
    const params = {
      username: form.value.username,
      name: form.value.name,
      phone: form.value.phone,
      pageMain: form.value.pageMain,
      email: form.value.email,
      description: form.value.description,
      address: form.value.address,
      timezone: form.value.timezone,
      language: form.value.language,
      unitDistance: form.value.distance,
      unitVolume: form.value.volume,
      unitTemperature: form.value.temperature,
      unitWeight: form.value.weight,
      dateFormat: form.value.date,
      timeFormat: form.value.time,
    } as any;
      this.userManage.updateProfile(params, { notifyGlobal: true }).takeUntil(this.unsubscribe).subscribe((result: any) => {
        if (result.errorCode === '200') {
          this.dialogRef.close(1);
        }
      });
  }

  loadItemEdit(id: any) {
    this.servicePriceService.getDetail(id).pipe(
      takeUntil(this.unsubscribe),
      finalize(() => {
        this.cdr.markForCheck();
      }),
    ).subscribe((result: any) => {
        if (result.errorCode === '200') {
          const data = result.data;
          this.saveForm.patchValue({
            name: data.name,
            electricityPrice: data.electricityPrice,
            parkingPrice: data.parkingPrice,
            occupiedChargePrice: data.occupiedChargePrice,
            description: data.description
          });
        }
      });
  }
}
