import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  private listPage: itemPage[] =
    [
      {
        id: 2,
        title: 'Report route',
        page: '/reports/route',
        permission: 'ROLE_report.route',
        translate: 'MENU.REPORT_ROUTE',
        icon: 'kt-menu__link-icon icon-route',
        type: ['report']

      },
      {
        id: 109,
        title: 'Report general',
        page: '/reports/summary-devices',
        permission: 'ROLE_report.general',
        translate: 'MENU.REPORT_GENERAL',
        icon: 'kt-menu__link-icon icon-book',
        type: ['report']
      },
      {
        id: 1091,
        title: 'Report temperature',
        page: '/reports/temperature',
        permission: 'ROLE_report.temperature',
        translate: 'MENU.REPORT_TEMPERATURE',
        icon: 'kt-menu__link-icon icon-temperature-high',
        type: ['report']
      },
      //  {
      //   id:11011,
      //   title: 'Report Summary by day',
      //   page: '/reports/summary-by-days',
      //   permission: 'ROLE_report.summary_day',
      //   translate: 'MENU.REPORT_SUMMARY_DAY',
      //   icon:'kt-menu__link-icon icon-list-alt',
      //   type: ['report']
      //  },
      {
        id: 99,
        title: 'Report trip detail',
        page: '/reports/trip-detail',
        permission: 'ROLE_report.trip_detail',
        translate: 'REPORT.TRIP.DETAIL.NAME',
        icon: 'kt-menu__link-icon icon-deviantart',
        type: ['report']
      },
      {
        id: 98,
        title: 'Report trip summary',
        page: '/reports/trip-summary',
        permission: 'ROLE_report.trip_summary',
        translate: 'REPORT.TRIP.GENERAL.NAME',
        icon: 'kt-menu__link-icon icon-deviantart',
        type: ['report']
      },
      {
        id: 122,
        title: 'Report geofence summary',
        page: '/reports/geofence-summary',
        permission: 'ROLE_report.geofence_summary',
        translate: 'MENU.REPORT_GEOFENCE_SUMMARY',
        icon: 'kt-menu__link-icon icon-polygon',
        type: ['report']
      },
      {
        id: 123,
        title: 'Report geofence detail',
        page: '/reports/geofence-detail',
        permission: 'ROLE_report.geofence_detail',
        translate: 'MENU.REPORT_GEOFENCE_DETAIL',
        icon: 'kt-menu__link-icon icon-pentagon',
        type: ['report']
      },
      {
        id: 110,
        title: 'Report data log',
        page: '/reports/history',
        permission: 'ROLE_report.data_log',
        translate: 'MENU.DATA_LOG',
        icon: 'kt-menu__link-icon icon-database',
        type: ['report']
      },
      //  {
      //   id:21233,
      //   title: 'Report route simple',
      //   page: '/reports/route-simple',
      //   permission: 'ROLE_report.route_simple',
      //   translate: 'MENU.REPORT_ROUTE_SIMPLE',
      //   icon:'kt-menu__link-icon icon-route',
      //   type: ['report']

      //  },
      // {
      //   id: 3,
      //   title: 'Report device',
      //   page: '/reports/device',
      //   permission: 'ROLE_report.device',
      //   translate: 'MENU.REPORT_DEVICE',
      //   icon: 'kt-menu__link-icon flaticon2-lorry',
      //   type: ['report']

      // },
      {
        id: 351,
        title: 'Report status history',
        page: '/reports/status-history',
        permission: 'ROLE_report.status_history',
        translate: 'MENU.REPORT_STATUS_HISTORY',
        icon: 'kt-menu__link-icon icon-history',
        type: ['report']

      },
      {
        id: 3511,
        title: 'Report toll station',
        page: '/reports/toll-station',
        permission: 'ROLE_report.toll_station',
        translate: 'MENU.REPORT_TOLL_FREE',
        icon: 'kt-menu__link-icon icon-tollbooth',
        type: ['report']

      },
      {
        id: 4,
        title: 'Report engine',
        page: '/reports/engine',
        permission: 'ROLE_report.engine',
        translate: 'MENU.REPORT_ENGINE',
        icon: 'kt-menu__link-icon icon-speedometer-2',
        type: ['report']

      },
      // {
      //   id: 166,
      //   title: 'Stop point',
      //   page: '/reports/stop-point',
      //   permission: 'ROLE_report.stop_point',
      //   translate: 'MENU.REPORT_STOP_POINT',
      //   icon: 'icon-place-marker-2',
      //   col: "col-xl-6 col-lg-6",
      //   type: ['report']

      // },
      {
        id: 5,
        page: '/reports/reports',
        permission: 'ROLE_report.report',
        translate: 'MENU.REPORT',
        icon: 'flaticon2-graph',
        col: "col-xl-4 col-lg-12",
        type: ['dashboard']
      },
      {
        id: 6,
        page: '/map',
        permission: 'ROLE_map.map',
        translate: 'MENU.MAP',
        icon: 'flaticon2-pin',
        col: "col-xl-8 col-lg-6",
        type: ['dashboard']

      },
      {
        id: 17,
        title: 'Activity log',
        page: '/manage/activity-log',
        permission: 'ROLE_manage.activity_log',
        translate: 'MENU.ACTIVITY_LOG',
        icon: 'icon-history',
        col: "col-xl-4 col-lg-6",
        type: ['profile']

      },
      {
        id: 18,
        title: 'Api key',
        page: '/manage/map-api-key',
        permission: 'ROLE_manage.api_key',
        translate: 'MENU.MAP_API_KEY',
        icon: 'icon-rest-api',
        col: "col-xl-6 col-lg-6",
        type: ['profile']
      },
      {
        id: 7,
        title: 'Manage Command',
        page: '/manage/command',
        permission: 'ROLE_manage.command',
        translate: 'MENU.COMMAND',
        icon: 'icon-terminal',
        col: "col-xl-4 col-lg-6",
        type: ['dashboard']

      },
      {
        id: 8,
        title: 'Manage Device',
        page: '/manage/devices',
        permission: 'ROLE_manage.device',
        translate: 'MENU.MANAGER_DEVICE',
        icon: 'flaticon2-lorry',
        col: "col-xl-8 col-lg-6",
        type: ['dashboard']

      },
      {
        id: 9,
        title: 'Manage Alert',
        page: '/manage/notifications',
        permission: 'ROLE_device.action.command',
        translate: 'DASHBOARD.PARAMS.ALERTS',
        icon: 'la la-warning',
        col: "col-xl-4 col-lg-6",
        type: ['dashboard']
      },
      {
        id: 10,
        title: 'Manage Alert',
        page: '/manage/alert-rules',
        icon: 'icon-bell',
        permission: 'ROLE_manage.alert',
        translate: 'MANAGE.ALERT_RULE.GENERAL.ALERT_RULE',
        col: "col-xl-8 col-lg-6",
        type: ['dashboard']

      },
      {
        id: 11,
        page: '/manage/users',
        permission: 'ROLE_manage.user',
        translate: 'MENU.MANAGER_USER',
        icon: 'icon-user',
        col: "col-xl-6 col-lg-6",
        type: ['user', 'dashboard']
      },
      {
        id: 13,
        title: 'Role alias',
        page: '/manage/role-alias',
        permission: 'ROLE_manage.role_alias',
        translate: 'MENU.ROLE_ALIAS',
        icon: 'kt-menu__link-icon icon-key1',
        type: ['profile']
      },
      {
        id: 12,
        title: 'User Alias',
        page: '/manage/user-alias',
        permission: 'ROLE_manage.alias',
        translate: 'MENU.USER_ALIAS',
        icon: 'icon-user-alias',
        col: "col-xl-6 col-lg-6",
        type: ['user', 'profile', 'dashboard']

      },
      {
        id: 16,
        title: 'Send message',
        page: '/manage/message',
        permission: 'ROLE_manage.message',
        translate: 'MENU.MESSAGE',
        icon: 'icon-envelope-regular',
        col: "col-xl-6 col-lg-6",
        type: ['profile']

      },

      // qcvn
      {
        id: 10001,
        title: 'Qcvn route',
        page: '/reports/gov/journey',
        permission: 'ROLE_report.qcvn_route',
        translate: 'REPORT.QCVN.ROUTE.NAME_SIMPLE',
        icon: 'kt-menu__link-icon icon-route',
        col: "col-xl-6 col-lg-6",
        type: ['qcvn']

      },
      {
        id: 10002,
        title: 'Qcvn speed',
        page: '/reports/gov/speed',
        permission: 'ROLE_report.qcvn_speed',
        translate: 'REPORT.QCVN.SPEED.NAME_SIMPLE',
        icon: 'icon-tachometer-alt',
        col: "col-xl-6 col-lg-6",
        type: ['qcvn']

      },
      {
        id: 10003,
        title: 'Qcvn over speed',
        page: '/reports/gov/over-speed',
        permission: 'ROLE_report.qcvn_over_speed',
        translate: 'REPORT.QCVN.OVER_SPEED.NAME_SIMPLE',
        icon: 'icon-tachometer-alt',
        col: "col-xl-6 col-lg-6",
        type: ['qcvn']

      },

      {
        id: 10004,
        title: 'Qcvn driving 4h',
        page: '/reports/gov/driving',
        permission: 'ROLE_report.qcvn_driving_4h',
        translate: 'REPORT.QCVN.DRIVING_4H.NAME_SIMPLE',
        icon: 'icon-wheelchair',
        col: "col-xl-6 col-lg-6",
        type: ['qcvn']

      },
      {
        id: 10005,
        title: 'Qcvn Stop',
        page: '/reports/gov/stop',
        permission: 'ROLE_report.qcvn_stop',
        translate: 'REPORT.QCVN.STOP.NAME_SIMPLE',
        icon: 'icon-stop-circle',
        col: "col-xl-6 col-lg-6",
        type: ['qcvn']

      },
      {
        id: 10006,
        title: 'Qcvn summary by vehicle',
        page: '/reports/gov/summary-by-vehicle',
        permission: 'ROLE_report.qcvn_summary_by_vehicle',
        translate: 'REPORT.QCVN.VEHICLE.NAME_SIMPLE',
        icon: 'icon-car',
        col: "col-xl-6 col-lg-6",
        type: ['qcvn']

      },
      {
        id: 10007,
        title: 'Qcvn summary by driver',
        page: '/reports/gov/summary-by-driver',
        permission: 'ROLE_report.qcvn_summary_by_driver',
        translate: 'REPORT.QCVN.DRIVER.NAME_SIMPLE',
        icon: 'icon-user1',
        col: "col-xl-6 col-lg-6",
        type: ['qcvn']

      },
      {
        id: 10008,
        title: 'Qcvn driving 10h',
        page: '/reports/gov/driving-on-day',
        permission: 'ROLE_report.qcvn_driving_10h',
        translate: 'REPORT.QCVN.DRIVING_10H.NAME_SIMPLE',
        icon: 'icon-calendar-alt1',
        col: "col-xl-6 col-lg-6",
        type: ['qcvn']

      },
      {
        id: 10009,
        title: 'Qcvn data transmission',
        page: '/reports/gov/data-transmission',
        permission: 'ROLE_report.qcvn_data_transmission',
        translate: 'REPORT.QCVN.DATA_TRANSMISSION.NAME_SIMPLE',
        icon: 'icon-coins',
        col: "col-xl-6 col-lg-6",
        type: ['qcvn']

      },
      {
        id: 10010,
        title: 'Qcvn route image',
        page: '/reports/gov/journey-image',
        permission: 'ROLE_report.qcvn_route',
        translate: 'REPORT.QCVN.ROUTE_IMAGE.NAME_SIMPLE',
        icon: 'kt-menu__link-icon icon-images',
        col: "col-xl-6 col-lg-6",
        type: ['qcvn']

      },
      {
        id: 190,
        title: 'Fuel chart',
        page: '/fuel/chart',
        icon: 'icon-chart-bar1',
        permission: 'ROLE_fuel.chart',
        translate: 'REPORT.DATA_LOG.GENERAL.FUEL_GRAPH',
        type: ['fuel'],
      },
      {
        id: 191,
        title: 'report fuel',
        page: '/fuel/changes',
        icon: 'icon-gas-pump',
        permission: 'ROLE_report.fuel',
        translate: 'MENU.REPORT_FUEL',
        type: ['fuel'],
      },

      {
        id: 192,
        title: 'fuel summary',
        page: '/fuel/summary',
        icon: 'icon-eco-fuel',
        permission: 'ROLE_fuel.summary',
        translate: 'MAP.TRACKING.ROUTE.SUMMARY',
        type: ['fuel'],
      },
    ];

  constructor() { }

  getPageById(id) {
    return this.listPage.find(x => x.id == id);
  }

  getAllPage() {
    return this.listPage;
  }

  getListPageReports() {
    return this.filterByType('report');
  }

  filterByType(type) {
    let list = this.listPage.filter(function (page) {
      if (page.type.includes(type)) return page;
    });
    return list;
  }

  getListPageDashboard() {
    return this.filterByType('dashboard');
  }

  getListPageUser() {
    return this.filterByType('user');
  }

  getListPageProfile() {
    return this.filterByType('profile');
  }

  getListPageQCVN() {
    return this.filterByType('qcvn');

  }
  getListPageFuel() {
    return this.filterByType('fuel');
  }

}

export class itemPage {
  id: number;
  title?: string;
  page: string;
  permission: string;
  translate: string;
  icon: string;
  col?: string;
  type?: any[];
}