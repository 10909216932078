import { assign } from 'lodash';
/** Angular */
import { Injectable } from '@angular/core';

@Injectable()
export class TypesUtilsService {
	/**
	 * Convert number to string and addinng '0' before
	 *
	 * @param value: number
	 */
	padNumber(value: number) {
		if (this.isNumber(value)) {
			return `0${value}`.slice(-2);
		} else {
			return '';
		}
	}

	/**
	 * Checking value type equals to Number
	 *
	 * @param value: any
	 */
	isNumber(value: any): boolean {
		return !isNaN(this.toInteger(value));
	}

	/**
	 * Covert value to number
	 *
	 * @param value: any
	 */
	toInteger(value: any): number {
		return parseInt(`${value}`, 10);
	}

	/**
	 * Convert date to string with 'MM/dd/yyyy' format
	 *
	 * @param date: Date
	 */
	dateFormat(date: Date): string {
		const month = date.getMonth() + 1;
		const day = date.getDate();
		const year = date.getFullYear();
		if (date) {
			return `${month}/${day}/${year}`;
		}

		return '';
	}

	/**
	 * Convert Date to string with custom format 'MM/dd/yyyy'
	 *
	 * @param date: any
	 */
	dateCustomFormat(date: any): string {
		let stringDate: string = '';
		if (date) {
			stringDate += this.isNumber(date.month) ? this.padNumber(date.month) + '/' : '';
			stringDate += this.isNumber(date.day) ? this.padNumber(date.day) + '/' : '';

			stringDate += date.year;
		}
		return stringDate;
	}

	/**
	 * Convert string to DateFormatter (For Reactive Forms Validators)
	 *
	 * @param dateInStr: string (format => 'MM/dd/yyyy')
	 */
	getDateFormatterFromString(dateInStr: string): any {
		if (dateInStr && dateInStr.length > 0) {
			const dateParts = dateInStr.trim().split('/');
			return [
				{
					year: this.toInteger(dateParts[2]),
					month: this.toInteger(dateParts[0]),
					day: this.toInteger(dateParts[1])
				}
			];
		}

		const _date = new Date();
		return [
			{
				year: _date.getFullYear(),
				month: _date.getMonth() + 1,
				day: _date.getDay()
			}
		];
	}

	/**
	 * Convert string to Date
	 *
	 * @param dateInStr: string (format => 'MM/dd/yyyy')
	 */
	getDateFromString(dateInStr: string = ''): Date {
		if (dateInStr && dateInStr.length > 0) {
			const dateParts = dateInStr.trim().split('/');
			const year = this.toInteger(dateParts[2]);
			const month = this.toInteger(dateParts[0]);
			const day = this.toInteger(dateParts[1]);
			// tslint:disable-next-line:prefer-const
			let result = new Date();
			result.setDate(day);
			result.setMonth(month - 1);
			result.setFullYear(year);
			return result;
		}

		return new Date();
	}


	/**
	 * Convert Date to string with format 'MM/dd/yyyy'
	 * @param _date: Date?
	 */
	getDateStringFromDate(_date: Date = new Date()): string {
		const month = _date.getMonth() + 1;
		const year = _date.getFullYear();
		const date = _date.getDate();
		return `${month}/${date}/${year}`;
	}

	processBassOptionApi(option?: any) {
		let params = {};
		if (option && option.params) params = option.params;
		if (option && option.notifyGlobal) params['notifyGlobal'] = 'true';
		if (option && option.notifyGlobal &&  option.toastConfig) params['toastConfig'] =  option.toastConfig;	
		if (option && option.sessionStore) params['sessionStore'] = 'true';
		if (option && option.localStore) params['localStore'] = 'true';
		return params;
	}
	transformObject(object:any,model:any){
		Object.keys(object).forEach(x=>{
			if(object[x]==null || object[x]==undefined) delete object[x];
		});
		model = Object.assign({},model,object);
		return model;
	}

	transformData(object:any,model:any){
		Object.keys(model).forEach(x=>{
			if(model[x] != undefined && object[x] != undefined){
				if(x != 'basic'){
					object[x] = model[x];
				}
			}
		});
		const map = new Map(Object.entries(model.basic));
		let basic = object.basic;
		for (let index = 0; index < basic.length; index++) {
			const element = basic[index];
			if(map.has(element.type)){
				element.value = map.get(element.type);
			}else{
				element.value = 0;
			}
		}
		return object;
	}

	convertObjectForRequestApi(object:any,model:any){
		Object.keys(model).forEach(x=>{
			model[x] = object[x];
		});
		return model;
	}

	stringToISODateStr(str: string, format: string) {
		let strResult = "";
		if(str == "" || str == undefined || str == null) return strResult;
        let temp;
        switch (format) {
            case "dd/mm/yyyy":
                strResult = str.split("/").reverse().join('-');
                break;
            case "mm/dd/yyyy":
				temp = str.split("/");							
                if (temp.length == 3) {
                    strResult = temp[2] + "/" + temp[0] + "/" + temp[1];
                }
                break;
            case "yyyy/mm/dd":
                strResult = str;
                break;
            default:
                break;
        }
        return strResult;
    }

	objectToJson(object: any){
		return JSON.stringify(object);
	}
}
