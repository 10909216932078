import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UserSetting{
    public nameFavorite:string;
    constructor(){}

    setLocalStorageFavorite(favoriteReports){
        localStorage.setItem(this.nameFavorite,  JSON.stringify(favoriteReports));
    }

    checkLocalStorageFavorite(){
        if (localStorage.getItem(this.nameFavorite) === null)
        {
            return false;
        }
        return true;
    }

    getLocalStorageFavorite()
    {
        return JSON.parse(localStorage.getItem(this.nameFavorite));
    }
}
