import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TypesUtilsService } from '../../models/utils/types-utils.service';
import { environment } from '../../../../environments/environment';
import { ResponseBody } from '../../models/query-models/_response-body.model';

const API_ALERT = environment.api.host + "/alert/alert-rule";

@Injectable({
  providedIn:'root'
})
export class NotificationService {

  constructor(private http: HttpClient,private utils:TypesUtilsService) { }
  list(params:  any | undefined): Observable<ResponseBody> {    
    return this.http.get<ResponseBody>(API_ALERT, {params:params});
  }

  count(params:  any | undefined): Observable<ResponseBody>{
    return this.http.get<ResponseBody>(API_ALERT+'/unread/count', {params:params});
  }
 
  detail(id:number): Observable<ResponseBody>{

    return this.http.get<ResponseBody>(API_ALERT + '/'+id);
  }

}
