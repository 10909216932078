import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineMessageComponent } from './inline-message/inline-message.component';
import {CurrencyPipe, UserDatePipe} from "../@core/layout";
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import {
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
    NbListModule,
    NbOptionModule,
    NbSelectModule, NbTabsetModule
} from "@nebular/theme";
import {TranslateModule} from "@ngx-translate/core";
import { LanguageSelectorComponent } from './component/language-selector/language-selector.component';
import {NgbDropdown, NgbDropdownMenu, NgbDropdownToggle} from "@ng-bootstrap/ng-bootstrap";
import { EvPopupComponent } from './component/ev-popup/ev-popup.component';
import {SafeHtmlPipe} from "./safe-html.pipe";
import { ChargeStationImageViewComponent } from './component/charge-station-image-view/charge-station-image-view.component';
import {ManageModule} from "../pages/manage/manage.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LightboxModule} from "@ngx-gallery/lightbox";
import {GalleryModule} from "@ngx-gallery/core";



@NgModule({
    declarations: [
        InlineMessageComponent,
        ConfirmDialogComponent,
        LanguageSelectorComponent,
        UserDatePipe,
        EvPopupComponent,
        SafeHtmlPipe,
        ChargeStationImageViewComponent,
        CurrencyPipe
    ],
  exports: [
    InlineMessageComponent,
    LanguageSelectorComponent,
    UserDatePipe,
    SafeHtmlPipe,
    CurrencyPipe
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbButtonModule,
    TranslateModule,
    NbIconModule,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NbListModule,
    NbInputModule,
    NbOptionModule,
    NbSelectModule,
    NbTabsetModule,
    ReactiveFormsModule,
    LightboxModule,
    GalleryModule,
    FormsModule
  ],
  providers: [UserDatePipe, CurrencyPipe]
})
export class SharedModule { }
