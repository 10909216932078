// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';

@Injectable({
	providedIn: 'root'
  })
export class MenuAsideService {
	// Public properties
	menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	/**
	 * Service constructor
	 *
	 * @param menuConfigService: MenuConfigService
	 */
	constructor(private menuConfigService: MenuConfigService) {
		this.loadMenu();
	}

	/**
	 * Load menu list
	 */
	loadMenu() {
		// get menu list
		const menuItems: any[] = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
		this.menuList$.next(menuItems);
	}
	getMenus(){
		return objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
	}
	getPageMain(){
		let resutl = [];

		const menuItems: any[] = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
		menuItems.forEach((v)=>{
			if(v['main']=== true){
				resutl.push(v);
			}
			if(v['submenu']!=undefined){
				v['submenu'].forEach(sm=>{
					if(sm['main']=== true){
						resutl.push(sm);
					}
				})
			}
		})
		return resutl;
	}
	getPageMainDefault(){
		let pageMain = {
			page:"/map"
		};
		let pageMainDefault = this.getPageMain().find(x=>x.mainDefault === true);
		if(pageMainDefault){
			pageMain = pageMainDefault;
		}else{
			pageMain = this.getPageMain()[0];
		}
		return pageMain;
	}

  getPageMainAfterLogin(userConfig: string, returnUrl: string): string {
    let result = "/map";
    try {
      if (userConfig == "") {
        userConfig = "/map";
      }
      if (returnUrl != "/") {
        result = returnUrl;
      } else if (userConfig && userConfig.length > 0) {
        result = userConfig;
      } else result = returnUrl;
    } catch (ex) {
      console.log(ex);
    }
    return result;
  }


}
