import { BaseModel } from "../../models/query-models/_base.model";

export class Role extends BaseModel {
    id: number;
    name: string;
    key: string;
    parentId : number;
    description : string;
    listPermission : [];
    permissions :[];
    isCoreRole: boolean = false;

    clear(): void { 
        this.id = undefined;
        this.name = '';
        this.parentId = 1;
        this.description = '';
        this.listPermission = [];
        this.permissions = [];
        this.isCoreRole = false;
	}
}
