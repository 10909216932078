// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
// Services
import { AuthService } from '../_services';
// Actions
import {
    AllPermissionsLoaded,
    AllPermissionsRequested,
    PermissionActionTypes,
    PermissionInit,
} from '../_actions/permission.actions';
// Models
import { Permission } from '../_models/permission.model';

@Injectable()
export class PermissionEffects {
    loadAllPermissions$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<AllPermissionsRequested>(PermissionActionTypes.AllPermissionsRequested),
                mergeMap(() => this.auth.getAllPermissions()),
                map((result: Permission[]) => {
                    return new AllPermissionsLoaded({
                        permissions: result
                    });
                })
            )
    );

    init$: Observable<Action> = createEffect(() =>
        defer(() => {
            // return of(new AllPermissionsRequested());
            return of(new PermissionInit());
        })
    );

    constructor(private actions$: Actions, private auth: AuthService) { }
}
