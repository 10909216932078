import { Component } from '@angular/core';

@Component({
  selector: 'ngx-ev-popup',
  templateUrl: './ev-popup.component.html',
  styleUrls: ['./ev-popup.component.scss']
})
export class EvPopupComponent {
  data: any;
  constructor() {
  }
}
