
<!-- <div *ngIf="configLayout.self.login=='login-v1'" class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"  id="kt_login" >
	<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile" >		
		<div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper" style="background-image: url(./assets/media//bg/bg-3.jpg);height:100%;">
			<kt-login-v1 ></kt-login-v1>
		</div>
	</div>
</div> -->
<kt-login-v1 *ngIf="configLayout.self.login=='login-v1'"></kt-login-v1>
<kt-login-v2 *ngIf="configLayout.self.login=='login-v2'"></kt-login-v2>
<kt-login-v3 *ngIf="configLayout.self.login=='login-v3'"></kt-login-v3>