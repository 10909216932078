// NGRX
import { Action } from '@ngrx/store';
// Models
import { Permission } from '../_models/permission.model';

export enum PermissionActionTypes {
    AllPermissionsRequested     = '[Permission Requested ] Action',
    AllPermissionsLoaded        = '[Permission Loaded] Auth API',
    PermissionsInit             = '[Permisison Init] Permissions ',
    PermissionLogOut            = '[Permission Log out] Log out'
}

export class AllPermissionsRequested implements Action {
    readonly type = PermissionActionTypes.AllPermissionsRequested;
}
export class PermissionInit implements Action {
    readonly type = PermissionActionTypes.PermissionsInit;
}

export class AllPermissionsLoaded implements Action {
    readonly type = PermissionActionTypes.AllPermissionsLoaded;        
    constructor(public payload: { permissions: Permission[] }) { }
}

export class PermissionLogOut implements Action {
    readonly type = PermissionActionTypes.PermissionLogOut;
}

export type PermissionActions = AllPermissionsRequested | AllPermissionsLoaded | PermissionLogOut;
