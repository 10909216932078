import { BaseModelCT } from "../../models/query-models/_base.model.ct";

export class Permission extends BaseModelCT {
    id: number;
    name: string;
    groupName : string;
    description : string;
    title : string;
    level: number;
    parentId: number;
    isSelected:  boolean;
    _children: Permission[];
    inherit?:boolean;
    isExtend?:string;
 
    clear(): void {
        this.id = undefined;
        this.title = '';
        this.description = '';
        this.groupName = '';
        this.name = '';
        this.level = 1;
        // this.parentId = undefined;
        // this.isSelected = false;
       
        // this._children = [];
	}
}
