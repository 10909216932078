// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * https://github.com/AndrewPoyntz/time-ago-pipe
 * An Angular pipe for converting a date string into a time ago
 */
@Pipe({ name: 'groupBy' })

export class GroupByPipe  implements PipeTransform {
	/**
	 * Transform
	 *
	 * @param value: string
	 */
    transform(value: Array<any>, field: string,textSearch): Array<any> {
        if(!value) return [];
        if(textSearch)  value = value.filter(itemValue=>{
            return itemValue.name == textSearch;
        })
   
        const groupedObj = value.reduce((prev, cur)=> {
          (prev[cur[field]] = prev[cur[field]] || []).push(cur);
          return prev;
        }, {});
        // console.log(groupedObj);
        return Object.keys(groupedObj).map(key => ({ key, value: groupedObj[key] }));
      }
}
