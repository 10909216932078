import {Injectable, EventEmitter} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserTreeService {

  public userIdActive;
  private eventEmiter: EventEmitter<string>;
  public userTreeMainDisplay: boolean = false;
  public userTreeResponsive: boolean = false;
  public changeUser: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {
    this.eventEmiter = new EventEmitter();
  }

  get event(): EventEmitter<string> {
    return this.eventEmiter;
  }

  close() {
    this.eventEmiter.emit("close");
  }

  open() {
    this.eventEmiter.emit('open');
  }
  reload() {
    this.eventEmiter.emit('reload')
  }


  getProfile(token) {
    const reqHeader = new HttpHeaders()
      .set('Authorization', token);
    return this.http.get(environment.api.host + '/users/profile', {headers: reqHeader});
  }

  getChildUsers(params) {
    return this.http.get(environment.api.host + '/users/get-child-users', {params: params});
  }

  searchChildUsers(params) {
    return this.http.get(environment.api.host + '/users/search-child-users', {params: params});
  }


  resetLocalStorage(name) {
    localStorage.removeItem(name);
  }

  setNodeActive(item, nameTree, isEdit) {
    let path = item.path;
    let id = item.id;
    if (isEdit) {
      id = item.parentId;
    }
    const expandedNodeIds = {};
    const treeState = {
      activeNodeIds: {},
      expandedNodeIds: {},
      focusedNodeId: ''
    };
    treeState.activeNodeIds[id] = true;
    path = path.split(",");
    for (let i = 0; i < path.length; i++) {
      expandedNodeIds[path[i]] = true;
    }
    treeState.expandedNodeIds = expandedNodeIds;
    localStorage.setItem(nameTree, JSON.stringify(treeState));
  }

}
