// Spain
export const locale = {
	lang: 'es',
	data: {
		TRANSLATOR: {
			SELECT: "Elige tu idioma"
		},
		THEME: {
			LIGHT: "Ligero",
			DARK: "Oscuro"
		},
		MENU: {
			NEW: "Nuevo",
			ACTIONS: "Acciones",
			CREATE_POST: "Crear nuevo",
			PAGES: "Página",
			FEATURES: "Caracteristicas",
			APPS: "Aplicaciones",
			DASHBOARD: "Visión general",
			MAP: "Mapa",
			REPORT: "Informes",
			REPORT_FUEL: "Cambio",
			MANAGE: "Gestionar",
			MANAGER_USER: "Cuenta",
			MANAGER_DEVICE: "Dispositivos",
			GROUP_DEVICE: "Grupo de dispositivos",
			COMMAND: "Enviar comando",
			ADMIN: "Administrador",
			SETTING_SYSTEM: "Configuración del sistema",
			MANAGE_PERMISSIONS: "Gestión de permisos",
			MANAGE_ROLES: "Gestión de roles",
			MANAGER_DRIVER: "Conductor",
			MANAGER_POI: "Hitos",
			LOGIN_PAGE: "Administrar página de inicio de sesión",
			SETTING_DEVICE: "Configuración del dispositivo",
			SIM_TYPE: "Tipo de SIM",
			DEVICE_TYPE: "Tipo de dispositivo",
			TRANSPORT_TYPE: "Tipo de transporte",
			TRANSPORT_TYPE_QCVN: "Tipo de transporte QCVN",
			SENSOR_MODEL: "Modelo de sensor",
			ICON: "Icono del dispositivo",
			USER_ALIAS: "Alias de usuario",
			ROLE_ALIAS: "Alias de rol",
			ALERT: "Reglas de alerta",
			REPORT_DEVICE: "Estado del dispositivo",
			MANAGE_GEOFENCE: "Geofences",
			MANAGE_GEOFENCE_GROUP: "Grupo de geofence",
			SCHEDULE_REPORT: "Informes programados",
			REPORT_ENGINE: "Horas de motor",
			REPORT_ROUTE: "Rutas",
			REPORT_ROUTE_SIMPLE: "Rutas simples",
			REPORT_GENERAL: "Resumen (dispositivos)",
			REPORT_SUMMARY_DAY: "Resumen por dias",
			REPORT_GEOFENCE_SUMMARY: "Geofence resumen",
			REPORT_GEOFENCE_DETAIL: "Detalle de geofence",
			REPORT_TEMPERATURE: "Temperatura",
			REPORT_STATUS_HISTORY: "Historial de estado",
			REPORT_TOLL_FREE: "Sin costo",
			MANAGE_ALERTS: "Notificaciones",
			UTILITIES: "Utilidades",
			FUEL: "Combustible",
			CARD_SERVICE: "Servicio de tarjeta",
			INSERT_CARD: "Insertar tarjeta",
			ACTIVITY_LOG: "Registro de actividades",
			SYSTEM_LOG: "Registro de cambios del sistema",
			NOTIFI_GLOBAL: "Avisos",
			MESSAGE: "Mensaje",
			POI_TYPE: "Tipo de hito",
			TOLL_STATION: "Estación de peaje",
			TOLL_ROAD: "Carretera de peaje",
			TOLL_SEGMENT: "Tramo de peaje",
			TOLL_FEE: "Cálculo de tarifa de peaje",
			POINTS_MANAGE: "Gestión de puntos",
			RENEWS_HISTORY: "Renueva la historia",
			TRANSACTION_HISTORY: "Historial de transacciones",
			POINT_MANAGE: "Gestión de puntos",
			BALANCE: "Equilibrar",
			SYNTHESIS_REPORT: "Informe de síntesis",
			TRACKINGS: "Rastreo",
			FEEDBACK: "Realimentación",
			DATA_LOG: "Registro de datos",
			MAP_API_KEY: "Clave de API de mapa",
			TECHNICAL_SUPPORT: "Soporte técnico",
			DEBUG: "Registro sin procesar",
			SIM_INFO: "Historial de información de SIM",
			DEVICE_LOGIN: "Historial de conexiones",
			LOST_SIGNAL: "Sin señal",
			TRACKING_GEOFENCES: "Seguimiento de geocercas",
			TRACKING_LANDMARKS: "Seguimiento de puntos de referencia",
			TRIP: "Viaje",
			REPORT_TASK_REMIND: "Informe recordar",
			DATA_GROUP_MANAGEMENT: "Gestión de grupos de datos"
		},
		AUTH: {
			GENERAL: {
				SIGNIN: "Iniciar sesión",
				OR: "o",
				SUBMIT_BUTTON: "Enviar",
				NO_ACCOUNT: "¿No tienes cuenta aún?",
				SIGNUP_BUTTON: "Regístrate",
				FORGOT_BUTTON: "Olvidó contraseña",
				BACK_BUTTON: "Espalda",
				PRIVACY: "Sobre nosotros",
				LEGAL: "Legal",
				CONTACT: "Contacto",
				WELCOME: "Bienvenido a Navitrack",
				DESCRIPTION: "Vietnam Electronics & Telecommunication Technology Joint Stock Company (Navitrack) es una de las empresas líderes en el campo de la distribución y fabricación de dispositivos de posicionamiento en Vietnam.",
				DONT_HAVE_ACCOUNT: "¿No tienes una cuenta?"
			},
			LOGIN: {
				TITLE: "Iniciar sesión cuenta!",
				BUTTON: "Iniciar sesión"
			},
			FORGOT: {
				TITLE: "¿Se te olvidó tu contraseña?",
				DESC: "Ingrese su correo electrónico para restablecer su contraseña",
				SUCCESS: "Restablezca la contraseña exitosamente!"
			},
			RESET: {
				TITLE: "¿Restablecer la contraseña?"
			},
			REGISTER: {
				TITLE: "Suscribir",
				DESC: "Ingrese correo electrónico",
				SUCCESS: "La cuenta ha sido registrada con éxito."
			},
			INPUT: {
				EMAIL: "Email",
				FULLNAME: "Nombre completo",
				PASSWORD: "Contraseña",
				CONFIRM_PASSWORD: "Escriba la contraseña otra vez",
				USERNAME: "Cuenta"
			},
			VALIDATION: {
				INVALID: "{{name}} no es válido",
				REQUIRED: "{{name}} requiere",
				MIN_LENGTH: "{{name}} tiene al menos {{min}} caracteres",
				AGREEMENT_REQUIRED: "Acepte los términos y condiciones requeridos",
				NOT_FOUND: "El {{name}} solicitado no se encuentra",
				INVALID_LOGIN: "La cuenta o contraseña es incorrecta",
				REQUIRED_FIELD: "No dejar en blanco",
				MIN_LENGTH_FIELD: "Longitud mínima:",
				MAX_LENGTH_FIELD: "Longitud máxima:",
				INVALID_FIELD: "Valor no válido"
			},
			MESSAGE: {
				TEST: "Experimental"
			}
		},
		DASHBOARD: {
			NOT_FOUND: "Sin datos",
			STATUS: "Estado",
			STATICIC: "Estadística",
			DEVICE: "Dispositivo",
			NO_DEVICE: "Cuenta no dispositivo",
			USER: "Usuario",
			CATEGORY: "Categoría",
			INFO_DISTRIBOUTOR: "Información de la agencia",
			TOTAL_POINT: "Puntaje total",
			OPTION: "Opción de pantalla",
			PARAMS: {
				ONLINE: "En línea",
				OFFLINE: "Desconectado",
				STOCK: "No vendido",
				EXPIRED: "Caducado",
				INACTIVE: "Inactivo",
				ACTIVE: "Activo",
				TOTAL: "Total",
				ALERTS: "Alertas",
				SELL: "Vendido",
				CHART_STATUS: "Tabla de estado"
			},
			STATIC: {
				DURATION: "Duración",
				DISTANCE: "Distancia",
				TOP_10_STOP: "Paradas",
				TOP_10_IDLING: "Ve más despacio",
				TOP_10_DISTANCE: "Movimientos",
				IDLING_LABEL: "Duración (minuto)",
				MIN: "minuto",
				SECONDS: "segundos",
				HOURS: "hora",
				DURATION_LABEL: "Duración (hora)",
				DISTANCE_LABEL: "Distancia (km)"
			}
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: "Número de registros seleccionados:",
				ALL: "Todas",
				SUSPENDED: "Suspendido",
				ACTIVE: "Activo",
				FILTER: "Filtrar",
				BY_STATUS: "por Estatus",
				BY_TYPE: "por Tipo",
				BUSINESS: "Empresa",
				INDIVIDUAL: "Individual",
				SEARCH: "Buscar",
				IN_ALL_FIELDS: "en todos los campos"
			},
			ECOMMERCE: "eCommerce",
			CUSTOMERS: {
				CUSTOMERS: "Clientes",
				CUSTOMERS_LIST: "Lista de clientes",
				NEW_CUSTOMER: "Nuevo Cliente",
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: "Eliminar Cliente",
					DESCRIPTION: "¿Estás seguro de eliminar permanentemente a este cliente?",
					WAIT_DESCRIPTION: "El cliente está eliminando ...",
					MESSAGE: "El cliente ha sido eliminado"
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: "Clientes Eliminar",
					DESCRIPTION: "¿Está seguro de eliminar permanentemente los clientes seleccionados?",
					WAIT_DESCRIPTION: "Los clientes están eliminando ...",
					MESSAGE: "Los clientes seleccionados han sido eliminados"
				},
				UPDATE_STATUS: {
					TITLE: "El estado ha sido actualizado para clientes seleccionados",
					MESSAGE: "El estado de los clientes seleccionados se ha actualizado correctamente"
				},
				EDIT: {
					UPDATE_MESSAGE: "El cliente ha sido actualizado",
					ADD_MESSAGE: "El cliente ha sido creado"
				}
			},
			MESSAGE: {}
		},
		ADMIN: {
			GENERAL: {
				SIM_TYPE: "tipo de sim",
				DEVICE_TYPE: "tipo de dispositivo",
				TRANSPORT_TYPE: "tipo de transporte"
			},
			SIM_TYPE: {
				GENERAL: {
					TITLE_FORM_ADD: "Crear nuevo tipo de sim",
					TITLE_FORM_EDIT: "Editar tipo de sim",
					NAME_KEY: "Código",
					NAME: "Nombre",
					CREATED_DATE: "Fecha de creación",
					SORT_ORDER: "Orden",
					ACTIONS: "Acciones"
				},
				VALIDATION: {},
				MESSAGE: {}
			},
			PERMISSION: {
				GENERAL: {
					TITLE_FORM_ADD: "Crear nuevos permisos",
					TITLE_FORM_EDIT: "Editar permisos",
					DESCRIPTION: "Descripción",
					NAME: "Nombre",
					GROUP: "Grupos",
					PERMISSION_NAME: "Nombre de permisos",
					PERMISSION: "permisos",
					UPDATE_PERMISSION_FAILED: "¡Los permisos de actualización fallaron!",
					DELETE_PERMISSION_FAILED: "Eliminar permisos ha fallado!",
					DELETE_PERMISSION_SUCCESS: "Eliminar permiso exitoso!",
					UPDATE_PERMISSION_NAME: "Actualizar permisos",
					ADD_PERMISSION_NAME: "Agregar permisos",
					INHERIT: "Heredar"
				}
			},
			ROLE: {
				GENERAL: {
					TITLE_FORM_ADD: "Crear nuevo rol",
					TITLE_FORM_EDIT: "Editar rol",
					DESCRIPTION: "Descripción",
					NAME: "Nombre",
					NAME_PARENT: "Nombre del Padre",
					ROLE_NAME: "Nombre de rol",
					ROLE: "papel",
					CHOICE_PERMISSIONS: "Seleccionar permisos",
					KEY: 'Llave'
				},
				MESSAGE: {
					UPDATE_ROLE_SUCCESS: "Actualizar rol con éxito",
					ADD_ROLE_SUCCESS: "Crea un nuevo rol con éxito",
					DELETE_ROLE_SUCCESS: "Eliminar rol con éxito",
					UPDATE_ROLE_FAILED: "Actualizar el rol falla!",
					ADD_ROLE_FAILED: "¡Agregó nuevos roles fallidos!",
					DELETE_ROLE_FAILED: "¡Eliminar el rol fallido!"
				}
			},
			SENSOR: {
				GENERAL: {
					TITLE_FORM_EDIT: "Editar sensor",
					SENSOR_TYPE: "Tipo de sensor",
					SENSOR_NAME: "Nombre del sensor",
					SENSOR: "sensor",
					UPDATE_SENSOR_FAILED: "¡Actualización del sensor fallida!",
					UPDATE_SENSOR_NAME: "Actualizar sensor",
					ADD_SENSOR_NAME: "Crear nuevo sensor",
					DELETE_SENSOR_NAME: "Eliminar sensor",
					KEY_LANGUAGE: "Lenguaje clave"
				},
				MESSAGE: {
					UPDATE_SENSOR_SUCCESS: "Actualización del sensor exitosamente",
					ADD_SENSOR_SUCCESS: "Nuevo sensor creado con éxito",
					DELETE_SENSOR_SUCCESS: "Sensor eliminado con éxito",
					UPDATE_SENSOR_FAILED: "¡Actualización del sensor fallida!",
					ADD_SENSOR_FAILED: "¡Error al agregar nuevo sensor!",
					DELETE_SENSOR_FAILED: "¡Error al eliminar el sensor!",
					SENSORTPL__NAME_NOT_VALID: "Nombre de sensor inválido",
					SENSORTPL__ALREADY_EXIST: "El nombre del sensor ya existe"
				}
			},
			DEVICE_ICON: {
				GENERAL: {
					TITLE_FORM_EDIT: "Editar icono del dispositivo",
					TITLE_FORM_ADD: "Agregar icono de dispositivo",
					NAME_KEY: "Nombre clave",
					ICON: "Icono",
					ICON_SVG: "Símbolos (SVG)",
					ICON_ON_MAP_SVG: "Icono de mapa (SVG)",
					COUNT: "Orden",
					ICON_ON_MAP: "Icono de mapa",
					ICONTYPE_NAME: "Nombre del icono del dispositivo",
					ICONTYPE: "icono del dispositivo"
				},
				MESSAGE: {
					UPDATE_ICONTYPE_SUCCESS: "Actualización de icono exitosa",
					ADD_ICONTYPE_SUCCESS: "Nuevo éxito de creación de iconos",
					DELETE_ICONTYPE_SUCCESS: "Se eliminó correctamente el ícono",
					UPDATE_ICONTYPE_FAILED: "¡La actualización del icono ha fallado!",
					ADD_ICONTYPE_FAILED: "¡Fallo al agregar nuevo símbolo!",
					DELETE_ICONTYPE_FAILED: "¡El icono de eliminación ha fallado!"
				}
			},
			TRANSPORT_TYPE: {
				GENERAL: {
					TRANSPORT_TYPE: "Tipo de transporte",
					TITLE_FORM_EDIT: "Editar tipo de transporte",
					TITLE_FORM_ADD: "Agregar tipo de transporte",
					DRAG_DROP: "Arrastra y suelta para organizar"
				},
				COLUMN: {
					DEVICE: "Equipo",
					LIMITSPEDD: "Límite de velocidad",
					DEVICE_TYPE: "Tipo de medio",
					QNCN_CODE: "QCVN - Tipo de operación"
				}
			},
			TRANSPORT_TYPE_QCVN: {
				GENERAL: {
					TRANSPORT_TYPE_QCVN: "tipo de transporte QCVN",
					TITLE_FORM_EDIT: "Editar tipo de transporte QCVN",
					TITLE_FORM_ADD: "Añadir tipo de transporte QCVN"
				},
				COLUMN: {
					DEVICE: "Equipo",
					LIMITSPEDD: "Límite de velocidad",
					DEVICE_TYPE: "Tipo de medio",
					QNCN_CODE: "QCVN - Tipo de operación"
				}
			},
			LOGIN_PAGE: {
				GENERAL: {
					TITLE: "Administrar página de inicio de sesión",
					TITLE_ADD: "agregar página de inicio de sesión",
					TITLE_FORM_EDIT: "Editar página de inicio de sesión",
					TITLE_FORM_ADD: "Agregar página de inicio de sesión",
					CHOOSE_THEME: "Escoge un tema",
					LOGIN_PAGE: "Página de inicio de sesión"
				},
				COLUMN: {
					HOST: "Anfitrión",
					WEBSITE: "Sitio web",
					COMPANY_NAME: "Nombre de empresa",
					THEME: "Tema",
					LOGO: "Logo",
					IOS: "Aplicación IOS",
					ANDROID: "aplicación Android",
					ICON: "Icono"
				}
			},
			DEVICETYPE: {
				GENERAL: {
					TITLE_CROP_PICTURE: "Editar foto",
					COMMAND: "mando"
				},
				COLUMN: {
					MANUFACTURER: "manufacturero",
					PROTOCOL: "Protocolo",
					MODEL_NAME: "Nombre del modelo",
					LINK: "Enlace",
					IMAGE: "Imagen",
					PARAMETER_SENSOR: "IO Setting",
					EXTENSION: "Extensión",
					MODEL: "Modelo"
				}
			},
			SYSTEM_LOG: {
				GENERAL: {
					TITLE_FORM_EDIT: "Editar versión",
					TITLE_FORM_ADD: "Agregar versión",
					VERSION: "Versión"
				},
				COLUMN: {
					VERSION_NAME: "Nombre de la versión",
					VERSION_TYPE: "Tipo de versión",
					CONTENT: "Contenido"
				}
			},
			POI_TYPE: {
				GENERAL: {
					TITLE_FORM_EDIT: "Editar tipo de hito",
					TITLE_FORM_ADD: "Agregar tipo de hito",
					VERSION: "Versión",
					POI: "Tipo de hito",
					KEY: "Lenguaje clave"
				},
				LANGUAGE: {
					OTHER: "Otro",
					RESTAURANT: "Restaurante",
					STADIUM: "Estadio",
					SCHOOL: "Colegio",
					HOSPITAL: "Hospital",
					SUPERMARKET: "Supermercado",
					COMPANY: "Empresa",
					HOME: "Hogar"
				}
			},
			TOLL_STATION: {
				COLUMN: {
					TYPE_GEOFENCE: "Tipo geofence",
					CITY: "Provincia",
					OPTION: "Autopista",
					STANDARD: "Estándar",
					NAME: "estación de peaje",
					TYPE_STATION: "Tipo",
					CHOISE_CITY: "Seleccione provincia",
					BEGIN_WORK: "Fecha de inicio",
					END_WORK: "Fecha final",
					PRICE: "Precio",
					TYPE_DEVICE: "Tipo de dispositivo",
					CIRCLE: "Circulo",
					RECTANGLE: "Rectángulo",
					TAB_PRICE: "Lista de precios",
					CURRENCY_UNIT: "Unidad monetaria",
					COUNTRY: "País",
					SELECT_COUNTRY: "Seleccionar país"
				},
				MESSAGE: {
					TAP_DEVICE: "Haga clic en ver todo tipo de transporte",
					TAP_CURRENCY: "Haga clic en cambiar moneda",
					CREATE_TOLL_ONMAP: "Crear estación de peaje en el mapa por favor!",
					PlEASE_COUNTRY: "Por favor seleccione provincia",
					PLEASE_ROAD: "Por favor seleccione la carretera"
				}
			},
			TOLL_ROAD: {
				GENERAL: {
					TITLE_FORM_EDIT: "Editar carretera de peaje",
					TITLE_FORM_ADD: "Agregar carretera de peaje"
				},
				COLUMN: {
					PROVINCE_ROAD: "Carretera provincial",
					HIGHWAY: "Autopista",
					NATIONAL_ROAD: "carretera nacional"
				}
			},
			TOLL_SEGMENT: {
				COLUMN: {
					ROAD: "La carretera",
					START_TOLL: "Estación de entrada",
					END_TOLL: "Estación de salida",
					IS_WAY: "Bidireccional",
					SELECT_ROAD: "Seleccionar camino",
					SELECT_TOLL_STATION: "Seleccionar estación de peaje",
					LIST_TOLL: "Estaciones de peaje",
					LIST_TOLLED: "Estación de peaje seleccionada"
				},
				GENERAL: {
					TITLE_FORM_EDIT: "Editar segmento de peaje",
					TITLE_FORM_ADD: "Agregar segmento de peaje"
				},
				MESSAGE: {
					PLEASE_TOLL_STATION: "Seleccione la estación de peaje"
				}
			},
			FEEDBACK: {
				GENERAL: {
					FEEDBACK: "Realimentación",
					FEEDBACK_MANAGE: "Gestión de realimentación",
					FEEDBACK_INFO: "Información de comentarios",
					UPDATE_FEEDBACK: "Actualizar realimentación"
				}
			},
			NOTIFI_GLOBAL: {
				GENERAL: {
					TIME: "Rango de fechas activo",
					NAME: "Avisos",
					TITLE_FORM_EDIT: "Aviso detallado",
					TITLE_FORM_ADD: "Crear aviso",
					VERSION: "Versión",
					DELETE: "Eliminar aviso"
				}
			}
		},
		MANAGE: {
			GENERAL: {
				DEVICE: "dispositivo",
				USER: "usuario",
				GROUP_DEVICE: "grupo de dispositivos",
				COMMAND: "enviar comando",
				USER_ALIAS: "alias de usuario",
				ROLE_ALIAS: "alias de rol",
				PROFILE: "perfil",
				PROFILE_UPERCASE: "Perfil",
				MAP_API_KEY: "mapa de clave de API"
			},
			DEVICE: {
				SELL: {
					GENERAL: {
						SELL_DEVICE: "Mover / Vender",
						TOTAL_DEVICE: "Dispositivos totales",
						SELL_TO: "Mover a"
					}
				},
				SEND_COMMAND: {
					GENERAL: {
						SEND_COMMAND: "Enviar comando",
						DEVICE_TYPE: "Tipo de dispositivo",
						CHOOSE_DEVICE_TYPE: "Seleccionar tipo de dispositivo",
						COMMAND_TYPE: "Tipo de comando",
						CHOOSE_COMMAND_TYPE: "Elegir tipo de comando",
						COMMAND_SEND: "Enviar comando",
						TOTAL_DEVICE: "Dispositivos totales",
						COMMAND_NAME: "Nombre del comando",
						IMEI_DEVICE: "Nombre del comando"
					}
				},
				RENEWS: {
					GENERAL: {
						RENEWS: "Renueva",
						USER_RENEWS: "Renueva cuentas",
						RENEWS_TO_DAY: "Renueva hasta la fecha",
						TOTAL: "Suma",
						DESCRIPTIONS: "Descripciones",
						PAY: "Pagar",
						RENEWS_CONFIRM: "Confirmar renovación",
						RENEWS_MESSAGE: "¿Seguro que quieres extender el dispositivo?",
						AMOUNT: "Cantidad",
						USED_OLD_SERVICE_TERM: "Término de servicio antiguo usado"
					},
					VALIDATION: {
						POINTS_NOT_ENOUGH: "No hay suficientes puntos para renovar",
						NO_DEVICES_HAVE_BEEN_SELECTED: "No hay dispositivos seleccionados",
						NO_DEVICES_HAVE_BEEN_RENEWED: "No se han extendido dispositivos."
					},
					MESSAGE: {
						NOTE_1: "Extensión de servicio: 1 mes con 1 punto",
						NOTE_2: "La fecha de servicio se contará a partir de la fecha actual o se agregará si aún es válida",
						NOTE_3: "Si el dispositivo no tiene la garantía habilitada, se activará automáticamente",
						DEVICE_ERROR: "Dispositivo inválido"
					},
					PAY: {
						UNKNOWN: "Desconocido",
						PAID: "Pagada",
						UNPAID: "No pagado"
					},
					MONTHS: {
						ADD_1_MONTH: "1 mes",
						ADD_6_MONTHS: "6 meses",
						ADD_1_YEAR: "1 año",
						ADD_2_YEARS: "2 años",
						ADD_3_YEARS: "3 años"
					}
				},
				IMPORT: {
					GENERAL: {
						IMPORT_DEVICE: "Añadir dispositivo",
						DOWNLOAD_TEMPLATE: "Descargar plantilla",
						CHOOSE_DOCUMENT: "Seleccionar documento",
						ADD_IMEI: "Añadir imei",
						RESET_IMEI: "Restablecer imei",
						REMOVE_DATA: "Borrar datos",
						DEVICE_TYPE: "Tipo de dispositivo",
						CHOOSE_DEVICE_TYPE: "Seleccionar tipo de dispositivo",
						CHOOSE_ICON: "Elige un icono",
						ICON: "Icono",
						TOTAL_IMEI: "Total imei",
						IMEI: "Imei"
					},
					VALIDATION: {},
					MESSAGE: {
						NOTE_1: "Agregar dispositivo por formulario de datos o importar a imei",
						NOTE_2: "Imei tiene aproximadamente 6-20 caracteres de longitud"
					}
				},
				EDIT: {
					GENERAL: {
						EDIT_DEVICE: "Editar dispositivo",
						BASE: "Básico",
						ADDVANCED: "Avanzado",
						EXTENSION: "Extensión",
						SENSOR: "Sensor",
						INFO_DEVICE: "Información del dispositivo",
						IMEI: "Imei",
						USER_NAME: "Cuenta",
						MACHINE_CODE__NUMBER_PLATE: "Código de máquina - número de placa",
						NAME_DEVICE: "Nombre del dispositivo",
						ICON: "Icono",
						CHOOSE_ICON: "Elige un icono",
						GROUP_DEVICE: "Grupo de dispositivos",
						CHOOSE_GROUP_DEVICE: "Seleccionar grupos de dispositivos",
						ADD_DEVICE_GROUP: "Agregar grupo de dispositivos",
						SIM_NO: "Número de SIM",
						SIM_TYPE: "Tipo de SIM",
						CHOOSE_SIM_TYPE: "Elige tipo de sim",
						DEVICE_TYPE: "Tipo de dispositivo",
						CHOOSE_DEVICE_TYPE: "Seleccionar tipo de dispositivo",
						SERVICE: "Servicios",
						SERVICE_EXPIRE: "Plazo de servicio",
						ACTIVE_WARRANTY: "Activar garantía",
						ACTIVE_WARRANTY_DATE: "Fecha de activación de la garantía",
						WARRANTY_EXPIRE: "Período de garantía",
						STATUS: "Estado",
						NOTE: "Notas",
						NOTE_ADMIN: "Notas (gerente)",
						SENSOR_NAME: "Nombre del sensor",
						SENSOR_TEMPLATE: "Tipo de sensor",
						CHOOSE_SENSOR_TEMPLATE: "Seleccionar patrón de sensor",
						PARAMETER_NAME: "Input",
						CHOOSE_PARAMETER_NAME: "Elige un parámetro",
						UNIT: "Unidad",
						OFFSET: "Compensar",
						FORMULA: "Fórmula",
						CALIBRATION: "Calibración",
						ROUND: "Redonda",
						MIN: "Mínima",
						MAX: "Máxima",
						INCREASE: "Aumentar mínimo",
						DECREASE: "Disminuir mínimo",
						SORT_ORDER: "Tipo",
						DESCRIPTION: "Descripción",
						SHOW_CHART_ON_MAP: "Mostrar mapa en mapa",
						INFO_VEHICEL: "Información",
						NUMBER_PLATE: "Placa",
						FRAME_NUMBER: "Número de cuadro",
						VIN_NUMBER: "Número VIN",
						TRANSPORT_TYPE: "Tipo de transporte",
						CHOOSE_TRANSPORT_TYPE: "Elija tipo de transporte",
						DRIVER: "Conductor",
						CHOOSE_DRIVER: "Elige conducir",
						DISTANCE_OFFSET: "Factor de distancia",
						DEPARMENT: "Ministry of Transportation ",
						SEND_TO_DEPARMENT: "Enviar datos",
						DEPARMENT_MANAGE: "Provincia",
						CHOOSE_DEPARMENT_MANAGE: "Elige una gerente",
						TRANSPORT_TYPE_QCVN: "Tipo de transporte QCVN",
						CHOOSE_TRANSPORT_TYPE_QCVN: "Elija el tipo de transporte QCVN",
						ADD_DRIVER: "Agregar conductora",
						NOTE_NUMBER_PLATE: "Ingrese el número de la placa de matrícula según sea necesario.",
						ENGINE_NUMBER: "Número de teléfono",
						SELL_INFO: "Vender información",
						SELLED: "VENDIDA",
						SELL_DATE: "Fecha de venta",
						UPDATE_TIME: "Tiempo actualizado",
						SENSOR_TYPE: "Tipo de sensor",
						CALCULATION_METHOD: "Método de cálculo",
						CHOOSE_CALCULATION_METHOD: "Elegir método de cálculo",
						NOTE_MINUTE: "Minutos",
						EXTENSION_CONFIG: "Config",
						EXTENSION_FUEL: "Combustible",
						EXTENSION_ADVANCED_FEATURES: "Características avanzadas",
						CONSUMPTION_RATE: "Tasa de consumo",
						TIMEOUT_NUMBER: "Tiempo de espera GPRS",
						MINUTE: "minuto",
						SECOND: "segundo",
						LITRE: "Litro",
						MIN_SPEED: "Filtro de velocidad mínima",
						DEBUG_MODE: "Registrar datos brutos",
						CAMERA: "Cámara",
						TOLL_STATION: "Estación de peaje",
						TIMEZONE: "Zona horaria",
						MIN_STOP_DURATION: "Duración mínima de la parada",
						MIN_STOP_DURATION_RANGE_PICKER: "Ingrese más de 5 segundos y menos de 60 minutos",
						CORRIDOR: "Corredor",
						ID: 'ID',
						GROUP_CAMERA: 'Grupo de camera',
						LIVESTREAM: 'Livestream',
						IMEI_OEM: "Imei oem",
						STORAGE_GROUP: 'Storage group(Image)'
					},
					MESSAGE: {
						NOTE_1: "Agregar dispositivo por formulario de datos o importar a imei",
						NOTE_2: "Imei tiene aproximadamente 6-20 caracteres de longitud"
					}
				},
				GENERAL: {
					LIST_DEVICE: "Lista de dispositivos",
					DEVICE: "Equipo",
					IMEI: "Imei",
					DEVICE_NAME: "Nombre del dispositivo",
					DEVICE_TYPE: "Tipo de dispositivo",
					SUB_ACCOUNT: "Cuenta de niño",
					MACHINE_CODE__VEHICLE_PLATE: "Codigo de maquina/ matrículas",
					SIMNO: "Número de SIM",
					SIM_TYPE: "Tipo de SIM",
					ACTIVE_DATE: "Tiempo de activación",
					ACTIVE_WARRANTY: "Activar garantía",
					SERVICE_EXPIRE: "Plazo de servicio",
					WARRANTY_EXPIRE: "Período de garantía",
					TIME_START: "Hora de inicio",
					TIME_END: "Hora de finalización",
					EXPIRE_SERVICE: "Plazo de servicio",
					EXPIRE_SOON: "Casi expirado",
					EXPIRED: "Caducado",
					SERVICE_IS_AVAIABLE: "Activo",
					STATUS: "Estado",
					INACTIVE: "No activado",
					ACTIVE: "Activar",
					EXPORT: "Exportar datos",
					SELL_DEVICE: "Mover / Vender",
					DISABLE: "Inhabilitar",
					ENABLE: "Activar",
					SEND_COMMAND: "Enviar comando",
					IMPORT_DEVICE: "Añadir dispositivo",
					RENEWS: "Renovación",
					ADD_DEVICE_GROUP: "Agregar grupo de dispositivos",
					ADD_DRIVER: "Agregar conductora",
					DROP_DRAP_SORT_ORDER: "Arrastra y suelta para organizar dispositivos",
					SORT_ORDER: "Tipo"
				},
				MESSAGE: {
					NO_DEVICE_SELECTED: "Sin dispositivos han sido seleccionados",
					CHOICE_PERMISSIONS: "Seleccionar permisos"
				}
			},
			USER: {
				GENERAL: {
					ROLE_NAME: "Nombre de rol",
					FULL_NAME: "Nombre completo",
					PHONE: "Número de teléfono",
					EMAIL: "Email",
					ACCOUNT_TYPE: "Tipo de usuario",
					PARENT_USER: "Cuenta para padres",
					USERNAME_OR_NAME: "Cuenta o nombre",
					NEW_PASSWORD: "Nueva contraseña",
					COMFIRM_PASSWORD: "Confirmar contraseña",
					CHANGE_PASSWORD: "Cambia la contraseña",
					START_TIME: "Hora de inicio",
					END_TIME: "Hora de finalización",
					PAGE_MAIN: "Página principal"
				},
				MESSAGE: {
					PASS_MIN_CHAR: "La contraseña debe tener más de 6 caracteres.",
					PASSWORD_INCORRECT: "Las contraseñas no coinciden",
					ADD_USER_FAILED: "¡Error al agregar nuevo usuario!",
					UPDATE_USER_FAILED: "¡La actualización del usuario falló!",
					DELETE_USER_FAILED: "Eliminar usuario no tuvo éxito!",
					UPDATE_USER_SUCCESS: "¡Actualización del usuario exitosa!",
					ADD_USER_SUCCESS: "¡Nuevo usuario agregado con éxito!",
					DELETE_USER_SUCCESS: "Eliminar usuario con éxito!",
					RESET_PASSWORD_SUCCESS: "¡Restablecimiento de contraseña exitoso!",
					RESET_PASSWORD_FAILED: "¡Restablecimiento de contraseña fallido!",
					USER__DELETED_FAIL_HAVE_CHILD: "La usuario tiene cuentas secundarias",
					USER__DELETED_FAIL_HAVE_DEVICE: "El usuario tiene dispositivo",
					USER__UPDATED_FAIL_PARENTID: "No se puede actualizar la cuenta principal",
					USER__UPDATED_FAIL_NOT_CHILD: "No hay derechos para actualizar cuentas que no sean cuentas secundarias",
					USER__UPDATED_ROLE_NOT_VALID: "Sin permiso para asignar roles de administrador",
					USER__UPDATED_ROLE_NOT_FOUND: "No se puede actualizar el usuario",
					USER__PARENTID_NOT_VALID: "No se pueden seleccionar cuentas secundarias como padre",
					UPDATE_ICONTYPE_FAILED: "¡La actualización del icono del dispositivo ha fallado!",
					UPDATE_ICONTYPE_NAME: "Actualizar ícono de dispositivo"
				},
				PARAMETER: {
					TIMEZONE: "Zona horaria",
					LANGUAGE: "Idioma",
					DISTANCE: "Distancia",
					VOLUME: "Unidad de volumen",
					TEMPERATURE: "Unidad de temperatura",
					WEIGHT: "Unidad de peso",
					DATE_FORMAT: "Fecha / mes",
					TIME_FORMAT: "Hora",
					DECIMAL_SEPARATOR: "Decimal",
					FIRST_WEEKDAY: "Primer día de la semana",
					DISTRIBUTOR: "Distribuidor",
					USER: "Usuario",
					USER_ONLY_MOTORBIKE: 'Usuario(solo moto)'
				},
				NOTIFICATIONS: {
					MOVEMEMT: "Moverse",
					SCHEDULE: "Calendario",
					FUEL_DRIVING: "Combustible y conducción",
					SAFETY: "Segura",
					INPUTS_OUTPUTS: "Acceso",
					Device: "Dispositivo",
					BATTERY_LOW: "La bateria esta baja",
					EXTERNAL_POWER_CUT: "Corte de energía externo",
					SOS: "llamada de socorro",
					OVER_SPEED: "Sobre velocidad",
					GEOFENCE_IN: "Entrar en geofence",
					GEOFENCE_OUT: "Salir de geofence",
					NONE: "Información",
					SHOCK: "conmoción",
					EXTERNAL_POWER_WEAK: "El poder externo es débil",
					OVERSPEED: "Sobre velocidad",
					EXTERNAL_POWER_CONNECTED: "Energía externa conectada",
					MOVING: "Moviente",
					DOOR_OPEN: "Puerta abierta",
					DOOR_CLOSE: "Puerta cerrada",
					STOP_OVERTIME: "Detener las horas extras",
					BATTERY_HIGHT: "La batería está llena",
					EXTERNAL_POWER_LOW: "La potencia externa es baja",
					EXTERNAL_POWER_HIGHT: "La potencia externa excede",
					VIN_NUMBER_CHANGE: "Cambio de número de VIN",
					NOTIFICATION: "Notificación",
					TYPE: "Tipo",
					ACTIVE: "Activo",
					INACTIVE: "Inactivo",
					MAP: "Mapa",
					GEOFENCE: "Geofence",
					FAVORITE: "Favorito",
					DEVICE: "Dispositivo",
					VIBRATION: "Vibración"
				}
			},
			COMMAND: {
				GENERAL: {
					SENDED: "Enviado",
					TiME_SEND: "Hora de enviar",
					RETURNED: "Devuelto",
					TIME_RETURN: "Hora de regreso",
					CONTENT_RETURN: "Contenido devuelto",
					COMMAND: "Enviar comando",
					RESPONSED: "Devuelto",
					TIME_RESPONSE: "Hora de regreso",
					CONTENT_RESPONSE: "Contenido devuelto",
					TITLE_FORM_ADD: "Agregar comando de envío",
					TITLE_FORM_DETAIL: "Detalles del pedido",
					DEVICE_INFO: "Dispositivo",
					SEND_INFO: "Enviar",
					RESPONSE_INFO: "Respuesta"
				},
				MESSAGE: {}
			},
			DEVICE_GROUP: {
				GENERAL: {
					TITLE_FORM_EDIT: "Editar grupo de dispositivos",
					TITLE_FORM_ADD: "Agregar grupo de dispositivos",
					DEVICE_GROUP: "Grupo de dispositivos",
					ADD_DEVICE_GROUP: "Agregar grupo de dispositivos",
					GROUP_NAME: "Nombre del grupo"
				},
				COLUMN: {
					COUNT_DEVICE: "Número de dispositivos"
				}
			},
			USER_ALIAS: {
				TITLE_FORM_EDIT: "Editar alias de usuario",
				TITLE_FORM_ADD: "Agregar alias de usuario",
				GENERAL: {
					BASE: "Base",
					DEVICES: "Dispositivos"
				}
			},
			ROLE_ALIAS: {
				TITLE_FORM_EDIT: "Editar alias de roles",
				TITLE_FORM_ADD: "Agregar alias de rol"
			},
			ALERT_RULE: {
				GENERAL: {
					ALERT_RULE: "Reglas de alerta",
					MANAGE_ALERT: "Reglas de alerta",
					ALERT: "alerta",
					NAME_ALERT: "Nombre de alerta",
					TYPE_ALERT: "Tipo de alerta",
					NOTIFICATION_TEXT: "Mensaje de texto",
					GEOFENCE: "Geofence",
					NOTIFICATION: "Notificaciones",
					TITLE_FORM_EDIT: "Editar alerta",
					TITLE_FORM_ADD: "Agregar nueva alerta",
					DEVICE: "Dispositivo",
					ALWAYS_SELECT_ALL_DEVICE: "Seleccionar siempre todos los dispositivos",
					ALWAYS_SELECT_ALL_GEOFENCE: "Seleccione siempre todas las geovallas"
				},
				TYPE_GROUP_ALERT: {
					MOVEMEMT: "Moverse",
					SCHEDULE: "Calendario",
					FUEL_DRIVING: "Combustible y conducción",
					SAFETY: "Segura",
					INPUTS_OUTPUTS: "Acceso",
					DEVICE: "Dispositivo"
				},
				PARAMS_VALUE: {
					OVER_SPEED: "Sobre velocidad"
				},
				NOTIFICATION: {
					CONTENT: "Contenido para recibir notificaciones",
					TIME_NOTIFI: "Hora de recibir notificación",
					DAY: "Día",
					TO: "A",
					FROM: "De",
					TIME: "Hora",
					OPERATION: "Activo",
					OPERATION_ALWAYS: "Siempre activa",
					OPERATION_TIME: "Actividad en rango",
					FROM_DAY: "Partir de la fecha",
					TO_DAY: "Hasta la fecha",
					SMS: "Notificación de SMS",
					SMS_LABEL: "(List of phones separated by \"\", or \";\")",
					EMAIL: "Notificación de correo electrónico",
					EMAIL_LABEL: "(Email list separated by \"\", or \";\")",
					PUSH: "Recibir notificaciones",
					PUSH_TOOLTIP: "Reciba notificaciones a través de la aplicación móvil",
					EMERGENCY: "Notificación de emergencia",
					EMERGENCY_TOOLTIP: "Aviso urgente",
					NOTIFICATION: "Notificaciones",
					NO_FOUND_NOTI: "No hay notificaciones nuevas",
					MARK_ALL_READ: "Marcar todo como leido",
					VIEW_ALL: "Ver todo",
					MARK_AS_READ: "Marcar como leído",
					MARK_AS_UNREAD: "Marcar como no leída",
					CLICK_HERE_FOR_VIEW_DETAIL: "Haga clic aquí para ver detalles",
					SEE_MORE: "Ver más",
					TIME_RANGE: "Intervalo de tiempo"
				},
				GEOFENCE: {
					OUT: "Fuera de geofence",
					IN: "En geofence",
					NONE: "Ignorar"
				},
				TYPE_ALERT: {
					MOVEMEMT: "Moverse",
					SCHEDULE: "Calendario",
					FUEL_DRIVING: "Combustible y conducción",
					SAFETY: "Segura",
					INPUTS_OUTPUTS: "Acceso",
					Device: "Dispositivo",
					BATTERY_LOW: "La bateria esta baja",
					EXTERNAL_POWER_CUT: "Corte de energía externo",
					SOS: "llamada de socorro",
					OVER_SPEED: "Sobre velocidad",
					GEOFENCE_IN: "Entrar en geofence",
					GEOFENCE_OUT: "Salir de geofence",
					NONE: "Información",
					SHOCK: "conmoción",
					EXTERNAL_POWER_WEAK: "El poder externo es débil",
					OVERSPEED: "Sobre velocidad",
					EXTERNAL_POWER_CONNECTED: "Energía externa conectada",
					MOVING: "Moviente",
					DOOR_OPEN: "Puerta abierta",
					DOOR_CLOSE: "Puerta cerrada",
					STOP_OVERTIME: "Detener las horas extras",
					BATTERY_HIGHT: "La batería está llena",
					EXTERNAL_POWER_LOW: "La potencia externa es baja",
					EXTERNAL_POWER_HIGHT: "La potencia externa excede",
					VIN_NUMBER_CHANGE: "Cambio de número de VIN"
				},
				GROUP_TYPE_ALERT: {
					DEVICE: "Dispositivo",
					ACTION: "Acción",
					IN_OUT: "En fuera",
					OBD: "OBD"
				},
				PARAMS: {
					INTERVAL: "Repetir mas tarde",
					TIME: "Hora",
					SPEED: "Velocidad",
					PERCENT: "Por ciento",
					VALUE: "Valor",
					POWER_VOL: "Tensión de alimentación"
				},
				DAY: {
					MO: "Lu",
					TU: "Ma",
					WE: "Mi",
					TH: "Ju",
					FR: "Vi",
					SA: "Sá",
					SU: "Do"
				}
			},
			ALERT: {
				GENERAL: {
					MESSAGE_CONTENT: "Mensaje de contenido",
					MANAGE_ALERT: "Notifications ",
					TITLE_FORM_DETAIL: "Detalle de alerta",
					READED: "Leer",
					UNREAD: "No leído",
					OBJECT: "Objeto",
					CLICK_DOWNLOAD: "Haga clic para descargar",
					GENERATION_DONE: "Informe creado",
					TYPE_ALERT: "Tipo de notificaciones"
				}
			},
			DRIVER: {
				GENERAL: {
					DRIVER: "conductora",
					NAME: "Nombre",
					PHONE: "Número de teléfono",
					LICENSE_NUMBER: "Número de licencia",
					EMAIL: "Email",
					DESCRIPTION: "Descripción",
					BEGIN_DATE: "Fecha de emisión",
					EXPRIE_DATE: "Fecha de vencimiento",
					DRIVER_CODE: "Código",
					ADDRESS: "Habla a",
					TITLE_FORM_ADD: "Agregar conductora",
					TITLE_FORM_EDIT: "Editar controlador"
				},
				GROUP: {
					INFO: "Información",
					NOTE: "Notas",
					LICENSE: "Licencia"
				}
			},
			GEOFENCE: {
				GENERAL: {
					NAME: "Nombre",
					GEOFENCE: "geofence",
					GEOFENCE_MANAGE: "Geofences",
					SEARCH_GEOFENCE: "Buscar geofence",
					GEOFENCES: "Geofences"
				},
				TOOL: {
					INFO_GEOFENCE: "Información de geovalla",
					NAME: "Nombre",
					DESCRIPTION: "Descripción",
					RADIUS: "Radio",
					LATITUDE: "Latitud",
					LONGITUDE: "Longitud",
					COLOR: "Color",
					OPACITY: "Opacidad",
					FILL_COLOR: "Color de relleno",
					FILL_OPACITY: "Opacidad de relleno",
					SHOW__HIDE_DEVICES: "Lista de dispositivos de encendido / apagado",
					PLEASE_CREATE_GEOFENCE: "Por favor cree geofence en el mapa"
				}
			},
			GEOFENCE_GROUP: {
				GENERAL: {
					NAME: "Nombre",
					GEOFENCE_GROUP: "grupo de geofence",
					TITLE_FORM_EDIT: "Editar grupo de geofence",
					TITLE_FORM_ADD: "Agregar nuevo grupo de geofence",
					COUNT: "Contar",
					CHOISE: "Selecciona grupo"
				}
			},
			POI: {
				GENERAL: {
					NAME: "hitos",
					TYPE: "Tipo de hito",
					TYPE_POI: "Seleccione tipo",
					ACTIVE: "Activo",
					INACTIVE: "Inactivo"
				},
				MESSAGE: {
					NOT_MARKET: "Por favor cree puntos de referencia en el mapa",
					NOT_TYPE: "Por favor, seleccione Tipo de hito"
				}
			},
			REPORT_SCHEDULE: {
				GENERAL: {
					REPORT_SCHEDULE: "informe programado",
					TITLE_FORM_EDIT: "Editar informe programado",
					TITLE_FORM_ADD: "Agregar informe programado",
					TITLE_FORM_DELETE: "Eliminar informe programado",
					SELECT_REPORTS: "Seleccionar informes"
				},
				COLUMN: {
					NAME: "Nombre",
					TYPE: "Tipo de informe",
					FORMAT: "Formato",
					DEVICE: "Dispositivo",
					SCHEDULE: "Programado",
					SEND_TO_EMAIL: "Enviar al correo electrónico",
					DAILY: "Diario",
					WEEKLY: "Semanal",
					DAILY_WEEKLY: "Diario, semanal"
				},
				MESSAGE: {
					TYPE_REPORT: "Por favor seleccione el tipo de informe",
					WEEKLY_MESSAGE: "El informe se enviará todos los lunes."
				}
			},
			ACTIVITY_LOG: {
				GENERAL: {
					BASIC: "Plataforma",
					USER_AGENT: "Iniciar sesión",
					ACTION_TYPE: "Tipo de acción",
					OBJECT_TYPE: "Tipo de objeto",
					OBJECT_NAME: "Nombre del objeto",
					EVENT_DESC: "Descripción del evento",
					DETAIL: "Detalles"
				},
				ACTIONS: {
					DELETE: "Eliminar",
					UPDATE: "Actualizar",
					EXTEND: "Ampliar",
					MOVE: "Moverse",
					EXTENSION: "Extensión",
					CHANGE_STATUS: "Cambiar Estado",
					SELL: "Vender",
					SORT_ORDER: "Orden de clasificación",
					UPDATE_PROFILE: "Actualización del perfil",
					UPDATE_PATH_CHILD: "Actualizar ruta hijo",
					LOGIN: "Iniciar sesión",
					CREATE: "Crear",
					CREATE_ALIAS: "Crear nuevo alias",
					UPDATE_ALIAS: "Actualizar alias",
					DELETE_ALIAS: "Eliminar alias"
				},
				OBJECT: {
					DEVICES: "Dispositivos",
					DEVICE_ICON: "Icono del dispositivo",
					DEVICE_GROUP: "Grupo de dispositivos",
					PERMISSION: "Permiso",
					CARD: "Tarjeta",
					USER_SESSION: "Sesión de usuario",
					LOGIN_PAGE: "Página de inicio de sesión",
					USER: "Usuario",
					SENSOR_TEMPLATE: "Plantilla de sensor",
					DEVICE_TYPES: "Tipos de dispositivo",
					BASIC: "Plataforma",
					USER_AGENT: "Iniciar sesión",
					ACTION_TYPE: "Tipo de acción",
					OBJECT_TYPE: "Tipo de objeto",
					OBJECT_NAME: "Nombre del objeto",
					EVENT_DESC: "Descripción del evento",
					ROLES: "Roles",
					SIM_TYPE: "Tipo de SIM",
					TRANSPORT_TYPE: "Tipo de transporte",
					ROLE_ALIAS: "Roles de alias",
					USER_SETTING: "Configuración del usuario",
					POI_TYPE: "Tipo de PDI",
					POI: "Tipo de hito",
					TOLL_STATION: "Estación de peaje",
					LATITUDE: "Latitud",
					LONGITUDE: "Longitud",
					CAMERA: "Cámara",
					SENSOR: "Sensor",
					GEOFENCE: "Geofence",
					MAP_KEY: "Llave del mapa"
				},
				EVENT: {
					REMOVE_PERMISSION: "Eliminar permiso",
					ADD_PERMISSION: "Agregar permiso",
					FROM: "desde",
					TO: "a",
					CHANGE: "Cambio",
					CREATE: "Crear nuevo",
					DELETE: "Eliminar",
					LOGIN: "iniciar sesión",
					PAGE: "Página"
				}
			},
			MAP_API_KEY: {
				API_NAME: "Nombre",
				API_KEY: "Clave API",
				TYPE: "Tipo",
				GOOGLE_TYPE: "Clave de API de Google Map",
				API_KEY_OWNER: "Propietario",
				ACTIVE: "Activo",
				INACTIVE: "Inactivo",
				USER_USE: "Usuario",
				CHOOSE_USER_USE: "Usuario objetivo",
				SEARCH_USER_USE: "Buscar usuario objetivo",
				SELECTED_USER_USE: "El usuario puede usar",
				DESCRIPTION: "Descripción",
				RESET: "Reiniciar",
				PARENT_ACCOUNT: "Usuario principal",
				ALREADY_EXIST_MAP_USERS: "Los usuarios seleccionados ya existen"
			},
			HELP: {
				GENERAL: {
					SYSTEM_CHANGE: "Registro de cambios del sistema",
					DOCS_PAGE: "Página de documentos",
					PRIVATE_POLICY: 'Política privada',
				}
			},
			MESSAGE: {
				COLUMN: {
					TITLE: "Título",
					COUNT_USER: "Cuenta usuario recibido",
					CONTENT: "Contenido"
				},
				RECEIVE: {
					MESSAGE: "Mensaje",
					NOT_FOUND: "Mensaje no encontrado",
					DELETE_ALL: "Eliminar todos",
					DELETE: "Borrar mensaje",
					DELETE_MESSAGE: "¿Estás seguro de que deseas eliminar todos los mensajes?",
					MARK_ALL: "¿Estás seguro de marcar todos los mensajes?",
					MARK: "Marcar mensaje"
				},
				SEND: {
					MESSAGE: "Enviar mensaje",
					DETAIL: "Mensaje de detalle",
					CUSTOMER: "Cliente",
					SEND_TO: "Enviar a",
					CHARACTER: "Personaje",
					LIMIT_CHARACTER: "El contenido del mensaje no debe exceder los 300 caracteres."
				}
			}
		},
		UTILITIES: {
			CARD_SERVICE: {
				GENERAL: {
					TITLE_FORM_ADD: "Agregar nuevo código",
					CARD: "tarjeta",
					USED: "Usado",
					NOT_USED: "No utilizado",
					STATISTICS: "Estadísticas",
					MONTH: "mes"
				},
				COLUMN: {
					NAME_CARD: "Código",
					DENOMINATIONS: "Denominaciones",
					QUANTITY: "Cantidad"
				}
			}
		},
		TECHNICAL_SUPPORT: {
			IMEI: "IMEI",
			DEBUG: {
				DEBUG: "Registro sin procesar",
				RAW_DATA: "Datos brutos"
			},
			SIM_INFO: {
				SENDER: "Remitente",
				DEVICE_NAME: "Nombre del dispositivo",
				NUMBER_PLATE: "Placa",
				SIM_NO: "SIM no",
				CONTENT: "Contenido"
			},
			DEVICE_LOGIN: {
				SOCKET_VERSION: "Versión de enchufe",
				SOCKET_IP: "IP del zócalo",
				PORT: "Puerto",
				FIRMWARE_VERSION: "Versión de firmware",
				LOGIN_DATA: "Datos de inicio de sesión"
			},
			LOST_SIGNAL: {
				TYPE: 'Lost signal type',
				LOST_GPRS: 'GPRS',
				LOST_GPS: 'GPS',
				DEVICE_TYPE: "Device type",
				DEVICE_NAME: "Device name",
				TIME_LOST: 'Time',
				TIME_LOST_GPS: 'GPS (minute)',
				TIME_LOST_GPRS: 'GPRS (minute)',
				TYPE_NOT_EMPTY: 'The type is not empty',
				TIME_NOT_EMPTY: 'The time is not empty',
				NUMBER_PLATE: 'Number plate'
			}
		},
		POINTS: {
			MANAGE: {
				GENERAL: {
					POINT_MANAGE: "Gestión de puntos",
					ADD_POINT: "Añadir punto",
					USER_NAME: "Nombre de la cuenta",
					POINT: "Punto",
					ACCOUNT_INFORMATION_SEND: "Enviar información de cuenta",
					CURRENT_POINT: "Puntos actuales",
					ACCOUNT_INFORMATION_RECEIVE: "Recibir información de la cuenta",
					TRANSACTION_INFORMATION: "Información de la transacción",
					AMOUNT_POINT: "Cantidad de puntos",
					PAY: "Pagar",
					DATE_PAY: "Fecha de pago",
					BILL_IMG: "Foto de factura",
					DESCRIPTION: "Aviso",
					TRANSACTION_MANAGE: "Gestionar transacciones",
					TYPE: "Tipo",
					DEVICE_IMEI: "IMEI de dispositivo",
					TRANSACTION_HISTORY: "Historial de transacciones",
					CANCEL_TRANSACTION: "Cancelar transacción",
					REQUEST_CANCEL_TRANSACTION: "Solicitar cancelar transacción",
					EDIT_TRANSACTION: "Actualizar transacción",
					DETAIL_TRANSACTION: "Detalle de transacción",
					RENEWAL_MANAGE: "Renovación gestionar",
					AMOUNT: "Cantidad",
					RENEWAL_HISTORY: "Historia de renovación",
					TOTAL_DEVICE_RENEWS: "Dispositivo de renovación total",
					COUNT_DEVICE_RENEWS: "Cuenta dispositivo de renovación",
					ADD_POINTS: "Añadir puntos",
					MOVE_POINTS: "Mover puntos",
					RENEWS: "Renovación",
					COUNT_TRANSACTION: "Contar transacción"
				},
				VALIDATE: {
					NOT_ENOUGH_POINTS: "Los puntos actuales no son suficientes",
					TYPE_INVALID: "Tipo o estado de transacción no válido",
					FIRMWARE_VERSION: "Versión de firmware"
				}
			},
			TYPE: {
				ADD: "Añadir",
				MOVE: "Moverse",
				RENEWS: "Renovación",
				ALL: "Todas"
			},
			PAY: {
				UNKNOWN: "Desconocido",
				PAID: "Pagada",
				UNPAID: "No pagado",
				CANCEL: "Cancelar",
				REQUEST_CANCEL: "Solicitud cancelar"
			},
			SEARCH: {
				ACCOUNT_OR_NAME: "Cuenta o nombre",
				PHONE: "Teléfono",
				EMAIL: "Email",
				SUB_ACCOUNT: "Sub-cuenta",
				CHOOSE_TYPE: "Elige tipo",
				ACCOUNT_TRANSACTION: "Transacción de cuenta",
				DATE: "Date a ti mismo",
				CHOOSE_PAY_STATUS: "Estado de pago",
				CHOOSE_SERVER: "Elegir servidor"
			}
		},
		REPORT: {
			REPORTS: {
				GENERAL: {
					REPORTS: "Informes",
					SCHEDULE: "Informes programados",
					ADD_REPORT: "Agregar informe",
					REMOVE_REPORT: "Ocultar informe",
					CHOISE_REPORT: "Seleccionar informe",
					HIDE_REPORT: "Esconder",
					REPORT_FAVORITE: "Reportes favoritos",
					QCVN: "Ministerio de Transporte",
					ADD_FAVORITE: "Agregar informes favoritos",
					REMOVE_FAVORITE: "Ocultar informes favoritos"
				},
				MESSAGE: {
					MESSEAGE_ADD_SUCSSEC: "Agregar exitosamente",
					MESSEAGE_REMOVE_SUCSSEC: "Ocultar con éxito",
					MESSAGE_REMOVE: "¿Quieres ocultar el informe favorito?",
					DONE: "Informe creado"
				}
			},
			DEVICE: {
				GENERAL: {
					REPORT_DEVICE: "Informar el estado del dispositivo"
				}
			},
			ROUTE: {
				GENERAL: {
					REPORT_DEVICE: "Rutas",
					DATE_START: "Hora de inicio",
					DATE_END: "Fin del tiempo",
					CHOISE_DEVICE: "Seleccione el dispositivo",
					STATE: 'Selecione o estado'
				},
				COLUMN: {
					MOVE_START: "Movimiento de inicio",
					MOVE_END: "Movimiento final",
					TOTAL_DISTANCE: "Longitud total de viajes (km)",
					TIME_DURATION: "Tiempo de viaje",
					AVERAGE_SPEED: "Velocidad media (km / h)",
					MAX_SPEED: "Velocidad máxima (km / h)",
					TRIPS: "Excursiones",
					TOTAL: "Total",
					TOTAL_MOVE: "Total - mover",
					STATE: 'Estado'
				},
				MESSAGE: {
					ERROR_TIME: "La hora de inicio no puede ser mayor que la hora de finalización"
				},
				EXCEL: {
					REPORT_ROUTE: "Informar ruta"
				}
			},
			ENGINE: {
				GENERAL: {
					REPORT_ENGINE: "Horas de motor",
					REPORT_ENGINE_DAILY: "Horas del motor (diarias)",
					REPORT_ENGINE_DETAILED: "Horas del motor (detallado)",
					MOVEMENT: "En movimiento",
					IDLING: "De marcha en vacío",
					HOURS: "Horas",
					TIME: "Hora",
					PLACE: "Sitio",
					DETAIL_DAY: "Día de detalle:"
				},
				COLUMN: {
					ENGINE_START: "De arranque del motor",
					ENGINE_END: "Parada del motor",
					TIME_MOVE: "Tiempo de movimiento",
					TIME_ENGINE: "Horas de motor",
					RATIO: "Movimiento / Motor (%)",
					TIME_IDLING: " Time idling",
					RATIO_IDLING: "Ralentí / Motor (%)",
					AVERAGE_TIME: "Intervalo medio",
					AVERAGE_SPEED: "Velocidad media (km / h)",
					COUNT: "Intervalos",
					DATE: "Date a ti mismo",
					MILEAGE: "Kilometraje (km)",
					INTERVAL: "Intervalos"
				},
				EXCEL: {
					REPORT_ENGINE: "Informar horas de motor"
				}
			},
			FUEL: {
				GENERAL: {
					REPORT_FUEL: "Combustible",
					EXPORT_FUEL: "Resumen de combustible",
					DATE_START: "Hora de inicio",
					DATE_END: "Fin del tiempo",
					CHOISE_DEVICE: "Seleccione el dispositivo",
					ADD: "Añadir",
					REMOVE: "Eliminar",
					MIN: "Min",
					MAX: "Max",
					MAX_FUEL: "Capacidad",
				},
				COLUMN: {
					VOLUME: "Volumen",
					MOVE_START: "Dirección de inicio",
					MOVE_END: "Dirección final",
					PACITY_START: 'La capacidad inicial (iluminado)',
					CAPACITY_END: 'Capacidad restante (iluminado)',
					FUEL_CHANGE: "Cambio de combustible",
					FUEL_GRAPH: "Gráfico"
				},
				MESSAGE: {
					SENSOR: "Sensor no encontrado",
					NOT_DEVICE: "Cuenta no dispositivo"
				},
				CHART: {
					SUMMARY: "Resumen",
					FUEL_START: "Inicio de combustible",
					FUEL_END: "Fin de combustible",
					CONSUME: "Consumir",
					HISTORY: "Cambios",
					START: "Comienzo",
					END: "Final",
					CHANGE: "Cambiar detalles"
				}
			},
			DATA_LOG: {
				GENERAL: {
					DATA_LOG: "Registro de datos",
					CAPACITY_START: "Capacidad inicial (litros)",
					CAPACITY_END: "Capacidad restante (litros)",
					FUEL_CHANGE: "Cambio",
					FUEL_GRAPH: "Gráfico"
				}
			},
			GENERAL: {
				COLUMN: {
					DEVICE: "Dispositivo",
					TIME_RUN: "Tiempo de viaje",
					DISTANCE_RUN: "Distancia recorrida (km)",
					FUEL_NORM: "Norma de combustible",
					EXCEED_SPEED: "La cuenta excede la velocidad",
					COUNT_STOPS: "Cuenta paradas",
					TIME_STOP_ENGINE: "Tiempo de parada del motor",
					COUNT_STOP_ENGINE: "Cuenta parada motor",
					DRIVEN: "Impulsado"
				},
				MESSAGE: {
					TEXT_NOTIFI: "Se encontraron demasiados resultados. Seleccione un formato de exportación de informe. Mientras tanto, puede usar el sistema y le notificaremos cuando el informe esté listo.",
					TEXT_WAIT: "Crea un informe para comenzar. Por lo general, no toma más de 5 minutos. Mientras tanto, puede usar el sistema y le notificaremos cuando haya terminado.",
					TEXT_EXPORT: "Reporte de exportacion",
					TEXT_SUCSSEC: " has created. Please click to download "
				}
			},
			SUMMARY: {
				DAY: {
					COLUMN: {
						DATE: "Date a ti mismo",
						STARTED_WORK: "Empieza a moverte",
						FINISHED_WORK: "Terminar de mover",
						QUANTITY: "Cantidad",
						ENGINE: "Motor",
						STOP_ENGINE: "De marcha en vacío",
						SPEED: "Velocidad (km / h)",
						MAX: "Max",
						AVG: "Promedio",
						START_DAY: "Comienzo del día",
						END_DAY: "Fin del día",
						CONSUME: "Consumir"
					}
				}
			},
			GEOFENCE: {
				SUMMARY: {
					TIME_STOP: "Tiempo de parada (minuto)",
					COUNT_IN: "Cuéntalo",
					COUNT_OUT: "Pasar por alto",
					SUMMARY_TIME: "Tiempo Total",
					SHOW_MAP: "Haga clic en toque ver geofence",
					ALL_DEVICE: "Todos los dispositivos",
					ALL_GEOFENCE: "Toda la geovalla"
				},
				DETAIL: {
					TIME_IN: "Tiempo en",
					TIME_OUT: "Se acabó el tiempo",
					NO_GEOFENCE: "Cuenta no geofence"
				}
			},
			TEMPERATURE: {
				GENERAL: {
					REPORT_TEMPERATURE: "Informe de temperatura",
					MESSAGE: "Por favor busque antes de exportar informe!"
				},
				COLUMN: {
					SHOW_POINTS: "Mostrar puntos"
				}
			},
			STATUS_HISTORY: {
				STATUS: {
					AIR_OFF: "Aire apagado",
					AIR_ON: "Aire encendido",
					ENGINE_OFF: "Motor apagado",
					ENGINE_ON: "Motor encendido",
					RUN: "Correr",
					STOP: "Aire Acondicionado"
				},
				OBJ: {
					ROUTE: "Ruta",
					ENGINE: "Motor",
					AIR: "Aire acondicionado"
				},
				COLUMN: {
					DURATION: "Duración",
					ALL_STATUS: "Todo el estado"
				}
			},
			TOLL_STATION: {
				COLUMN: {
					SEGMENT: "Carretera de segmento",
					TOLL: "Peaje",
					ALL_PRICE: "Precio total"
				}
			}
		},
		SIM: {
			VIETTEL_3G: "Viettel 3G",
			VINAPHONE_PAY_PAID: "Vinaphone prepago",
			MOBIPHONE_PAY_PAID: "Mobiphone prepago",
			VIETTEL_PAY_PAID: "Viettel prepago",
			VINAPHONE_POST_PAID: "Vinaphone pospago",
			MOBIPHONE_POST_PAID: "Mobiphone pospago",
			VIETTEL_POST_PAID: "Viettel pospago",
			VINAPHONE_3G: "Vinaphone 3G",
			MOBIPHONE_3G: "Mobiphone 3G",
			VNET_DATA_M1: "Datos VNET M1",
			VNET_DATA_M2: "Datos VNET M2",
			UNKNOWN: "Desconocido"
		},
		SENSOR_TYPE: {
			FUEL: "Combustible",
			FUEL_SENSOR: 'Tanque de combustible adicional',
			TEMPERATURE: "Temperatura",
			HUMID: "Húmedo",
			PRESSURE: "Presión",
			OTHER: "Otro"
		},
		SENSOR_TEMPLATE: {
			CALIBRATION: "Calibración",
			ORIGINAL: "Original",
			LINEAR: "Lineal"
		},
		MOMENT: {
			IN_DAY: "del día",
			TO_DAY: "hoy"
		},
		LIBARY: {
			DATE_RANGE_PICKER: {
				TO_DAY: "Hoy",
				ADD_1_WEEK: "1 semana después",
				ADD_2_WEEK: "2 semanas después",
				ADD_1_MONTH: "1 mes después",
				ADD_2_MONTH: "2 meses después",
				ADD_3_MONTH: "3 meses despues",
				ADD_6_MONTH: "6 meses despues",
				ADD_1_YEAR: "El próximo año",
				ADD_2_YEAR: "2 años después",
				YESTERDAY: "Ayer",
				THIS_WEEK: "Esta semana",
				LAST_WEEK: "La semana pasada",
				LAST_7_DAYS: "Los últimos 7 días",
				THIS_MONTH: "Este mes",
				LAST_MONTH: "El mes pasado",
				LAST_30_DAY: "Últimos 30 días",
				CUSTOM_RANGE_LABLE: "Opcional",
				APPLY_LABLE: "Aplicar",
				CANCEL_LABLE: "Cancelar",
				FROM_LABLE: "De",
				TO_LABLE: "A",
				DAY_OF_WEEK: {
					SU: "Domingo",
					MO: "Lunes",
					TU: "Martes",
					WE: "Miércoles",
					TH: "Jueves",
					FR: "Viernes",
					SA: "Sábado"
				},
				MONTH_NAME: {
					JANUARY: "Enero",
					FEBRUARY: "Febrero",
					MARCH: "Marzo",
					APRIL: "Abril",
					MAY: "Mayo",
					JUNE: "Junio",
					JULY: "Julio",
					AUGUST: "Agosto",
					SEPTEMBER: "Septiembre",
					OCTOBER: "Octubre",
					NOVEMBER: "Noviembre",
					DECEMBER: "Diciembre"
				}
			}
		},
		COMMON: {
			GENERAL: {
				BASE: "Básico",
				COMMAND: "Enviar comando",
				SEARCH_ADVANCED: "Búsqueda Avanzada",
				RESET: "Reiniciar",
				SEARCH: "Buscar",
				PARAMETER: "Parámetros",
				EXAMPLE: "Por ejemplo",
				ACTIVE: "Activar",
				INACTIVE: "No activado",
				BLOCK: "Bloquear",
				EXPORT_TO: "Exportar datos",
				DOWNLOAD: "Descargar",
				DOWNLOAD_ALL: "Descargar todo",
				SELECT_ALL: "Seleccionar todo",
				SIGN_OUT: "Desconectar",
				WELCOME_TO_TOP_GPS: "Bienvenido a TOP GPS",
				CHANGED: "Carga",
				NO_BATTERY: "Sin batería",
				ALL: "Todas",
				TOTAL: "Total",
				DATETIME: "Fecha y hora",
				POWER_ENGINE: "Motor de potencia",
				BATTERY: "Batería",
				CLOSE: "Cerca",
				OPEN: "Abierto",
				UNKNOWN: "Desconocido",
				EXPORT_EXCEL: "Exportación excel",
				EXPORT_TXT: "Exportar txt",
				EXPORT_PDF: "Exportar pdf",
				CLICK_SHOW_IMAGE: "Haz clic para mostrar la imagen",
				SERVERS: "Servidores",
				INSERT_TEXT: "Insertar texto aquí",
				PASSWORD: "Contraseña",
				ALL_STATE: 'Todos os status'
			},
			ACTIONS: {
				ACTIONS: "Acciones",
				DELETE: "Eliminar",
				EDIT: "Editar",
				ADD: "Añadir",
				ADD_NEW: "Crear nuevo",
				SAVE_CHANGES: "Guardar cambios",
				CLOSE: "Cerca",
				CONFIRM: "Confirmar",
				CANCEL: "Cancelar",
				SEND: "Enviar",
				DETAIL: "Detalles",
				ACTIVE: "Activar",
				SOLD: "Vender",
				LOCK: "Bloquear",
				UNLOCK: "Desbloquear",
				LOCK_ON: "Cerradura abierta",
				SAVE: "Salvar",
				MOVE_POINT: "Punto de movimiento",
				MOVE: "Moverse",
				CANCEL_TRANSACTION: "Cancelar transacción",
				REQUEST_CANCEL: "Solicitud cancelar"
			},
			COLUMN: {
				NAME: "Nombre",
				DESCRIPTION: "Descripción",
				MODIFIELD_BY: "Editado por",
				MODIFIED_BY: "Editado por",
				UPDATED_DATE: "Fecha actualizada",
				SORT_ORDER: "Orden",
				ACTIONS: "Acciones",
				CREATED_BY: "Creador",
				CREATED_DATE: "Fecha de creación",
				UPDATED_BY: "El arreglador",
				IMEI: "IMEI",
				USERNAME: "Cuenta",
				TYPE: "Tipo",
				SIM: "SIM",
				SERVICE_EXPIRE: "El servicio expira",
				SERVICE_EXPIRE_OLD: "El servicio caduca viejo",
				SERVICE_EXPIRE_NEW: "Servicio caduca nuevo",
				WARRANTY_EXPIRE: "La garantía caduca",
				STATUS: "Estado",
				CANCEL: "Cancelar",
				VALUE: "Valor",
				ADDRESS: "Habla a",
				DELETE: "Eliminar",
				ADD_DAY: "Agregar días",
				POINT: "Puntos",
				POINT_OLD: "Puntos de edad",
				POINT_NEW: "Puntos nuevos",
				UNIT: "Unidad",
				CREATE_BY: "Creador",
				ACTION: "Las funciones",
				NAME_KEY: "Nombre clave",
				STATUS_CAR: "Estado del vehículo",
				VIN: "VIN",
				BATTERY: "Batería",
				UPDATE_TIME: "Tiempo de actualizacion",
				POWER: "Poder",
				SIGNAL: "Señal",
				ACTIVE: "Activar",
				IS_SELL: "Vendido",
				KEY_LANGUAGE: "Lenguaje clave",
				KEY_TRANSLATED: "Clave traducida",
				USER_TRANSACTION: "Transacción de cuenta",
				PAY: "Pagar",
				NOTE: "Aviso",
				IMPLEMENTER: "Implementador",
				PAY_AT: "Pagar en",
				BILL_IMG: "Imagen de Bill",
				USER_RENEWAL: "Renovación de usuario",
				ACCOUNT_DEVICE: "Dispositivo de cuenta",
				NUMBER_PLATE: "Número de placa",
				DEVICE_NAME: "Nombre del dispositivo",
				EMAIL: "Email",
				PERMISSIONS: "Permiso",
				LISTPERMISSION: "Permiso de lista",
				TIMEFORMAT: "Formato de tiempo",
				UNITVOLUME: "Unidad de volumen",
				UNITWEIGHT: "Unidad de peso",
				SENSORS: "Sensor",
				LANGUAGE: "Idioma",
				ROLENAME: "Nombre de rol",
				WEEKFIRSTDAY: "Semana primer día",
				ROLEID: "Identificación del rol",
				ICONURL: "URL del icono",
				ICONMAPURL: "URL del mapa de iconos",
				ICONSVG: "IconSvg",
				WORK: "Acción",
				PHONE: "Número de teléfono",
				DEVICE_TYPE: "Tipo de dispositivo",
				AMOUNT: "Cantidad",
				ACCOUNT_TRANSACTION: "Transacción de cuenta",
				DETAIL: "Detalle",
				ACCOUNT: "Cuenta",
				SEVER_NAME: "Nombre del servidor",
				SERVER: "Servidor",
				DISTRIBUTOR: "Distribuidor",
				SUB_USERNAME: "Cuenta 3",
				TOTAL: "Total",
				ADD_POINT: "Añadir punto",
				MOVE_POINT: "Punto de movimiento",
				RENEWS: "Renueva",
				COUNT_TRANSACTION: "Contar transacción",
				TOTAL_POINT: "Punto total",
				AMOUNT_PAID: "Cantidad pagada",
				TOTAL_AMOUNT: "Cantidad total",
				RENEWS_COUNT: "Renueva el recuento",
				DATE: "Date a ti mismo",
				TITLE: "Título",
				USERNAME_MAIN: "Nombre de usuario principal",
				COMMAND_NAME: "Nombre del comando",
				INFO: "Informacion",
				FEEDBACK: "Realimentación",
				CONTENT: "Contenido",
				FLATFORM: "Forma plana",
				PROCESS: "Proceso",
				IP_ADDRESS: "Dirección IP",
				ACCESS: "Acceso",
				FEEDBACK_INFO: "Información de comentarios",
				PROCESS_INFO: "Información del proceso",
				ACCESS_INFO: "Información de acceso",
				IMAGE: "Imagen",
				IMAGES: "Imágenes",
				USER_AGENT: "Agente de usuario",
				TIME: "Hora",
				LAT_LNG: "Latitude, Longitude",
				SPEED: "Velocidad",
				GSM: "GSM",
				GPS: "GPS",
				BASIC: "Básico",
				TIME_DEVICE: "Dispositivo de tiempo",
				LINK_DOCUMENT: "Enlaces de documentos",
				KEY: "Llave",
				COMMAND: "Mando",
				NAMEKEY: "Nombre clave",
				OPEN_SOURCE_LICENSES_LINK: 'Enlace de licencias de código abierto',
				SUPPORT_MAIL: "Correo electrónico de soporte",
				SUPPORT_SERVICE_PHONE_NUMBER: "Número de teléfono del servicio de soporte",
				SUPPORT_TECH_PHONE_NUMBER: "Número de teléfono de soporte técnico",
				TEAM_OF_SERVICE_LINK: "Equipo de enlace de servicio"
			},
			VALIDATION: {
				REQUIRED_FIELD: "por favor no lo deje en blanco",
				EMAIL: "formato de correo electrónico incorrecto",
				MIN_LENGTH: "ingrese al menos {{min}} caracteres",
				MAX_LENGTH: "ingrese un máximo de {{max}} caracteres",
				REQUIRED_FIELD_NAME: "por favor no lo deje en blanco",
				PLEASE_CHOOSE_TYPE: "Porfavor seleccione una categoría",
				PLEASE_ENTER_CORRECT_FORM: "por favor ingrese la forma correcta",
				INVALID: "valor no válido",
				LEASE_ENTER_NUMBER: "por favor ingrese el número",
				PLEASE_ENTER_MIN_LENGTH: "ingrese al menos {{min}} caracteres",
				PLEASE_ENTER_MAX_LENGTH: "por favor ingrese hasta {{max}} caracteres",
				PLEASE_ENTER_RANGE_LENGTH: "ingrese de {{min}} a {{max}} caracteres",
				PLEASE_ENTER_RANGE: "ingrese el número de {{min}} a {{max}}",
				PLEASE_ENTER_MIN: "ingrese un número mayor que {{min}}",
				PLEASE_ENTER_MAX: "ingrese un número menor que {{max}}",
				PLEASE_ENTER_PHONE: "por favor ingrese el número de teléfono",
				PLEASE_ENTER_EMAIL: "por favor ingrese su correo electrónico",
				PLEASE_ENTER_NUMBER: "por favor ingrese el número",
				REQUIRED_DEVICE: "Lista de dispositivos requeridos",
				INVALID_EMAIL: " Email '{{email}}' invalid "
			},
			MESSAGE: {
				INTERNAL_SERVER_ERROR: "Error del lado del servidor",
				NO_RECORDS_FOUND: "Datos no encontrados",
				NOT_FOUND_DATA: "Datos no encontrados",
				COMFIRM_DELETE: "¿Estás seguro de que deseas eliminar este elemento?",
				COMFIRM_RESET: "¿Restablecer la contraseña?",
				PASSWORD_DEFAULT: "La contraseña predeterminada es '68686868'",
				COPIED: "Copiado",
				COPY: "Copiar",
				COPY_FALSE: "Error al copiar",
				NOTHING_SELECTED: "No seleccionado",
				CONFIRM_CANCEL_TRANSACTION: "¿Está seguro de que desea cancelar esta transacción?",
				CONFIRM_REQUEST_CANCEL_TRANSACTION: "¿Está seguro de que desea solicitar cancelar esta transacción?",
				SELECT_DEVICE_TO_DISPLAY: "Seleccione el dispositivo para mostrar",
				SELECT_CAMERA_TO_DISPLAY: 'Seleccione la cámara para mostrar',
				PLEASE_CHOOSE_DEVICE: "Por favor elija el dispositivo",
				DEVICE_NOT_SUPPORT_DEBUG: "Este dispositivo no es compatible para registrar datos",
				PLEASE_ENTER_DEVICE: "Por favor ingrese IMEI"
			},
			DATA_TABLE: {
				SELECT_PAGE_SIZE: "Seleccione el número de registros para mostrar",
				SHOW: "espectáculo",
				OF: "de",
				SEARCH: "Buscar",
				RESET: "Reiniciar",
				REFRESH: "Actualizar",
				PLEASE_WAIT: "Por favor espera ...",
				LAST_PAGE: "Última página",
				NEXT_PAGE: "Siguiente página",
				FIRST_PAGE: "Primera página",
				PREVIOUS_PAGE: "Pagina anterior",
				CREATED_DATE: "Fecha de creación",
				SUCCESS: "¡Éxito!",
				FAILED: "¡Ha fallado!"
			},
			USER_TREE: {
				USER_NAME__ACCOUNT: "Nombre de usuario / Cuenta",
				POINT: "Puntos",
				STOCK: "Almacén",
				TOTAL: "Suma"
			},
			USER_INFO: {
				USER_INFO: "Usuario de información",
				DETAIL: "Detalles",
				GO_TO_USER_MANAGE_PAGE: "Ir a la página de administración de usuarios",
				GO_TO_DEVICE_MANAGE_PAGE: "Ir a la página de administración del dispositivo",
				GO_TO_MAP_PAGE: "Ir a la página del mapa",
				COMFIRM_DELETE: "¿Estás seguro de que deseas eliminar este elemento?"
			},
			LIST_DEVICE: {
				GENERAL: {
					OFF: "Apagado",
					CLOSED: "Cerrado",
					ON: "En",
					OPEN: "Abierto"
				},
				NOT_UPDATE_DATA: "Datos no actualizados",
				DEVICE_NOT_SET_COORDINATES: "Ubicación no actualizada o señal GPS pobre",
				DEVICE_EXPIRED: "El dispositivo ha expirado",
				ALL_DEVICE: "Todos los dispositivos",
				ALL: "Todas",
				STOP: "Detener",
				RUN: "Correr",
				EXPIRED: "Caducado",
				LOST_GPS: "GPS perdido",
				LOST_SIGNAL: "Sin señal",
				FILTER: "Filtrar",
				FILTERS: "Filtros",
				ALL_GROUPS: "Todos los grupos",
				ALL_TYPES: "Todas las categorias",
				RESET: "Filtrar",
				DEVICE_GROUP: "Grupo de dispositivos",
				DEVICE_TYPE: "Tipo de dispositivo",
				DEVICE_INACTIVE: "El dispositivo aún no ha activado el servicio.",
				IMEI: "IMEI",
				GROUPS: "Grupos",
				ADDRESS: "Habla a",
				DISTANCE_TODAY: "Distancia del día",
				UPDATE_TIME: "Tiempo de actualizacion",
				NUMBER_PLATE: "Placa",
				VIN_NUMBER: "Número VIN",
				BATTERY: "Batería",
				CHANGED: "Carga",
				DRIVING_TODAY: "Tiempo de conducción",
				VIN: "VIN",
				SIMNO: "Número de SIM",
				GEOFENCE: "Geofence",
				FOLLOW_POPUP: "Abrir ventana de monitoreo del dispositivo",
				FOLLOW_NEW_WINDOW: "Abra la página de seguimiento del dispositivo.",
				GSM: {
					LOST_GSM: "Perdió",
					WEAK: "Débiles",
					MEDIUM: "Medio",
					GOOD: "Bueno",
					EXCELLENT: "Excelente"
				},
				POWER_VOLTAGE: "Poder",
				DRIVER: "Conductor",
				FOLLOW: "Seguir",
				UNFOLLOW: "Dejar de seguir",
				SHOW_ALL_ON_MAP: "Mostrar todo en el mapa",
				HIDE_ALL_ON_MAP: "Ocultar todo en el mapa",
				HIDE_ON_MAP: "Ocultar en el mapa",
				SHOW_ON_MAP: "Mostrar en el mapa",
				HISTORY_TRIPS: "Viajes de historia",
				FAVORITE: "Favorito",
				DISFAVOUR: "Desfavorecer",
				NOTIFICATIONS: "Notificaciones",
				VIEW_FAVORITE_MODE: "Ver modo favorito",
				DETAIL: "Detalle",
				SORT_CHECK: "Ordenar el dispositivo seleccionado",
				SEND_COMMAND: "Enviar comando",
				DEVICE_NAME: "Nombre del dispositivo"
			},
			ERROR: {
				SOMTHING_WENT_WRONG: "¡Ocurrió un error!",
				RETURN_BACK: "Regresa",
				NOT_PERMISSION_TO_VIEW_THIS_PAGE: "¡No estás autorizado para ver esta página!"
			},
			ALERT_POPUP: {
				EVENT_TYPE: "Tipo de evento",
				TIME_IN_OUT: "Tiempo de entrada y salida",
				TIME: "Hora",
				DEVICE: "Dispositivo",
				GEOFENCE: "Geofence",
				ADDRESS: "Habla a",
				NOTIFICATION: "Notificación"
			},
			MAP_SETTING: {
				MAP_CONFIG: "Configuración del mapa",
				LIST_DEVICE: "Lista de dispositivo",
				INFO_DEVICE: "Cuadro de información del dispositivo",
				TRACKING: "Rastreo",
				HISTORY_TRIPS: "Viajes de historia",
				CHART: "Gráfico",
				ICON: "Icono",
				DURATION: "Duración",
				SPEED: "Velocidad",
				BATTERY: "Batería",
				GSM: "GSM",
				NUMBER_PLATE: "Número de placa",
				IMEI: "IMEI",
				SIM_NO: "SIM no",
				VIN: "VIN",
				POWER_VOLTAGE: "Tensión de alimentación",
				DISTANCE_TODAY: "Distancia hoy",
				DRIVER: "Conductor",
				DRIVING_TODAY: "Conduciendo hoy",
				UPDATE_TIME: "Tiempo de actualizacion",
				GROUP: "Grupo",
				GEOFENCE: "Geofence",
				ADDRESS: "Habla a",
				OTHER_DATA: "Otros datos",
				BASE: "Base",
				ADVANCE: "Avanzar",
				DRIVING: "Conducción",
				STOP: "Detener",
				DISTANCE: "Distancia",
				SUMMARY: "Resumen",
				AIR: "Aire",
				DOOR: "Puerta",
				ENGINE: "Motor",
				MAP_MULTI_CONFIG: "Configuración de seguimiento de vehículos múltiples",
				DISPLAY: "Mostrar cuadro de información",
				SHOW_FULL: "Mostrar abierto",
				BEN: 'Ben',
				ACC: 'Acc',
				SENSOR: 'Sensor'
			},
			USER_PROFILE: {
				POINTS: "puntos",
				NOTE_POINT: "1 punto <=> 1 mes",
				AVAILABLE_BALANCE: "Saldo disponible"
			},
			EXCEL: {
				START_TIME: "Hora de inicio",
				END_TIME: "Hora de finalización",
				TIMEZONE: "Zona horaria",
				COUNT: "Contar",
				TOTAL: "Total",
				AMOUNT: "Cantidad",
				USER_LOGIN: "Inicio de sesión de usuario",
				TOTAL_POINT: "Punto total",
				TOTAL_COUNT: "Cuenta total",
				USER_NAME: "Nombre de usuario"
			},
			FEEDBACK: {
				GENERAL: {
					FEEDBACK: "Feebback",
					CONTENT: "Contenido",
					CONTENT_NOTE: "Describe el problema o comparte ideas",
					SHOTSCREEN: "Pantalla de tiro",
					IMAGE: "Imagen",
					CHOOSE_STATUS: "Elige estado"
				},
				MESSAGE: {
					BROWSER_NOT_SUPPORT: "¡El navegador no admite esta función!"
				},
				STATUS: {
					CHOOSE_STATUS: "Elige estado",
					PENDDING: "Pendiente",
					COMPLETE: "Completar",
					SKIP: "Omitir"
				}
			},
			UPLOAD_FILE: {
				GENERAL: {
					CROP_IMAGE: "Delimitar imagen",
					PREVIEW: "Avance"
				},
				VALIDATION: {
					MAX_COUNT: "Seleccione hasta {{maxCount}} archivos",
					LENGTH_FILE: "Seleccione un archivo más pequeño que {{maxLength}} MB"
				}
			},
			COMMAND: {
				RESET: "Reiniciar",
				RESET_FACTORY: "Fábrica de reinicio",
				INFO: "Obtener información",
				TIME_ZONE: "Obtener zona horaria",
				SET_TIME_ZONE: "Establecer zona horaria",
				LOCK_CONFIG: "Bloquear config",
				UNLOCK_CONFIG: "Desbloquear config",
				APN: "Obtenga APN",
				SET_APN: "Establecer APN",
				STATUS: "Estado",
				SET_STATUS: "Establecer estado",
				LOCATION: "Obtener ubicación",
				HEARBEAT_INTERVAL: "Intervalo de latidos",
				TRACKING_INTERVAL: "Intervalo de seguimiento",
				MODE: "Obtener modelo",
				SET_MODE: "Establecer modelo",
				SHOCK_MODE: "Obtener el modo de choque",
				SLEEP_MODE: "Obtener el modo de suspensión",
				SMS_CENTER: "Obtener sms center",
				SET_SMS_CENTER: "Establecer centro de sms",
				SOS: "llamada de socorro",
				IP_PORT: "Obtener puerto ip",
				SET_IP_PORT: "Establecer puerto ip",
				HOST: "Obtener anfitrión",
				SET_HOST: "Establecer anfitrión",
				TURN_OFF_ENGINE: "Giro de motor",
				TURN_ON_ENGINE: "Enciende el motor",
				SAVES_BATTERY: "Ahorra batería",
				TURN_OFF: "Apagar",
				TURN_ON: "Encender",
				CHANGE_PASSWORD: "Cambia la contraseña",
				AUTHORIZATION: "Autorización"
			},
			SHOW: "espectáculo",
			OF: "de",
			SEARCH: "Buscar",
			RESET: "Reiniciar",
			REFRESH: "Actualizar",
			PLEASE_WAIT: "Por favor espera ...",
			LAST_PAGE: "Última página",
			NEXT_PAGE: "Siguiente página",
			FIRST_PAGE: "Primera página",
			PREVIOUS_PAGE: "Pagina anterior",
			CREATED_DATE: "Fecha de creación",
			SUCCESS: "¡Éxito!",
			FAILED: "¡Ha fallado!",
			EDIT_BY: "Editado por",
			UPLOAD: "Subir",
			SAVE: "Salvar"
		},
		PERMISSIONS: {
			GENERAL: {
				USER: "Usuario",
				DEVICE: "Dispositivo",
				ROLE: "Papel",
				PERMISSION: "Permiso",
				MAP: "Mapa",
				REPORT: "Reporte",
				MANAGE: "Gestionar",
				ADMIN: "Administración",
				POINT: "Punto",
				UTILITIES: "Utilidades"
			},
			PROFILE: {
				OWN_VIEW: "Ver perfil"
			},
			USER: {
				LIST: "Lista de usuarios",
				UPDATE: "Actualizar usuario",
				CREATE: "Crear usuario",
				DELETE: "Borrar usuario",
				TREE: "Ver árbol de usuarios"
			},
			COMMAND: {
				MANAGE: {
					STATUS: "Cambiar comando de estado"
				},
				ACTION: {
					LOCK: "Comando de bloqueo"
				}
			},
			DEVICE: {
				ACTION: {
					COMMAND: "Función de envío de comandos",
					IMPORT_DEVICE: "Función de importación del dispositivo",
					RENEWED: "Dispositivo de renovación",
					SELL: "Vender dispositivo",
					EDIT: "Editar dispositivo",
					STATUS: "Cambiar dispositivo de estado",
					RENEWS: "Renueva el dispositivo",
					SORT_ORDER: "Dispositivo de orden de clasificación",
					DEVICE_GROUP: "Función de creación de grupo de dispositivos",
					DRIVER: "Función de creación de controlador"
				},
				EDIT: {
					SIM: "Editar SIM del dispositivo",
					GROUP: "Editar dispositivo grupal",
					ICON: "Editar ícono de dispositivo",
					ACTIVE_STATUS: "Cambiar dispositivo de estado activo",
					ACTIVE_CUSTOMER: "Cliente activo",
					DESCRIPTION_ADMIN: "Descripciones para admin",
					TYPE: "Editar tipo de dispositivo",
					ADVANCED: "Editar avanzado",
					SOLD: "Editar vendido",
					SERVICE_EXPIRE: "Editar caducidad del servicio",
					EXTENSION: "Extensión de configuración",
					DEBUG: "Registrar datos brutos",
					LIVESTREAM: "Livestream"
				},
				MANAGE: "Administrar dispositivo",
				DELETE: "Eliminar dispositivo",
				RENEWED: {
					ONE_MONTH: "Se renueva con un mes",
					USER_OLD_SERVICE: "Las renovaciones usan el servicio antiguo"
				},
				DEBUG: "Registrar datos brutos"
			},
			PERMISSION: {
				MANAGE: "Administrar permiso",
				LIST: "Permiso de lista"
			},
			ROLE: {
				MANAGE: "Gestionar rol",
				LIST: "Rol de lista"
			},
			MAP: {
				MAP: "Mapa",
				TRACKINGS: "Rastrea múltiples vehículos",
				PLAYBACK: "Reproducción",
				MAP_LITE: "Mapa lite",
				MAP_FULL: "Mapa completo"
			},
			REPORT: {
				REPORT: "Reporte",
				FUEL: "Informe de combustible",
				DEVICE: "Informar dispositivo",
				ROUTE: "Informar ruta",
				ENGINE: "Motor de informes",
				DATA_LOG: "Historial de datos",
				SUMMARY_DAY: "Día resumen",
				ROUTE_SIMPLE: "Ruta simple",
				GENERAL: "Resumen"
			},
			MANAGE: {
				MANAGE: "Gestionar",
				USER: "Gestión de usuarios",
				DEVICE: "Gestión de dispositivos",
				DEVICE_GROUP: "Gestionar grupo de unidades",
				COMMAND: "Commange administrar",
				ALIAS: "Alias de usuario",
				ALERT: "Alerta",
				GEOFENCE: "Geofence",
				SCHEDULE_REPORT: "Informe de horario",
				ALERT_RULES: "Regla de alerta",
				ROLE_ALIAS: "Alias de rol",
				ACTIVITY_LOG: "Registro de actividades",
				POI: "PDI",
				DRIVER: "Conductor"
			},
			ADMIN: {
				ADMIN: "Administración",
				LOGIN_PAGE: "Página de inicio de sesión",
				TRANSPORT_TYPE: "Tipo de transporte",
				SIM_TYPE: "Tipo de SIM",
				ROLE: "Papel",
				PERMISSION: "Permiso",
				DEVICE_TYPE: "Tipo de dispositivo",
				SENSOR_TEMPLATE: "Plantilla de sensor",
				ICON: "Icono",
				TOLL_STATION: "Estación de peaje",
				SYSTEM_LOG: "Registro del sistema",
				FEEDBACK: "Realimentación",
				UTILITIES: "Utilidades",
				NOTICES: "Sistema de avisos",
				POI_TYPE: "Tipo de PDI"
			},
			POINT: {
				ADD: "Añadir puntos",
				MOVE: "Mover puntos",
				EXTEND: "Ampliar",
				TRANSACTION_HISTORY: "Historial de transacciones",
				RENEWS_HISTORY: "Renueva la historia",
				MANAGE: "Gestionar",
				POINTS: "Puntos",
				SYNTHESIS_REPORT: "Informe de síntesis"
			},
			TRANSACTION: {
				UPDATE: "Actualizar transacción",
				REQUEST_CANCEL: "Solicitud cancelar",
				CANCEL: "Cancelar"
			},
			UTILITIES: {
				UTILITIES: "Utilidades",
				TRACKINGS: "Rastreo"
			},
			FUEL: {
				SUMMARY: "Resumen"
			}
		},
		SYSTEM: {
			UPDATED_VERSION: "Nueva versión actualizada"
		},
		ICON_TYPE: {
			TAXI: "Taxi",
			CAR: "Coche",
			TRUCK: "Camión",
			CONTAINER: "Envase",
			MOTORBIKE: "Moto",
			MOTORCYCLES: "Motocicletas",
			DIRECTION: "Dirección",
			OTHER: "Otro"
		},
		SENSORTPL: {
			GET_LIST_SUCCESS: "¡Cargue la lista de sensores con éxito!",
			NAME_NOT_VALID: "Nombre de sensor inválido",
			ALREADY_EXIST: "El nombre del sensor ya existe",
			CREATED_SUCCESS: "Sensor creado con éxito",
			CREATED_FAIL: "La creación del sensor falló",
			NOT_FOUND: "El sensor de plantilla no existe",
			UPDATED_FAIL: "Reparación del sensor fallida",
			UPDATED_SUCCESS: "Sensor reparado con éxito",
			DELETED_FAIL: "¡Error al eliminar el sensor!",
			DELETED_SUCCESS: "Sensor eliminado con éxito"
		},
		MAP: {
			STATUS: {
				EXPIRED: "Servicio caducado",
				RUN: "Corriendo",
				STOP: "Detenido",
				LOST_SIGNAL: "Sin señal",
				STOP_IGNITION_ON: "Deja de encender la computadora",
				HISTORY_TRANSFER: "Transmisión histórica",
				NODATA: "Datos no disponibles",
				INACTIVE: "No activado",
				LOST_GPRS: "GPRS perdido",
				LOST_GPS: "GPS perdido",
				ENGINE: "Deja de estacionar",
				HINT: "Aviso",
				TOOLS: "Herramientas",
				SEARCH: "Buscar",
				POOR_GPRS: "GPRS ruim",
				POOR_GPS: "GPS ruim",
			},
			FEATURES: {
				SEARCH: "Buscar ...",
				CREATE_LANDMARK: "Crear nuevo hito",
				CREATE_GEOFENCE: "Crear nueva geovalla",
				CENTER: "Punto central",
				EDIT: "Editar",
				DELETE: "Eliminar",
				LOCATE: "Localizar",
				ZOOM_IN: "Acercarse",
				ZOOM_OUT: "Disminuir el zoom",
				CLUSTER: "Mostrar clúster",
				FITBOUND: "Contraer área de visualización",
				STYLE_MARKER_ONE: "Etiqueta",
				STYLE_MARKER_TWO: "Etiqueta + icono",
				MARKER_STYLE: "Tipo de visualización",
				MAP_TYPE: "Tipo de mapa",
				ROAD_MAP: "Mapa vial",
				ROAD_MAP_TOPGPS: "Mapa Topgps",
				SATELLITE: "Mapa satelital",
				GOOGLE_ROAD_MAP: "Google hoja ruta",
				GOOGLE_SATELLITE: "Mapa satelital de Google",
				LOCATION: "Ubicación",
				GEOFENCES: "Geofence",
				LANDMARK: "Punto de referencia",
				FOLLOW: "Seguir",
				MARKER_POPUP: "Mostrar información al pasar el mouse",
				FULL_SCREEN: "Pantalla completa",
				NORMAL_SCREEN: "Pantalla Normall",
				TRAFFIC: "Tráfico",
				BRIGHTNESS: "Brillo",
				MAP: "Mapa",
				INFO_BOX: "Caja infor",
				PLAY: "Jugar",
				PAUSE: "Pausa",
				SPEED: "Velocidad",
				COLOR: "Mostrar base de color en velocidad",
				CENTER_POINT: "Punto central",
				DISTANCE_TITLE: "Medir la distancia",
				DISTANCE_GUIDE: "Haga clic en el mapa para agregar un punto",
				DISTANCE_TOTAL: "Total",
				NO_DATA: "Sin datos",
				DIRECTION: "en",
				DIRECTION_TITLE: "Direcciones",
				DIRECTION_START: "Elija el punto de partida o haga clic en el mapa",
				DIRECTION_ADD: "Agregar destino",
				DIRECTION_END: "Elija destino o haga clic en el mapa",
				DIRECTION_DIRECTION: "Buscar en Google Maps",
				DIRECTION_TOLL_FEE_DIRECTION: "Encontrar ruta",
				DIRECTION_GOOGLE: "Usamos Google Maps para mejorar su experiencia de búsqueda",
				DIRECTION_HINT: "Escriba @ para mostrar la lista de dispositivos",
				DIRECTION_NO_ROUTE: "No puedo encontrar ninguna ruta",
				DIRECTION_GO: "Vía",
				DIRECTION_FEE: "Tarifa estimada",
				DIRECTION_TIME: "Hora prevista",
				DIRECTION_DISTANCE: "Distancia estimada",
				DIRECTION_HIGHWAY: "Autopista",
				DIRECTION_NATIONAL_ROAD: "carretera nacional",
				DIRECTION_PROVINCE_ROAD: "Carretera provincial",
				DIRECTION_UNKNOWN: "Desconocido",
				DIRECTION_LOCATION: "Obtenga su ubicación",
				DIRECTION_AVOID_FEE: "Evitar los peajes"
			},
			CLUSTER: {
				SEARCH: "Buscar ..."
			},
			MARKER: {
				PIN: "Información del pin",
				LOCK_SCREEN: "Bloquear pantalla",
				FOLLOW_DEVICE: "Seguir dispositivo"
			},
			GENERAL: {
				FOLLOW: "Seguir dispositivo"
			},
			TRACKING: {
				GENERAL: {
					ROUTE: "Ruta",
					NOW: "Ahora",
					NOTIFICATIONS: "Notificaciones",
					PLAYBACK: "Reproducción"
				},
				ROUTE: {
					SUMMARY: "Resumen",
					DRIVING: "Conducción",
					STOP: "Detener",
					DISTANCE: "Distancia"
				},
				INFO: {
					OTHER_DATA: "Otros datos",
					AIR: "Aire",
					DOOR: "Puerta",
					ENGINE: "Motor",
					SUMMARY: "Resumen",
					DRIVING: "Conducción",
					STOP: "Detener",
					DISTANCE: "Distancia",
					TODAY: "Hoy",
					STOPED: "Detenido",
					SENSORS: "Sensores",
					ACC: "Acc"
				},
				NOTIFICATIONS: {
					MARK_READ_ALL: "Mark leyó todo",
					REFRESH: "Actualizar",
					SEE_MORE: "Ver más",
					MARK_AS_READ: "Marcar como leído",
					MARK_AS_UNREAD: "Marcar como no leída",
					SHOW_ON_MAP: "Mostrar en el mapa",
					SHOW_DETAIL: "Mostrar detalle"
				},
				CHART: {
					SPEED: "Velocidad",
					SHOW_CHART: "Mostrar gráfico",
					NO_DATA: "Sin datos",
					DATA_LOG: "Registro de datos"
				}
			},
			INFO_BOX: {
				MAP_CONFIG: "Configuración del mapa",
				LIST_DEVICE: "Lista de dispositivo",
				TRACKING: "Rastreo",
				HISTORY_TRIPS: "Viajes de historia",
				CHART: "Gráfico",
				ICON: "Icono",
				DURATION: "Duración",
				SPEED: "Velocidad",
				BATTERY: "Batería",
				GSM: "GSM",
				NUMBER_PLATE: "Número de placa",
				IMEI: "IMEI",
				SIM_NO: "SIM no",
				VIN: "VIN",
				POWER_VOLTAGE: "Tensión de alimentación",
				DISTANCE_TODAY: "Distancia hoy",
				DRIVER: "Conductor",
				DRIVING_TODAY: "Conduciendo hoy",
				UPDATE_TIME: "Tiempo de actualizacion",
				GROUP: "Grupo",
				GEOFENCE: "Geofence",
				ADDRESS: "Habla a",
				OTHER_DATA: "Otros datos",
				BASE: "Base",
				ADVANCE: "Avanzar",
				DRIVING: "Conducción",
				STOP: "Detener",
				DISTANCE: "Distancia",
				SUMMARY: "Resumen",
				AIR: "Aire",
				DOOR: "Puerta",
				ENGINE: "Motor",
				NAME: "Nombre",
				DIRECTION: "Dirección",
				DEVICE_LABEL: "Mostrar nombre"
			}
		},
		MESSEAGE_CODE: {
			AUTH: {
				NOT_VALID: "La cuenta o contraseña es incorrecta",
				NOT_ACTIVE: "La cuenta no ha sido activada o bloqueada",
				DELETED: "El usuario ha sido eliminado",
				SUCCESS: "Autenticación exitosa",
				NOT_FOUND_EMAIL: "El correo electrónico no encontrado",
				EMAIL_SENT: "Correo electrónico de restablecimiento de contraseña enviado",
				LINK_NOT_FOUND: "¡Lo siento! Este es un enlace de restablecimiento de contraseña no válido",
				RESET_SUCCESS: "Restablecimiento de contraseña exitosamente",
				EMAIL_NOT_VALID: "El correo electrónico no encontrado",
				LINKNOT_FOUND: "Enlace no encontrado",
				EXPIRE_DATE: "La cuenta ha expirado",
				INACTIVE: "La cuenta no ha sido activada"
			},
			PERMISSION: {
				NAME_NOT_EMPTY: "El nombre no debe estar en blanco.",
				GROUP_NOT_EMPTY: "El nombre del grupo no puede estar vacío.",
				ALREADY_EXIST: "El nombre del permiso ya existe",
				CREATED: "Crear un permiso exitoso",
				NOT_FOUND: "El nombre del permiso no existe",
				DESCRIPSION_NOT_EMPTY: "La descripción no está vacía.",
				NAME_ALREADY_EXIST: "El nombre del permiso ya existe",
				UPDATED: "Permiso de actualización exitoso",
				DELETED: "Eliminado exitosamente",
				DELETED_FAIL: "Error al eliminar el permiso"
			},
			ROLE: {
				ALREADY_EXIST: "El rol ya existe",
				NOT_CHILD: "La función actual no es la cuenta de inicio de sesión secundaria",
				NOT_FOUND: "El rol no existe",
				PARENTID_NOT_FOUND: "El rol paterno no existe",
				PARENTID_NOT_VALID: "La función principal no es válida.",
				UPDATED_FAIL: "Actualización de rol fallida",
				UPDATED: "Rol actualizado con éxito",
				DELETED_FAIL: "Eliminar rol fallido",
				DELETED_PARENTID_FAIL: "El rol no se puede eliminar mientras hay un hijo disponible",
				USED: "No se puede eliminar el rol mientras el niño está disponible",
				DELETED: "Eliminar rol con éxito",
				CREATED: "Creando roles exitosos"
			},
			USER: {
				UPDATED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS: "Las cuentas de alias no tienen derecho a actualizar las cuentas de alias",
				DELETED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS: "Las cuentas de alias no tienen derecho a eliminar cuentas de alias",
				CREATED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS: "Las cuentas de alias no tienen derecho a crear cuentas de alias",
				NOT_LOAD_CHILD: "Descargar solo usuarios secundarios del usuario de inicio de sesión actual",
				NOT_GET: "",
				PARENTID: "",
				DELETED_NOT_CHILD: "No se pueden eliminar usuarios no secundarios",
				RESET_PASSWORD_NOT_CHILD: "No se puede restablecer la contraseña de otra cuenta",
				PARENTID_NOT_EMPTY: "",
				NOT_VALID: "",
				ROLE_NOT_EMPTY: "El rol no está vacío.",
				ROLE_NOT_VALID: "El rol no es válido.",
				ROLE_NOT_FOUND: "Ningún rol encontrado",
				PARENTID_NOT_FOUND: "",
				NAME_NOT_EMPTY: "",
				NAME_ALREADY_EXIST: "El nombre de la cuenta ya existe.",
				EMAIL_ALREADY_EXIST: "El Email ya existe",
				CREATED: "Creación exitosa de la cuenta",
				DELETED: "Se eliminó exitosamente la cuenta",
				NOT_FOUND: "",
				DELETED_FAIL_HAVE_CHILD: "No se puede eliminar la cuenta con cuentas secundarias",
				DELETED_FAIL_DEVICE: "No se puede eliminar la cuenta que contiene dispositivos",
				DELETED_FAIL_DELETED: "",
				DELETED_FAIL_PARENTID: "No se puede eliminar la cuenta principal",
				DELETED_FAIL_NOT_CHILD: "",
				PARENTID_NOT_VALID: "La cuenta principal no es válida.",
				UPDATED: "Cuenta actualizada con éxito",
				UPDATED_SUCCESS: "Actualización exitosa de la información de la cuenta",
				RESET_FAIL: "Restablecer la contraseña de error",
				RESET: "Restablecimiento de contraseña exitoso",
				NOT_CHILD: "",
				UPDATED_FAIL_PARENTID: "No se puede actualizar la cuenta principal",
				UPDATED_FAIL_NOT_CHILD: "No hay permiso para actualizar cuentas que no sean cuentas secundarias",
				UPDATED_ROLE_NOT_VALID: "Sin permiso para asignar roles de administrador",
				UPDATED_ROLE_NOT_FOUND: "No se puede actualizar el usuario",
				ALREADY_EXIST: "El nombre de usuario ya existe",
				PHONE_ALREADY_EXIST: "El número de teléfono ya existe.",
				PHONE_NOT_VALID: "Numero de telefono invalido",
				EMAIL_NOT_VALID: "Email inválido",
				UPDATED_PARENTID_NOT_VALID: "La cuenta principal no es válida.",
				DELETED_SUCCESS: "Se eliminó correctamente el usuario",
				NOT_CREATED_USER_WITH_PARENT_ID_IS_END_USER: "Las subcuentas no pueden crear cuentas",
				ALIAS_CREATED_SUCCESS: "Alias de usuario creado correctamente",
				UPDATED_ALIAS_SUCCESS: "Actualizar el alias de usuario con éxito",
				ALIAS_DELETED_SUCCESS: "Se eliminó correctamente el alias de usuario",
				FORBIDDEN: "La función requiere permiso para realizar"
			},
			DEVICE: {
				PHONE_ALREADY_EXIST: "SIM ",
				MOVE_SUCCESS: "Transferir dispositivo con éxito",
				MOVE_FAIL: "La transferencia del dispositivo falló",
				EQUIPMENT_NOT_VALID: "Lista de dispositivos inválidos",
				USER_NOT_FOUND: "Usuario no encontrado",
				RECEIVE_NOT_CHILD: "Cuenta de transferencia inválida",
				NO_DEVICE_SELL: "No se han extendido dispositivos.",
				NO_USER_RECEVIE: "Cuenta de llegada desconocida",
				CHANGE_STATUS_SUCCESS: "Cambio de estado del dispositivo exitoso",
				CHANGE_STATUS_FAIL: "Cambio de estado del dispositivo fallido",
				EXTENSION_SUCCESS: "Renovación exitosa del dispositivo",
				USER_EXTENSION_NOT_VALID: "Cuenta de renovación no válida",
				USER_EXTENSION_NOT_CHILD: "Cuenta de renovación no válida",
				NO_DEVICE_EXTENSION: "Ningún dispositivo fue renovado",
				USER_NOT_ENOUGH_POINT: "La cuenta no tiene suficientes puntos para renovar",
				DATE_FORMAT_NOT_VALID: "Período de extensión inválido",
				EXTENSION_FAIL: "Falló la renovación del dispositivo",
				ALREADY_EXIST: "El dispositivo ya existe",
				GROUP_NOT_FOUND: "Grupo de dispositivos no encontrado",
				TYPE_NOT_FOUND: "Tipo de dispositivo no encontrado",
				IMEI_NOT_VALID: "IMEI inválido",
				CREATED: "Añadir dispositivo con éxito",
				USER_NOT_CHILD: "Cuenta no válida",
				GROUP_NOT_VALID: "Grupo de dispositivos inválido",
				SIM_NOT_FOUND: "El tipo de sim no existe",
				SIM_NOT_VALID: "Tipo de sim no válido",
				ICON_NOT_FOUND: "No se encontró ningún tipo de icono",
				ICON_NOT_VALID: "Tipo de icono inválido",
				NO_DEVICE_IMPORT: "No se han agregado dispositivos",
				DELETED_SUCCESS: "Se eliminó correctamente el dispositivo.",
				FORBIDDEN: "La función requiere permiso para realizar",
				DELETED_FAIL: "Error al eliminar el dispositivo",
				SORT_ORDER_SUCCESS: "La disposición del dispositivo fue exitosa",
				SORT_ORDER_FAIL: "¡la configuración del dispositivo falló",
				IMEI_ALREADY_EXIST: "Imei ya existe",
				UPDATED_SUCCESS: "La actualización del dispositivo fue exitosa",
				SELL_SUCCESS: "Vender dispositivo exitosamente",
				SELL_FAIL: "La venta del dispositivo falló",
				NO_SELL: "No se han vendido dispositivos.",
				SOLD: "El dispositivo fue vendido",
				CAN_NOT_SMALLER_5_MINUTES: "No se puede reducir 5 minutos",
				IMEI_NOT_EMPTY: "Ingrese el IMEI"
			},
			SIM: {
				ALREADY_EXIST: "El nombre de la SIM ya existe",
				NOT_VALID: "Nombre sim no válido",
				CREATED_SUCCESS: "Crea un nuevo tipo de sim con éxito",
				NOT_FOUND: "No se encontró ningún tipo de sim",
				UPDATED_SUCCESS: "Se actualizó correctamente el tipo de sim",
				DELETED_SUCCESS: "Se eliminó correctamente el tipo de sim",
				DELETED_FAIL: "Eliminar el tipo de sim falló"
			},
			DEVICETYPE: {
				NOT_FOUND: "El tipo de dispositivo no existe",
				DELETED_SUCCESS: "Se eliminó correctamente el tipo de dispositivo",
				DELETED_FAIL: "Eliminar el tipo de dispositivo falló",
				NAME_NOT_VALID: "El nombre del tipo de dispositivo no está vacío",
				ALREADY_EXIST: "El tipo de dispositivo ya existe",
				UPDATED_SUCCESS: "Actualización del tipo de dispositivo exitosa",
				UPDATED_FAIL: "Error de actualización del tipo de dispositivo",
				CREATED_SUCCESS: "Nuevo tipo de dispositivo creado con éxito",
				CREATED_FAIL: "La creación del nuevo tipo de dispositivo falló",
				PROTOCOL_NOT_EMPTY: "Protocolo no vacío"
			},
			DEVICE_GROUP: {
				SEACH_NOT_VALID: "Datos de búsqueda inválidos",
				GET_LIST_FAIL: "Error al obtener la lista de grupos de dispositivos",
				CREATED_FAIL: "Error al crear el grupo de dispositivos",
				CREATED_SUCCESS: "Grupo de dispositivos creado correctamente",
				ALREADY_EXIST: "El nombre del grupo de dispositivos ya existe",
				NAME_NOT_EMPTY: "El nombre del grupo de dispositivos no está en blanco",
				NOT_FOUND: "El grupo de dispositivos no existe",
				UPDATED_FAIL: "Error en la actualización del grupo de dispositivos",
				UPDATED_SUCCESS: "Actualización del grupo de dispositivos exitosa",
				DELETED_FAIL: "Error al eliminar el grupo de dispositivos",
				DELETED_SUCCESS: "Se eliminó correctamente el grupo de dispositivos"
			},
			SENSOR: {
				DELETED_SUCCESS: "Sensor eliminado con éxito",
				NOT_FOUND: "Sensor no encontrado",
				DELETED_FAIL: "Error al eliminar el sensor",
				UPDATED_SUCCESS: "Sensor actualizado con éxito",
				NAME_NOT_EMPTY: "El nombre del sensor no está vacío",
				DEVICE_NOT_EMPTY: "Dispositivo desconocido",
				TYPE_SENSOR_NOT_EMPTY: "Tipo de sensor desconocido",
				DEVICE_NOT_FOUND: "Dispositivo no encontrado",
				PARAMETER_NOT_VALID: "Parámetro desconocido",
				UPDATED_FAIL: "Actualización del sensor fallida",
				CREATED_SUCCESS: "Sensor creado con éxito",
				CREATED_FAIL: "La creación del sensor falló",
				FORMULA_ERROR: "Fórmula inválida",
				PARAMETER_NOT_FOUND: "El parámetro no es válido.",
				CALIBRATION_FAIL: "Calibración inválida",
				PARAMETER_NAME_NOT_EMPTY: "El parámetro puede no estar en blanco",
				SORT_ORDER_SUCCESS: "Arreglo del sensor exitoso"
			},
			DEVICE_ICON: {
				GET_LIST_SUCCES: "Obtenga la lista de iconos con éxito",
				GET_LIST_FAIL: "Error al obtener la lista de iconos",
				NAME_NOT_EMPTY: "El nombre del icono no puede estar en blanco",
				NAME_KEY_NOT_EMPTY: "La tecla de icono no está vacía",
				ALREADY_EXIST: "El nombre de la clave del dispositivo ya existe.",
				CREATED_FAIL: "La creación del icono del dispositivo falló",
				CREATED_SUCCESS: "Icono de dispositivo creado con éxito",
				UPDATED_FAIL: "Error en la corrección del tipo de icono del dispositivo",
				UPDATED_SUCCESS: "Se reparó correctamente el tipo de ícono del dispositivo",
				NOT_FOUND: "El ícono del dispositivo no existe",
				DELETED_FAIL: "Error al eliminar el icono del dispositivo",
				DELETED_SUCCESS: "Se eliminó con éxito el ícono del dispositivo"
			},
			DRIVER: {
				SEACH_NOT_VALID: "Datos de búsqueda inválidos",
				GET_LIST_FAIL: "No se pudo obtener la lista de conducción",
				CREATED_SUCCESS: "Nueva conducción exitosa",
				PHONE_NOT_VALID: "Numero de telefono invalido",
				EMAIL_NOT_VALID: "Email inválido",
				PHONE_ALREADY_EXIST: "El número de teléfono ya existe.",
				EMAIL_ALREADY_EXIST: "El Email ya existe",
				NAME_NOT_EMPTY: "El nombre del conductor no debe estar en blanco",
				CREATED_FAIL: "La creación de una unidad falló",
				USER_NOT_VALID: "La cuenta especificada no es válida.",
				USER_NOT_CHILD: "La cuenta especificada no es válida.",
				LICENSEN_NUMBER_ALREADY_EXIST: "La licencia de conducir ya existe",
				DRIVER_CODE_ALREADY_EXIST: "El código del controlador ya existe",
				UPDATED_SUCCESS: "Actualizar el controlador con éxito",
				NOT_FOUND: "Conducir no existe",
				BEGIN_DATE_NOT_VALID: "Fecha de emisión no válida",
				EXPIRE_DATE_NOT_VALID: "La fecha de vencimiento no es válida.",
				UPDATED_FAIL: "Actualización de conducción fallida",
				DELETED_FAIL: "Eliminar unidad falló",
				DELETED_SUCCESS: "Eliminar unidad con éxito"
			},
			SENSORTPL: {
				GET_LIST_SUCCESS: "¡Cargue la lista de sensores con éxito!",
				NAME_NOT_VALID: "Nombre de sensor inválido",
				ALREADY_EXIST: "El nombre del sensor ya existe",
				CREATED_SUCCESS: "Sensor creado con éxito",
				CREATED_FAIL: "La creación del sensor falló",
				NOT_FOUND: "El sensor de plantilla no existe",
				UPDATED_FAIL: "Reparación del sensor fallida",
				UPDATED_SUCCESS: "Sensor reparado con éxito",
				DELETED_FAIL: "¡Error al eliminar el sensor!",
				DELETED_SUCCESS: "Sensor eliminado con éxito"
			},
			DEVICEICON: {
				GET_LIST_SUCCES: "Obtenga la lista de iconos con éxito",
				GET_LIST_FAIL: "Error al obtener la lista de iconos",
				NAME_NOT_EMPTY: "El nombre del icono no puede estar en blanco",
				NAME_KEY_NOT_EMPTY: "La tecla de icono no está vacía",
				DELETED_SUCCESS: "Eliminar unidad con éxito",
				ALREADY_EXIST: "El nombre de la clave del dispositivo ya existe.",
				CREATED_FAIL: "La creación del icono del dispositivo falló",
				CREATED_SUCCESS: "Icono de dispositivo creado con éxito",
				UPDATED_FAIL: "Error en la corrección del tipo de icono del dispositivo",
				UPDATED_SUCCESS: "Se reparó correctamente el tipo de ícono del dispositivo",
				NOT_FOUND: "El ícono del dispositivo no existe",
				DELETED_FAIL: "Error al eliminar el icono del dispositivo"
			},
			TRANSPORT_TYPE: {
				CREATED_SUCCESS: "Crear un tipo de transporte exitoso",
				CREATED_FAIL: "Crear un tipo de falla de transporte",
				NAME_KEY_NOT_EMPTY: "La palabra clave no debe estar en blanco",
				LIMIT_SPEED_NOT_VALID: "Límite de tarifa no válido",
				QCVNCODE_NOT_VALID: "QCVN inválido",
				UPDATED_SUCCESS: "Actualización exitosa del tipo de transporte",
				UPDATED_FAIL: "Error al actualizar el tipo de transporte",
				DELETED_SUCCESS: "Eliminación exitosa del tipo de transporte",
				DELETED_FAIL: "Eliminar el tipo de transporte fallido",
				NAME_KEY_ALREADY_EXIST: "La palabra clave ya existe",
				SORT_ORDER_SUCCESS: "Orden de éxito"
			},
			TRANSPORT_TYPE_QCVN: {
				CREATED_SUCCESS: "Crear un tipo de transporte exitoso",
				CREATED_FAIL: "Crear un tipo de falla de transporte",
				NAME_KEY_NOT_EMPTY: "La palabra clave no debe estar en blanco",
				LIMIT_SPEED_NOT_VALID: "Límite de tarifa no válido",
				QCVNCODE_NOT_VALID: "QCVN inválido",
				UPDATED_SUCCESS: "Actualización exitosa del tipo de transporte",
				UPDATED_FAIL: "Error al actualizar el tipo de transporte",
				DELETED_SUCCESS: "Eliminación exitosa del tipo de transporte",
				DELETED_FAIL: "Eliminar el tipo de transporte fallido",
				NAME_KEY_ALREADY_EXIST: "La palabra clave ya existe"
			},
			DEVICEGROUP: {
				CREATED_FAIL: "Error al crear el grupo de dispositivos",
				CREATED_SUCCESS: "Grupo de dispositivos creado correctamente",
				ALREADY_EXIST: "El grupo de dispositivos ya existe",
				NAME_NOT_EMPTY: "El nombre del grupo de dispositivos no puede estar vacío.",
				NOT_FOUND: "El grupo de dispositivos no existe",
				UPDATED_FAIL: "Editar grupo de dispositivos falló",
				UPDATED_SUCCESS: "Reparación exitosa del grupo de dispositivos",
				DELETED_FAIL: "Error al eliminar el grupo de dispositivos",
				DELETED_SUCCESS: "Se eliminó correctamente el grupo de dispositivos"
			},
			COMMAND: {
				UPDATED_SUCCESS: "Estado de actualización exitoso",
				CREATED_SUCCESS: "Envío exitoso enviado"
			},
			ALERT_RULE: {
				CREATED_SUCCESS: "Crear advertencia exitosa",
				NAME_NOT_EMPTY: "El nombre de advertencia es incorrecto.",
				EVENT_TYPE_NOT_EMPTY: "Tipo de alerta incorrecto",
				DEVICE_ID_NOT_EMPTY: "Lista de dispositivos vacios",
				GEOFENCE_IDS_NOT_VALID: "Lista de regiones geográficas no válida",
				SCHEDULE_NOT_VALID: "Programación incorrecta",
				EVENT_TYPE_NOT_FOUND: "No se encontró ningún tipo de alerta",
				DELETED_SUCCESS: "Eliminar advertencia exitosa",
				DELETED_FAIL: "Advertencia clara de falla",
				UPDATED_SUCCESS: "Advertencia de actualización exitosa",
				UPDATED_FAIL: "Advertencia fallida de actualización",
				NOT_FOUND: "Regla de alerta no encontrada",
				GET_LIST_DEVICE_FAIL: "Obtener lista dispositivo falso",
				DEVICE_NOT_VALID: "Dispositivo no valido",
				PUSH_SETTING_NOT_EMPTY: "Ajuste de empuje no vacío",
				PUSH_SETTING_NOT_VALID: "Ajuste de inserción no válido",
				EXTENDED_PARAM_NOT_VALID: "Alert params not valid "
			},
			GEOFENCES: {
				NOT_FOUND: "Geofence no encontrado",
				DELETED_FAIL: "Error al eliminar Geofence",
				DELETED_SUCCESS: "Se eliminó con éxito la geovalla",
				UPDATED_FAIL: "Actualizar geofence falló",
				TYPE_NOT_FOUND: "Tipo de geofence desconocido",
				NOT_CHILD: "La cuenta especificada no es válida.",
				UPDATED_SUCCESS: "Actualización de Geofence exitosa",
				CREATED_SUCCESS: "Geofence crear exitoso",
				USER_NOT_VALID: "La cuenta especificada no es válida.",
				NAME_NOT_EMPTY: "El nombre no debe estar en blanco.",
				CREATED_FAIL: "Creación de geofence fallida",
				CHANGE_STATUS_SUCCESS: "Cambiar estado geofence exitoso"
			},
			LOGIN_PAGE: {
				UPDATED_SUCCESS: "Actualización de la página de inicio de sesión exitosa",
				CREATED_SUCCESS: "Crear página de inicio de sesión exitosa"
			},
			SCHEDULED_REPORT: {
				CREATED_SUCCESS: "Crear informe de programación con éxito",
				CREATED_FAIL: "Crear informe de programación fallido",
				DELETED_SUCCESS: "Eliminar informe de programación con éxito",
				DELETED_FAIL: "Eliminar informe de programación fallido",
				UPDATED_SUCCESS: "Actualizar el informe de programación con éxito",
				UPDATED_FAIL: "El informe de programación de actualización falló",
				TYPE_REPORT_ALREADY_EXIST: "El tipo de informe ya existe",
				DEVICE_NOT_VALID: "Dispositivo no valido"
			},
			DEVICE_FAVORITE: {
				DELETED_FAIL: "Eliminar favorito falló",
				DELETED_SUCCESS: "Eliminado el favorito",
				NOT_CHILD: "La cuenta especificada no es válida.",
				CREATED_SUCCESS: "Agregó un favorito",
				USER_NOT_VALID: "La cuenta especificada no es válida.",
				CREATED_FAIL: "Favorito agregado fallido"
			},
			ROLE_ALIAS: {
				UPDATED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS: "Las cuentas de alias no tienen derecho a actualizar las cuentas de alias",
				DELETED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS: "Las cuentas de alias no tienen derecho a eliminar cuentas de alias",
				CREATED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS: "Las cuentas de alias no tienen derecho a crear cuentas de alias",
				CREATED_SUCCESS: "Cree un alias de rol con éxito",
				CREATED_FAIL: "Crear alias de rol fallido",
				DELETED_SUCCESS: "Eliminar alias de rol correctamente",
				DELETED_FAIL: "Eliminar alias de rol fallido",
				UPDATED_SUCCESS: "Actualice el alias de rol correctamente",
				UPDATED_FAIL: "Actualizar alias de rol fallido",
				NOT_UPDATED_ROLE_WHEN_YOU_ARE_A_ALIAS: "No puede actualizar roles cuando es un alias de usuario",
				NOT_UPDATED_ROLE_FOR_USED_NOT_CHILD: "No puede actualizar roles cuando es un alias de usuario",
				PERMISSION_DO_NOT_ALLOW_EXTEND: "El permiso seleccionado no es válido.",
				USED: "El rol se está utilizando"
			},
			POINT: {
				USER_EXTENSION_NOT_CHILD: "Cuenta inválida para renovación",
				NO_DEVICE_EXTENSION: "Ningún dispositivo fue renovado",
				EQUIPMENT_NOT_VALID: "La lista de dispositivos no es válida.",
				USER_NOT_FOUND: "Cuenta inválida para renovación",
				EXTENSION_FAIL: "Renovación fallida",
				MONTH_NOT_VALID: "Mes de renovación inválido",
				USER_NOT_ENOUGH_POINT: "La cuenta no tiene suficientes puntos para renovar",
				EXTENSION_SUCCESS: "Renovación exitosa",
				PAYAT_NOT_EMPTY: "Pague en no vacío",
				ADD_POINT_SUCCESS: "Añadir puntos de éxito",
				FORBIDDEN: "La función requiere permiso",
				POINT_NOT_EMPTY: "Punto no vacío",
				POINT_NOT_VALID: "Punto inválido",
				ADD_POINT_FAIL: "Añadir punto fallido",
				SELL_POINT_SUCCESS: "Punto de venta exitoso",
				NOT_CHILD: "Cuenta no válida",
				POINT_NOT_ENOUGH_POINT: "No hay suficientes puntos",
				SELL_POINT_FAIL: "Punto de venta fallido",
				MONTH_DEVICE_EXTENSION_NOT_VALID: "Mes de renovación inválido",
				USER_REVICE_NOT_CHILD: "Cuenta inválida para renovación",
				USER_REVICE_NOT_CHILD_EXTENSION: "Cuenta inválida para renovación"
			},
			TRANSACTION: {
				FORBIDDEN: "La función requiere permiso",
				CANCEL_FAIL: "Cancelar transacción fallida",
				CANCEL_SUCCESS: "Transacción cancelada con éxito",
				CAN_NOT_CANCEL: "Tipo o estado de transacción no válido",
				UPDATED_SUCCESS: "La transacción de actualización se completó con éxito",
				PAYAT_NOT_VALID: "La fecha de pago no es válida.",
				UPLOAD_BILL_IMAGE_FAIL: "Factura inválida",
				UPDATED_FAIL: "La transacción de actualización falló",
				CAN_NOT_UPDATED_CANCEL: "Actualización de transacción fallida",
				REQUEST_CANCEL_SUCCESS: "Solicitar cancelar la transacción con éxito",
				CAN_NOT_REQUEST_CANCEL: "Solicitud para cancelar la transacción fallida"
			},
			CARD: {
				CREATED_SUCCESS: "Crea un nuevo código con éxito",
				CREATED_FAIL: "Crear nuevo código fallido"
			},
			SYSTEM_LOG: {
				CREATED_SUCCESS: "Crear versión con éxito",
				CREATED_FAIL: "Crear versión fallida",
				UPDATED_SUCCESS: "Actualice la versión con éxito",
				UPDATED_FAIL: "La versión de actualización falló"
			},
			MESSAGE: {
				SEND_SUCCESS: "Enviar mensaje con éxito",
				SEND_FAIL: "Enviar mensaje fallido",
				DELETED_SUCCESS: "Eliminar mensaje con éxito",
				DELETED_FAIL: "Eliminar mensaje fallido",
				MARK_READ_ALL_SUCCESS: "Mark read all successfully\t"
			},
			NOTICE: {
				CREATED_SUCCESS: "Crear aviso con éxito",
				CREATED_FAIL: "Crear aviso fallido",
				DELETED_SUCCESS: "Eliminar aviso con éxito",
				DELETED_FAIL: "Eliminar aviso fallido",
				UPDATED_SUCCESS: "Notificación de actualización exitosa",
				UPDATED_FAIL: "Aviso de actualización fallido"
			},
			POI_TYPE: {
				CREATED_SUCCESS: "Crear tipo de PDI con éxito",
				CREATED_FAIL: "Crear tipo de PDI fallido",
				DELETED_SUCCESS: "Eliminar el tipo de PDI correctamente",
				DELETED_FAIL: "Eliminar el tipo de PDI fallido",
				UPDATED_SUCCESS: "Actualizar el tipo de PDI correctamente",
				UPDATED_FAIL: "Error al actualizar el tipo de PDI",
				ALREADY_EXIST: "El tipo de PDI ya existe"
			},
			POI: {
				CREATED_SUCCESS: "Crear puntos de referencia con éxito",
				CREATED_FAIL: "Crear puntos de referencia fallidos",
				DELETED_SUCCESS: "Eliminar puntos de referencia correctamente",
				DELETED_FAIL: "Eliminar puntos de referencia fallidos",
				UPDATED_SUCCESS: "Actualizar puntos de referencia con éxito",
				UPDATED_FAIL: "Actualizar puntos de referencia fallidos",
				NAME_ALREADY_EXIST: "Los puntos de referencia ya existen",
				POI_TYPE_NOT_EMPTY: "Por favor, seleccione Tipo de hito"
			},
			GEOFENCE_GROUPS: {
				CREATED_SUCCESS: "Crear un grupo de geofence con éxito",
				CREATED_FAIL: "Crear grupo de geofence fallido",
				DELETED_SUCCESS: "Eliminar el grupo geofence con éxito",
				DELETED_FAIL: "Eliminar el grupo de geofence falló",
				UPDATED_SUCCESS: "Actualizar el grupo de geofence con éxito",
				UPDATED_FAIL: "Error al actualizar el grupo de geofence",
				ALREADY_EXIST: "El grupo de geofence ya existe"
			},
			TOLL_STATION: {
				CREATED_SUCCESS: "Crear estación de peaje con éxito",
				CREATED_FAIL: "Crear estación de peaje fallida",
				DELETED_SUCCESS: "Eliminar la estación de peaje con éxito",
				DELETED_FAIL: "Eliminar la estación de peaje falló",
				UPDATED_SUCCESS: "Actualizar la estación de peaje con éxito",
				UPDATED_FAIL: "La estación de peaje de actualización falló",
				ALREADY_EXIST: "La estación de peaje ya existe",
				TOLL_ROAD_NOT_VALID: "Seleccione la carretera de peaje",
				TYPE_TOLL_STATION_TYPE_TOLL_ROAD_IS_HIGHWAY: "Tipo de peaje y carretera de peaje diferentes"
			},
			USER_MAP_KEY: {
				CREATED_SUCCESS: "Crear clave de mapa correctamente",
				CREATED_FAIL: "Error al crear la clave del mapa",
				DELETED_SUCCESS: "Eliminar clave de mapa correctamente",
				DELETED_FAIL: "Error al eliminar la clave del mapa",
				UPDATED_SUCCESS: "Actualizar la clave del mapa correctamente",
				UPDATED_FAIL: "La estación de peaje de actualización falló",
				KEY_ALREADY_EXIST: " Map key already exist",
				TYPE_NOT_EMPTY: "El tipo de clave no debe estar en blanco",
				KEY_NOT_VALID: "Clave de API no válida",
				CHANGE_STATUS_SUCCESS: "El cambio de estado de la clave de la API del mapa se ha realizado correctamente",
				USER_NOT_EMPTY: "No se selecciona ningún usuario nuevo",
				KEY_NOT_EMPTY: "La llave no debe estar en blanco"
			},
			CAMERA: {
				CREATED_SUCCESS: "Crear cámara exitosamente",
				CREATED_FAIL: "Crear cámara fallida",
				DELETED_SUCCESS: "Eliminar la cámara con éxito",
				DELETED_FAIL: "Eliminar la cámara falló",
				UPDATED_SUCCESS: "Actualiza la cámara con éxito",
				UPDATED_FAIL: "Falló la actualización de la cámara",
				ALREADY_EXIST: "La cámara ya existe",
				CAMERA_ID_NOT_VALID: "Identificación de la cámara no válida",
				NAME_ALREADY_EXIST: "El nombre de la cámara ya existe",
				CAMERA_ID_ALREADY_EXIST: "La identificación de la cámara ya existe"
			},
			TOLL_ROAD: {
				CREATED_SUCCESS: "Crear una carretera de peaje con éxito",
				CREATED_FAIL: "Error al crear la carretera de peaje",
				DELETED_SUCCESS: "Eliminar la carretera de peaje con éxito",
				DELETED_FAIL: "Error al eliminar la carretera de peaje",
				UPDATED_SUCCESS: "Actualizar la carretera de peaje con éxito",
				UPDATED_FAIL: "Error al actualizar la carretera de peaje",
				ALREADY_EXIST: "La carretera de peaje ya existe",
				CAN_NOT_UPDATED_TYPE_TOLL_ROAD_ALREADY_EXIST_TOLL_SEGMENT: "La carretera se ha asignado a una carretera de peaje"
			},
			TOLL_SEGMENT: {
				CREATED_SUCCESS: "Crear segmento de peaje con éxito",
				CREATED_FAIL: "Error al crear segmento de peaje",
				DELETED_SUCCESS: "Eliminar el tramo de peaje con éxito",
				DELETED_FAIL: "Error al eliminar el tramo de peaje",
				UPDATED_SUCCESS: "Actualizar el segmento de peaje con éxito",
				UPDATED_FAIL: "Error al actualizar el segmento de peaje",
				ALREADY_EXIST: "El segmento de peaje ya existe",
				TOLL_ROAD_STATION_EXIT_STATION_ALREADY_EXIST: "El inicio y el final de la estación de peaje ya existen",
				ENTRY_STATION_EXIT_STATION_ALREADY_EXIST: "La estación de entrada y la estación de salida ya existen"
			},
			STORAGE: {
				DELETED_SUCCESS: "Eliminar éxito"
			}
		},
		DATA: {
			SENSOR: {
				ERROR: 'Valor indefinido'
			}
		}
	}
};
