import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {TranslationService} from "../../../@core/services/translation.service";
import {NavigationStart, Router} from "@angular/router";
import {filter} from "rxjs/operators";
interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}
@Component({
  selector: 'ngx-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  // Public properties
  @HostBinding('class') classes = '';
  @Input() iconType: '' | 'brand';

  language: LanguageFlag;
  languages: LanguageFlag[] = [
    {
      lang: 'vn',
      name: 'Việt Nam',
      flag: './assets/media/flags/001-vietnam.svg'
    },
    {
      lang: 'en',
      name: 'English',
      flag: './assets/media/flags/012-uk.svg'
    },
    // {
    // 	lang: 'ch',
    // 	name: 'Mandarin',
    // 	flag: './assets/media/flags/015-china.svg'
    // },
    // {
    //   lang: 'es',
    //   name: 'Spanish',
    //   flag: './assets/media/flags/016-spain.svg'
    // },
    // {
    // 	lang: 'jp',
    // 	name: 'Japanese',
    // 	flag: './assets/media/flags/014-japan.svg'
    // },
    // {
    // 	lang: 'de',
    // 	name: 'German',
    // 	flag: './assets/media/flags/017-germany.svg'
    // },
    // {
    // 	lang: 'fr',
    // 	name: 'French',
    // 	flag: './assets/media/flags/019-france.svg'
    // },
  ];
  constructor(
    private translationService: TranslationService,
    private router: Router) {
  }
  ngOnInit() {
    this.setSelectedLanguage();
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(event => {
        this.setSelectedLanguage();
      });
  }
  setLanguage(lang) {
    this.languages.forEach((language: LanguageFlag) => {
      if (language.lang === lang) {
        language.active = true;
        this.language = language;
      } else {
        language.active = false;
      }
    });
    this.translationService.setDefaultLang(lang);
    this.translationService.setLanguage(lang);
  }
  setSelectedLanguage(): any {
    this.setLanguage(this.translationService.getSelectedLanguage());
  }
}
