// Angular
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { Logout } from '../../../@core/auth';
import { AppState } from '../../../@core/reducers';
// Auth

@Component({
	selector: 'kt-login',
	template: ''
})
export class LogoutComponent implements OnInit {

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor	 
	 * @param store: Store<AppState>
	 */
	constructor(
		private store: Store<AppState>,
		private router: Router,
	) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.store.dispatch(new Logout());
		this.router.navigate(['/auth/login'],
			{
				queryParams: {
					returnUrl: sessionStorage.getItem("lastUrl")
				}
			});
	}
}
