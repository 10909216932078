import {Component, Input} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AbstractControl, FormControl} from "@angular/forms";

@Component({
  selector: 'ngx-inline-message',
  templateUrl: './inline-message.component.html',
  styleUrls: ['./inline-message.component.scss'],
})
export class InlineMessageComponent {
  @Input() formName: AbstractControl<any>;
  @Input() message: any;
  @Input() customMessage: any;

  constructor(private translate: TranslateService) {
  }
  getMessage(error: any) {
    if (error) {
      const key = Object.keys(error);
      if (key) {
        if (this.customMessage && this.customMessage[key[0]]) {
          return this.customMessage[key[0]];
        } else return this.translate.instant('validation.' + key[0], {
          field: this.translate.instant(this.message),
          value: error[key[0]] ? error[key[0]].requiredLength : '',
        });
      }
    }
  }
}
