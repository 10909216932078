import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators';
import { TypesUtilsService } from '../../@core/models';
import { environment } from '../../../environments/environment';
import { BaseOptionAPI } from '../../@core/models/query-models/_base.option-api';
import { Response } from '../../@core/models/query-models/_new-response-body.model';

const API_LOGIN_TYPE = environment.api.host + "/admin/login-pages";
const KEY_LOGIN_PAGE: string = "KEY_LOGIN_PAGE";
const KEY_CHECK_LOGIN_PAGE: string = "KEY_CHECK_LOGIN_PAGE";

@Injectable({
  providedIn: 'root'
})
export class LoginPageService {

  constructor(private http: HttpClient, private utils: TypesUtilsService) { }
  search(option: any | undefined, params ?: any): Observable<Response> {
    return this.http.post<Response>(API_LOGIN_TYPE + '/search', option, { params: params });
  }

  create(params: any, option?: BaseOptionAPI): Observable<Response> {
    return this.http.post<Response>(API_LOGIN_TYPE, params, {
      params: this.utils.processBassOptionApi(option),
    });
  }

  update(id:number, params: any, option?: BaseOptionAPI): Observable<Response> {
    return this.http.put<Response>(API_LOGIN_TYPE + "/" + id, params, {
      params: this.utils.processBassOptionApi(option),
    });
  }

  delete(id: number, option?: BaseOptionAPI): Observable<Response> {
    const url = `${API_LOGIN_TYPE}/${id}`;
    return this.http.delete<Response>(url, {
      params: this.utils.processBassOptionApi(option),
    });
  }

  host(name: string): Observable<Response> {
    let reqHeader = new HttpHeaders()
      .set('Auth', 'False')
    return this.http.get<Response>(API_LOGIN_TYPE + '/host', 
      {
        params:{name:name},
        headers:reqHeader
      }).pipe(
        tap(result=>{
          localStorage.setItem(KEY_LOGIN_PAGE,JSON.stringify(result.data));
        })
      );
  }
  getCacheHost(){
    let host = localStorage.getItem(KEY_LOGIN_PAGE);
    if(host) return JSON.parse(host);
    else return {};
  }

  getDetail(id: number, option?: BaseOptionAPI): Observable<Response> {
    return this.http.get<Response>(`${API_LOGIN_TYPE}/${id}` ,{
      params: this.utils.processBassOptionApi(option),
    })
  }
}
