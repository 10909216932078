import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'MENU.ADMIN',
    icon: { icon: 'user', pack: 'fas'},
    data: {role: 'ROLE_menu.user_tree', user_tree: true},
  },
  {
    title: 'MENU.DASHBOARD',
    icon: { icon: 'home', pack: 'fas'},
    link: '/dashboard',
    home: true,
    data: {pageMain: true, role: 'ROLE_menu.dashboard'}
  },
  // {
  //   title: 'MENU.MAP',
  //   icon: { icon: 'map', pack: 'fas'},
  //   link: '/map',
  //   data: {pageMain: true, role: 'ROLE_menu.map'}
  // },
  {
    title: 'MENU.MANAGE',
    icon: { icon: 'cog', pack: 'fa'},
    data: {role: 'ROLE_menu.manage'},
    expanded: true,
    children: [
      {
        title: 'MENU.TERMINAL',
        link: '/manage/terminal',
        data: {role: 'ROLE_menu.terminal'},
      },
      {
        title: 'MENU.MANAGER_USER',
        link: '/manage/user',
        data: {role: 'ROLE_menu.user'},
      },
      {
        title: 'MENU.MANAGER_DEVICE',
        // children: [{
        //   title: 'MENU.MAP',
        //   link: '/manage/charge-station/map',
        // },{
        //   title: 'MENU.INFO',
        //   link: '/manage/charge-station/info',
        // }],
        data: {role: 'ROLE_menu.device'},
        link: '/manage/device/info',
      },
      {
        title: 'MENU.BANK',
        data: {role: 'ROLE_menu.bank'},
        link: '/manage/bank',
      },
      {
        title: 'MENU.VOUCHER',
        data: {role: 'ROLE_menu.voucher'},
        link: '/manage/voucher',
      },
    ],
  },
  {
    title: 'MENU.REPORT',
    icon: { icon: 'chart-line', pack: 'fas'},
    data: {role: 'ROLE_menu.report'},
    expanded: true,
    children: [
      {
        title: 'MENU.TRANSACTION_HISTORY',
        link: '/report/transaction-history',
        data: {role: 'ROLE_menu.transaction_history'},
      },
      {
        title: 'MENU.CHANGE_BALANCE',
        link: '/report/change-balance',
        data: {role: 'ROLE_menu.change_balance'},
      },
      {
        title: 'MENU.DEVICE_STATUS',
        link: '/report/device-status',
        data: {role: 'ROLE_menu.device_status'},
      },
    ],
  },
  {
    title: 'MENU.SYSTEM_CONFIGURATION',
    icon: { icon: 'database', pack: 'fas'},
    data: {role: 'ROLE_menu.system_configuration'},
    children: [
      {
        title: 'MENU.ROLE',
        link: '/manage/role',
        data: {role: 'ROLE_menu.role'},
      },
      {
        title: 'MENU.PERMISSION',
        link: '/manage/permission',
        data: {role: 'ROLE_menu.permission'},
      },
      {
        title: 'MENU.LOGIN_PAGE',
        link: '/manage/login-page',
        data: {role: 'ROLE_menu.login_page'},
      },
      {
        title: 'MENU.SIM_TYPE',
        link: '/manage/sim-type',
        data: {role: 'ROLE_menu.sim_type'},
      },
      {
        title: 'MENU.WALLET',
        link: '/manage/wallet',
        data: {role: 'ROLE_menu.wallet'},
      },
      {
        title: 'MENU.SERVICE_PRICE',
        link: '/manage/service-price',
        data: {role: 'ROLE_menu.service_price'},
      },
      {
        title: 'MENU.SYSTEM',
        link: '/manage/system',
        data: {role: 'ROLE_menu.system'},
      },
    ],
  },
  {
    title: 'MENU.DEVICE_CONFIGURATION',
    icon: { icon: 'tools', pack: 'fas'},
    data: {role: 'ROLE_menu.device_configuration'},
    children: [
      {
        title: 'MENU.DEVICE_TYPE',
        link: '/manage/device-type',
        data: {role: 'ROLE_menu.device_type'},
      },
    ],
  },
];
