<nb-card>
  <nb-card-header>
    <div class="d-flex justify-content-between">
      <h5>{{ title |translate}}</h5>
      <button (click)="close()" nbButton ghost status="basic"><nb-icon icon="close-outline"></nb-icon></button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div>
       <h6 style="font-weight: 400">
         <nb-icon [icon]="'question-mark-circle-outline'"></nb-icon>
         {{message|translate}}</h6>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="text-right">
      <button *ngIf="allowDelete" nbButton type="button" (click)="save()" class="mr-2">{{ okTitle |translate}}</button>
      <button *ngIf="!hideCancel" nbButton type="button" [status]="allowDelete ? 'warning': 'primary'" (click)="close()"
              id="dialogCancelBtn">{{ (!allowDelete ? okTitle : cancelTitle) |translate}}</button>
    </div>
  </nb-card-footer>
</nb-card>
