<nb-card class="modal-card">
  <nb-card-header>
    <div class="d-flex justify-content-between">
      <h5>
        {{ title|translate }}
      </h5>
      <button (click)="dialogRef.close()" class="close" nbButton ghost status="basic"><nb-icon icon="close-outline"></nb-icon></button>
    </div>
  </nb-card-header>
  <nb-card-body >
    <input type="file" [hidden]="true"  #input accept="image/*" (change)="onImageUpload($event)" [(ngModel)]="fileImage">
<!--    {{fileImage}}-->
    <div class="row photos col-12" style="margin: unset;padding: unset">
      <div  class="photo" (click)="addImage(input)" *ngIf="enableAddImage">
        <div class="image">
          <div class="centerAll">
            <span>{{'MAP.LABEL.addPhoto' | translate}}</span>
            <nb-icon [icon]="'plus-circle-outline'" style="transform: scale(1.4)" class="mt-2" [status]="'basic'"></nb-icon>
          </div>
        </div>
      </div>
      <div class="photo" *ngFor="let photo of chargePhotoInfos;let k = index;">
        <div class="image" [style.background-image]="'url(' + photo?.url + ')'" [lightbox]="k" (click)="showImage(chargePhotoInfos)">
        </div>
        <button  class="delete-icon" (click)="delete(photo, k)" nbButton ghost status="basic"><nb-icon icon="close-outline" status="basic"></nb-icon></button>
      </div>
    </div>
  </nb-card-body>
</nb-card>
