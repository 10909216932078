<div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1" id="kt_login">
    <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
        <!--begin::Aside-->
        <div class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
            [ngStyle]="backgroundImageStyle">
            <!-- <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div class="kt-grid__item kt-grid__item--middle">
                    <h3 class="kt-login__title">Join Our Community</h3>
                    <h4 class="kt-login__subtitle">Lorem ipsum dolor sit amet, coectetuer adipiscing
                        elit sed diam nonummy et nibh euismod</h4>
                </div>
            </div>
            <div class="kt-grid__item">
                <div class="kt-login__info">
                    <div class="kt-login__copyright">
                        © ---
                    </div>
                    <div class="kt-login__menu">
                        <a href="#" class="kt-link">Privacy</a>
                        <a href="#" class="kt-link">Legal</a>
                        <a href="#" class="kt-link">Contact</a>
                    </div>
                </div>
            </div> -->
        </div>
        <!--begin::Aside-->

        <!--begin::Content-->
        <div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
            <!--begin::Head-->
            <!--begin::Head-->
            <div class="kt-login__head">
                <div class=" select-default-language kt-align-right">

                    <div ngbDropdown placement="bottom-right"
                        class="kt-header__topbar-item kt-header__topbar-item--langs">
                        <div ngbDropdownToggle class="kt-header__topbar-wrapper">
                            <span class="kt-header__topbar-icon" style="cursor: pointer;">
                                <img class="" src="{{language?.flag}}" alt="" /> <span>{{language.name}}</span>
                            </span>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                            class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
                            <ul class="kt-nav kt-margin-t-10 kt-margin-b-10">
                                <ng-container *ngFor="let language of languages">
                                    <li class="kt-nav__item" [ngClass]="{'kt-nav__item--active': language.active}">
                                        <a ngbDropdownToggle href="javascript:;" (click)="setLanguage(language.lang)"
                                            [ngClass]="{'kt-nav__link--active': language.active}" class="kt-nav__link">
                                            <span class="kt-nav__link-icon">
                                                <img src="{{language.flag}}">
                                            </span>

                                            <span class="kt-nav__link-text">{{language.name}}</span>
                                        </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            <!--end::Head-->
            <!--end::Head-->

            <!--begin::Body-->
            <div class="kt-login__body">

                <!--begin::Signin-->
                <div class="kt-login__form">
                    <div class="kt-login__logo" style="text-align: center;">
                        <a href="javascript:;" class="">
                            <img [src]="logo" alt="" style="max-width: 100px;max-height: 150px;">
                        </a>
                    </div>
                    <div class="kt-login__title">
                        <h3>{{ 'AUTH.GENERAL.SIGNIN' | translate }}</h3>
                    </div>

                    <kt-auth-notice></kt-auth-notice>
                    <!--begin::Form-->
                    <form [formGroup]="loginForm" novalidate="novalidate">
                        <div class="form-group validate is-invalid">
                            <label>{{ 'AUTH.INPUT.USERNAME' | translate }}</label>
                            <input type="text" formControlName="email" name="email" autocomplete="off"
                                class="form-control" />
                            <div class="error invalid-feedback" *ngIf="isControlHasError('email','required')">
                                <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate}}</strong>
                            </div>
                            <!-- <mat-error *ngIf="isControlHasError('email','email')">
                                    <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
                                </mat-error> -->
                            <div class="error invalid-feedback" *ngIf="isControlHasError('email','minLength')">
                                <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                            </div>
                            <div class="error invalid-feedback" *ngIf="isControlHasError('email','maxLength')">
                                <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
                            </div>
                        </div>
                        <div class="form-group validate is-invalid">
                            <label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</label>
                            <input type="password" formControlName="firstName" class="form-control" name="password"
                                formControlName="password" autocomplete="off" />
                            <div class="error invalid-feedback" *ngIf="isControlHasError('password','required')">
                                <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                            </div>
                            <div class="error invalid-feedback" *ngIf="isControlHasError('password','minLength')">
                                <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                            </div>
                            <div class="error invalid-feedback" *ngIf="isControlHasError('password','maxLength')">
                                <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                            </div>
                        </div>
                        <!--begin::Action-->
                        <div class="kt-login__actions">
                            <a href="javascript:;" routerLink="/auth/forgot-password"
                                class="kt-link kt-login__link-forgot">
                                {{ 'AUTH.GENERAL.FORGOT_BUTTON' | translate }}
                            </a>

                            <button (click)="submit()"
                                [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}"
                                id="kt_login_signin_submit"
                                class="btn btn-primary btn-elevate kt-login__btn-primary">{{ 'AUTH.LOGIN.BUTTON' | translate }}</button>
                        </div>
                        <!--end::Action-->
                    </form>
                    <!--end::Form-->

                    <div class="kt-login__divider">
                        <div class="kt-divider">
                            <span></span>
                            <span style="color: #67666e;">{{'AUTH.DOWNLOAD.TITLE' | translate}}</span>
                            <span></span>
                        </div>
                    </div>

                    <div class="kt-login__options">
                        <a target=”_blank” href="{{iosUrl}}" class="btn btn-primary kt-btn" style="margin-right: 15px;">
                            <i style="font-size: 1.1rem;" class="icon-app-store-ios"></i>
                            App Store
                        </a>
                        
                        <a target=”_blank” href="{{androidUrl}}" class="btn btn-primary kt-btn">
                            <i style="font-size: 1.1rem;" class="socicon-android"></i>
                            Google Play
                        </a>

                        <!-- <a href="#" class="btn btn-primary kt-btn">
                            <i class="fab fa-google"></i>
                            Google
                        </a> -->
                    </div>
                </div>
                <!--end::Signin-->
            </div>
            <!--end::Body-->
        </div>
        <!--end::Content-->
    </div>
</div>