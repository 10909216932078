import { Injectable } from '@angular/core';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toastConfig = {
    autoDismiss: false,
    closeButton: false,
    countDuplicates: false,
    disableTimeOut: false,
    easeTime: 300,
    easing: "ease-in",
    enableHtml: false,
    extendedTimeOut: 1000,
    maxOpened: 0,
    messageClass: "toast-message",
    newestOnTop: true,
    onActivateTick: false,
    positionClass: "toast-bottom-right",
    preventDuplicates: false,
    progressAnimation: "decreasing",
    progressBar: false,
    resetTimeoutOnDuplicate: false,
    tapToDismiss: true,
    timeOut: 3000,
    titleClass: "toast-title",
    toastClass: "ngx-toastr"
  }
  toasts: any[] = [];
  constructor(
    private toastr: ToastrService,
    private translate: TranslateService) {
    this.toastr.toastrConfig = Object.assign({},this.toastr.toastrConfig, this.toastConfig);

  }
  /**
    * Set toart config 
    * @param toastConfig 
    */
  setToartConfig(toastConfig) {
    if (toastConfig) {
      this.toastr.toastrConfig = Object.assign({},this.toastr.toastrConfig, toastConfig);
    }
    else {
      this.toastr.toastrConfig = Object.assign({},this.toastr.toastrConfig, this.toastConfig);
    }
  }
  show(options?:{ message?: string, translate?: string, type?: 'info'| 'success'|'error'|'warning'| string, config?: GlobalConfig}) {
    this.toastr.toastrConfig = Object.assign(this.toastr.toastrConfig, this.toastConfig);
   
    if (options) {
      if (options.config) this.toastr.toastrConfig = Object.assign({},this.toastr.toastrConfig, this.toastConfig, options.config);
      if (!options.type) options.type = "info";
      if (!options.message) options.message = "";
      if (options.translate) options.message = this.translate.instant(options.translate);
      if (options.message.length > 0) {
        switch (options.type) {
          case "info":
            this.toastr.info(options.message);
            break;
          case "success":
            this.toastr.success(options.message);
            break;
          case "error":
            this.toastr.error(options.message);
            break;
          case "warning":
            this.toastr.warning(options.message);
            break;
          default:
            this.toastr.show(options.message);
            break;
        }
      }

    }
  }

  showMessage(options?:{ result: any, config?: GlobalConfig}) {
    this.toastr.toastrConfig = Object.assign(this.toastr.toastrConfig, this.toastConfig);
   
    if (options) {
      if (options.config) this.toastr.toastrConfig = Object.assign({},this.toastr.toastrConfig, this.toastConfig, options.config);
        if(options.result.success){
          this.toastr.success(options.result.message);
        }else{
          this.toastr.error(options.result.message);
        }
      }
    }

  copied(val?) {
    this.toastr.toastrConfig = Object.assign({},this.toastr.toastrConfig, this.toastConfig, { timeOut: 2000,toastClass: "ngx-toastr kt-bg-info" });
    if (val) {
      if (val.isSuccess) {
        this.toastr.info(this.translate.instant('COMMON.MESSAGE.COPIED'));
      }
      else if (!val.isSuccess) {
        this.toastr.info(this.translate.instant('COMMON.MESSAGE.COPY_FALSE'));
      }
    }
    else {
      this.toastr.warning(this.translate.instant('COMMON.MESSAGE.COPIED'));
    }
  }

}
