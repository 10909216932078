// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * https://github.com/AndrewPoyntz/time-ago-pipe
 * An Angular pipe for converting a date string into a time ago
 */
@Pipe({ name: 'date1' })

export class TimeFormat implements PipeTransform {
	// Private properties
	public dateFormat : string = 'dd/MM/yyyy';
	public timeFormat : string = 'HH:mm';
	public timezone : string  = 'UTC+7'; 
	/**
	 * Pipe Constructor
	 */


	/**
	 * Transform
	 *
	 * @param value: string
	 */
	transform(items: any[], searchText: string): any[] {
		if(!items) return [];
		if(!searchText) return items;
		
		searchText = searchText.toLowerCase();
			return items.filter( it => {
			  return it.name.toLowerCase().includes(searchText);
			});
	   } 
	

}
