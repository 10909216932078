// Angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';

/**
 * List status code for api response.
 */
export enum StatusCode {
    // UNAUTHORIZED = 401,
    // FORBIDDEN = 403,
    // NOT_FOUND = 404,
    // SUCCESS = 200,
    // CREATED = 201,
    CONTINUE = 100,
    SWITCHING_PROTOCOLS = 101,

    OK = 200,
    CREATED = 201,
    ACCEPTED = 202,
    NON_AUTHORITATIVE_INFORMATION = 203,
    NO_CONTENT = 204,
    RESET_CONTENT = 205,
    PARTIAL_CONTENT = 206,

    MULTIPLE_CHOICES = 300,
    MOVED_PERMANENTLY = 301,
    FOUND = 302,
    SEE_OTHER = 303,
    NOT_MODIFIED = 304,
    USE_PROXY = 305,
    TEMPORARY_REDIRECT = 307,

    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    PAYMENT_REQUIRED = 402,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    METHOD_NOT_ALLOWED = 405,
    NOT_ACCEPTABLE = 406,
    PROXY_AUTHENTICATION_REQUIRED = 407,
    REQUEST_TIME_OUT = 408,
    CONFLICT = 409,
    GONE = 410,
    LENGTH_REQUIRED = 411,
    PRECONDITION_FAILED = 412,
    REQUEST_ENTITY_TOO_LARGE = 413,
    REQUEST_URI_TOO_LARGE = 414,
    UNSUPPORTED_MEDIA_TYPE = 415,
    REQUESTED_RANGE_NOT_SATISFIABLE = 416,
    EXPECTATION_FAILED = 417,

    INTERNAL_SERVER_ERROR = 500,
    NOT_IMPLEMENTED = 501,
    BAD_GATEWAY = 502,
    SERVICE_UNAVAILABLE = 503,
    GATEWAY_TIME_OUT = 504,
    HTTP_VERSION_NOT_SUPPORTED = 505,
}
const Swal2 = Swal.mixin({
    toast: true,
    position: 'top-right',
    showConfirmButton: false,
    timer: 3000,
    // showCloseButton:true
    // timerProgressBar: true,
    // onOpen: (toast) => {
    //   toast.addEventListener('mouseenter', Swal.stopTimer)
    //   toast.addEventListener('mouseleave', Swal.resumeTimer)
    // }
  })
@Injectable()
export class ResponseUtilsService {
    private toastConfig = {
        autoDismiss: false,
        closeButton: false,
        countDuplicates: false,
        disableTimeOut: false,
        easeTime: 300,
        easing: "ease-in",
        enableHtml: false,
        extendedTimeOut: 1000,
        maxOpened: 0,
        messageClass: "toast-message",
        newestOnTop: true,
        onActivateTick: false,
        positionClass: "toast-bottom-right",
        preventDuplicates: false,
        progressAnimation: "decreasing",
        progressBar: false,
        resetTimeoutOnDuplicate: false,
        tapToDismiss: true,
        timeOut: 5000,
        titleClass: "toast-title",
        toastClass: "ngx-toastr"
    }
    // private swalConfig = {
    //     toast: true,
    //     position: 'bottom',
    //     showConfirmButton: false,
    //     timer: 3000,
    //     // showCloseButton:true
    //     // timerProgressBar: true,
    //     // onOpen: (toast) => {
    //     //   toast.addEventListener('mouseenter', Swal.stopTimer)
    //     //   toast.addEventListener('mouseleave', Swal.resumeTimer)
    //     // }
    // }
    // private Swal2;
  
    constructor(private router: Router,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {
        this.toastr.toastrConfig = Object.assign({}, this.toastr.toastrConfig, this.toastConfig);
        // this.Swal2 = Swal.mixin({
        //     toast: true,
        //     position: 'bottom',
        //     showConfirmButton: false,
        //     timer: 3000,
        //     // showCloseButton:true
        //     // timerProgressBar: true,
        //     // onOpen: (toast) => {
        //     //   toast.addEventListener('mouseenter', Swal.stopTimer)
        //     //   toast.addEventListener('mouseleave', Swal.resumeTimer)
        //     // }
        //   })
    }
    /**
     * Set toart config 
     * @param toastConfig 
     */
    setToartConfig(toastConfig) {
        if (toastConfig != undefined && Object.keys(toastConfig).length > 0) {
            this.toastr.toastrConfig = Object.assign({}, this.toastr.toastrConfig, toastConfig);
        }
        else {
            this.toastr.toastrConfig = Object.assign({}, this.toastr.toastrConfig, this.toastConfig);
        }
    }
    /**
     * Process `event` response
     * @param event Event response
     * @param notifyGlobal Show notify global
     * 
     * ### Example
     * ```
     * responseUtilsService.processSuccess(event);
     * ```
     */
    private messageError = new BehaviorSubject<any>({});
    messageErrorObservable$ = this.messageError.asObservable();
    processSuccess(event, notifyGlobal?: boolean, toastConfig?: any) {
        if (notifyGlobal != undefined) this.setToartConfig(toastConfig);
        let message: string = "";
        if (event.body.message != undefined && typeof event.body.message == "string" && event.body.message.length > 0) {
            message = event.body.message
        }
        
        if (notifyGlobal && notifyGlobal === true) {
                if(event.body instanceof Blob){
                    Swal2.fire({
                        icon: 'success',
                        text: this.translate.instant('COMMON.MESSAGE.IMPORT_SUCCESS')
                    });
                    return;
                }
                switch (event.body.errorCode.toString()) {
                    case StatusCode.OK.toString():
                    case StatusCode.CREATED.toString():
                        Swal2.fire({
                            icon: 'success',
                            text: message
                        });
                        break;
                    default:
                        this.messageError.next(message);
                        Swal2.fire({
                            icon: 'error',
                            text: message
                        });
                        break;
                }
        }else{
            if(event.body.success == false && event.body.errorCode.toString() == StatusCode.BAD_REQUEST){
                Swal2.fire({
                    icon: 'error',
                    text: message
                });
            }
        }
    }

    /**
     * Process error and redirect
     *      
     * @param error `error` Error Response
     * 
     * ### Example
     * 
     * ```
     * responseUtilsService.processError(error);
     * ```
     */
    processError(error, notifyGlobal?: boolean, toastConfig?: any) {
        if (notifyGlobal) this.setToartConfig(toastConfig);
        let message: string = "";

        if (!error.error) {
            return;
        }
        if (error.error.message != undefined && typeof error.error.message == "string" && error.error.message.length > 0) {
            message = error.error.message
        }
        if (error.status) {
            switch (error.status) {
                case StatusCode.UNAUTHORIZED:
                    // redirect to logout - reset sate
                    // after redirect to login 
                    this.router.navigate(['/auth/logout'], { queryParams: { returnUrl: sessionStorage.getItem("lastUrl") } });
                    break;
                case StatusCode.INTERNAL_SERVER_ERROR:
                    Swal2.fire({
                        icon: 'error',
                        text: message
                    });
                    break;
                default:
                    if (notifyGlobal && notifyGlobal === true) {
                        Swal2.fire({
                            icon: 'error',
                            text: message
                        });
                    }
                    break;
            }
        }

    }

    showError(message) {
        Swal2.fire({
            icon: 'error',
            text: message
        });
    }
}
