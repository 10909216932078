import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NbDialogService, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';

import {UserData} from '../../../@core/data/users';
import {LayoutService} from '../../../@core/utils';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AuthService, Logout, PermissionActionTypes, currentUser} from '../../../@core/auth';
import {Store, select} from '@ngrx/store';
import {AppState} from '../../../@core/reducers';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { UserManageService } from '../../../@core/services/user-manage.service';
import { TranslationService } from '../../../@core/services/translation.service';
import { LayoutConfigService } from '../../../@core/layout';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    }
  ];

  currentTheme = 'default';

  userMenu = [{title: this.translateService.instant('MANAGE.GENERAL.USER_INFO'), action: 'profile', keyTitle: 'MANAGE.GENERAL.USER_INFO'},
  {title: this.translateService.instant('COMMON.GENERAL.SIGN_OUT'), action: 'logout', keyTitle: 'COMMON.GENERAL.SIGN_OUT'}];

 title = 'S.TOUCH';

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private store: Store<AppState>,
              private translateService: TranslateService,
              private dialogService: NbDialogService,
              private authService: AuthService,
              private userManage: UserManageService,
              private cdr: ChangeDetectorRef,
              private translationService: TranslationService,
              private layoutConfigService: LayoutConfigService,
              ) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.store.pipe(select(currentUser)).subscribe((user: any) => {
      if(user){
        this.user = user;
        this.user.walletBalanceStr = this.translateService.instant('MENU.BALANCE') + ': ' + new Intl.NumberFormat('en-US').format(this.user.walletBalance) + ' VNĐ';
      }
    });

    this.userManage.walletBalanceObservable$.subscribe((data: any) => {
      if(data && data.walletBalance){
        this.user.walletBalanceStr = this.translateService.instant('MENU.BALANCE') + ': ' + new Intl.NumberFormat('en-US').format(data.walletBalance) + ' VNĐ';
        this.cdr.detectChanges();
      }
    })

    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({name}) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.menuService.onItemClick().subscribe((input: any) => {
      switch (input.item.action) {
        case 'logout':
          this.logout();
          break;
        case 'profile':
          this.openProfile();
          break;
      }
    })

    var loginPage = this.layoutConfigService.getLoginPage();
    this.title = loginPage.title;
    this.translationService.eventChange.subscribe((lang:any) => {
      if(this.user && this.user.walletBalance){
        this.user.walletBalanceStr = this.translateService.instant('MENU.BALANCE') + ': ' + new Intl.NumberFormat('en-US').format(this.user.walletBalance) + ' VNĐ';
      }
      this.userMenu.forEach(item => {
        item.title = this.translateService.instant(item.keyTitle)
      })
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  logout() {
    this.store.dispatch(new Logout());
    if (localStorage.getItem("favorite-report") != null) {
      localStorage.removeItem('favorite-report');
    }
    if (localStorage.getItem("permissions") != null) {
      localStorage.removeItem('permissions');
    }
    if (localStorage.getItem("eventType") != null) {
      localStorage.removeItem('eventType');
    }
  }

  openProfile(item?: any) {
    const ref = this.dialogService.open(UserProfileComponent, {
      autoFocus: false,
      context: {
        // user: this.user,
      },
    });
  }
}
