import { BaseModelCT } from '../../models/query-models/_base.model.ct';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';

export class User extends BaseModelCT  {
    id: number;
    username: string;
    password: string;
    email: string;
    accessToken: string;
    refreshToken: string;
    roles: number[];
    picture: string;
    fullname: string;
    occupation: string;
	companyName: string;
    phone: string;
    timezone:string;
    address: Address;
    socialNetworks: SocialNetworks;
    pageMain?:string;
    type?:number;
    partnerCode?: string; 

    clear(): void {
        this.id = undefined;
        this.username = 'admin';
        this.password = 'demo';
        this.email = 'admin@demo.com';
        this.accessToken = null;
        this.refreshToken = 'access-token-f8c137a2c98743f48b643e71161d90aa';
        this.roles = [1]; // Administrator
        // this.picture = 'assets/images/alan.png';
        this.fullname = 'Sean';
        this.occupation= 'CEO';
        this.companyName= 'Keenthemes';
        this.phone= '456669067890';
        this.address = {
            addressLine: 'L-12-20 Vertex, Cybersquare',
            city: 'San Francisco',
            state: 'California',
            postCode: '45000'
        } as Address;
        this.socialNetworks = {
            linkedIn: 'https://linkedin.com/admin',
            facebook: 'https://facebook.com/admin',
            twitter: 'https://twitter.com/admin',
            instagram: 'https://instagram.com/admin'
        } as SocialNetworks;
        this.pageMain = "";
        this.type = 0;
        this.partnerCode = 'PM#1';
    }    
    // deserialize(input:any):User{
    //     Object.assign(this,input);        
    //     return this;
    // }
}
