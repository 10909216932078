
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TypesUtilsService } from '../../@core/models';
import { BaseOptionAPI } from '../../@core/models/query-models/_base.option-api';
import { Response } from '../../@core/models/query-models/_new-response-body.model';

const API_URL = environment.api.host + "/settings/service-price";

@Injectable({
  providedIn: 'root'
})
export class ServicePriceService {

  constructor(private http: HttpClient, private utils: TypesUtilsService) { }
  search(option?: any, params?: any): Observable<Response> { 
    return this.http.post<Response>(API_URL + '/search', option, {params : params});
  }

  create(model: any, option?: BaseOptionAPI): Observable<Response> {
    return this.http.post<Response>(API_URL, model, {
      params: this.utils.processBassOptionApi(option),
    });
  }

  update(id:number, model: any, option?: BaseOptionAPI): Observable<Response> {
    const url = `${API_URL}/${id}`;
    return this.http.put<Response>(url, model, {
      params: this.utils.processBassOptionApi(option),
    });
  }

  delete(id: number, option?: BaseOptionAPI): Observable<Response> {
    const url = `${API_URL}/${id}`;
    return this.http.delete<Response>(url, {
      params: this.utils.processBassOptionApi(option),
    });
  }

  getDetail(id: number, option?: BaseOptionAPI): Observable<Response> {
    return this.http.get<Response>(`${API_URL}/${id}` ,{
      params: this.utils.processBassOptionApi(option),
    })
  }
}

