// Angular
import { Pipe, PipeTransform, ChangeDetectorRef, OnDestroy } from '@angular/core';
import * as moment from 'moment'; // add this 1 of 4
import { TranslateService } from '@ngx-translate/core';
import { tap, takeUntil, finalize } from 'rxjs/operators';
import { Subject, Observable, of, empty, timer, interval } from 'rxjs';
import { CurrentUserService } from '../../../shared/services/current-user.service';

@Pipe({
  name: 'userDateAdv'
})
export class UserDateAdvPipe implements PipeTransform,OnDestroy {
  private dateFormatDefault = "DD/MM/YYYY";
  private timeFormatDefault = "HH:mm";
  private timeZoneDefault: TimeZone;
  private _local: LocalMoment;
  private _dateFormat: string;
  private _timeFormat: string;
  private _timeZone: string;
  private langTranform = { vn: 'vi', en: 'en' };
  private strValue:string = "";
  stateChanges = new Subject<void>();
  private dateTimeFormat;
  private dateTime;
  private now;
  private source;


  /**
   * Set defualt
   * local = 'en'
   * time_zone = 'Asia/Ho_Chi_Minh'
   * dateFormatDefault = "DD/MM/YYYY";
   * timeFormatDefault = "HH:mm";
   * @param store 
   */
  constructor(
    private translate: TranslateService,
    private currentUser: CurrentUserService,
    // private cd: ChangeDetectorRef,
  ) {
    // this.timeZoneDefault = TIME_ZONES.find(x => x.timeZone == "Asia/Ho_Chi_Minh");// set time zone default
    // let localByLanguage = localStorage.getItem('language');
    // let indexLocal = LOCAL_MAP.find(x => x.type == localByLanguage);
    // if (indexLocal) {
    //   this._local = indexLocal;
    // }
    // else {
    //   this._local = LOCAL_MAP.find(x => x.type == "en");
    // }
    this._dateFormat = this.currentUser.dateFormat;
    this._local = this.currentUser.local;
    this._timeZone = this.currentUser.timeZone;
    this._timeFormat = this.currentUser.timeFormat;

    this.stateChanges.subscribe(() => {
      let now = moment(moment().utc().format("YYYY/MM/DD HH:mm:ss"), "YYYY/MM/DD HH:mm:ss");
      // 29/03/2023 remove add offset timezone
      now.add(this._timeZone, "minutes");
      this.strValue = moment().utc().format("YYYY/MM/DD HH:mm:ss");
      // this.strValue = this.transformFormat(this.dateTimeFormat, this.dateTime, this._dateFormat, this._timeFormat, now);
      // console.log(this.strValue );
      
      // this.cd.markForCheck();
      
    });
    
  }

  /**
   * The process of transform datetime to the format of the current user login.  
   * @param value "YYYY-MM-dd HH:i:ss"
   * dateTimeFormat default 'date'
   * @param dateTimeFormat date|time|dateTime|fromNow|fromNowDate|format   
   * @param timeZone 
   */
  transform(value: string | number | any, dateTimeFormat?: string, options?: {
    formatInput?: string,
    now?: string,
    nowOffset?: number,
    valueOffset?: number,
    duration?:number // s
  }): any {
    // console.log('dateTimeFormat =>', dateTimeFormat)
    let formated = "";

    if (!options) {
      options = {}
    }
    if (value == undefined || value == null || value == "") return this.strValue;
    if(value == "now"){
      let nowTmp = moment(moment().utc().format("YYYY/MM/DD HH:mm:ss"), "YYYY/MM/DD HH:mm:ss");
      // 29/03/2023 remove add offset timezone
      nowTmp.add(this._timeZone, "minutes");
      value = nowTmp.format("YYYY/MM/DD HH:mm:ss");
    }
    if (dateTimeFormat == undefined) dateTimeFormat = "date";
   

    // process locale
    let lang = 'en';
    if (localStorage.getItem('language')) lang = localStorage.getItem('language');
    if (this.langTranform[lang]) lang = this.langTranform[lang];
    moment.locale(lang);

    //process value
    if (typeof value == "string") {
      value = value.replace(/-/g, "/");
    }
    let dateTime;
    if (typeof value == "string" && value.length > 0) {
      let temp = value.split(' ');
      if (temp.length == 2) dateTime = moment(value, "YYYY/MM/DD HH:mm:ss");
      else if (temp.length == 1) dateTime = moment(temp[0], "YYYY/MM/DD");

    }
    else if (typeof value == 'number' || Number.parseInt(value).toString() == value) {
      dateTime = moment(moment.unix(Number.parseInt(value)).utc().format("YYYY/MM/DD HH:mm:ss"), "YYYY/MM/DD HH:mm:ss");
    }

    if (dateTime && dateTime.isValid()) {
      if (options.valueOffset != undefined){
        dateTime.add(options.valueOffset, "seconds");
      } else {
        // 29/03/2023 remove add offset timezone
        dateTime.add(this._timeZone, "minutes");
      }
       
    }
    else {
      this.strValue = formated;
      return this.strValue;
    }

    // process now
    let now;
    if (options.now) {
      if (typeof options.now == "string" && options.now.length > 0) {
        options.now = options.now.replace(/-/g, "/");
        let temp = value.split(' ');
        if (temp.length == 2) now = moment( options.now, "YYYY/MM/DD HH:mm:ss");
        else if (temp.length == 1) now = moment(temp[0], "YYYY/MM/DD");

        if (options.nowOffset != undefined){
          now.add(options.nowOffset, "seconds");
        } 
        else {
          // 29/03/2023 remove add offset timezone
          now.add(this._timeZone, "minutes")
        };

      }
      else if (Number.parseInt(options.now).toString() == options.now) {
        now = moment(moment.unix(Number.parseInt(options.now)).utc().format("YYYY/MM/DD HH:mm:ss"), "YYYY/MM/DD HH:mm:ss");
      }
    }
    else {
      now = moment(moment().utc().format("YYYY/MM/DD HH:mm:ss"), "YYYY/MM/DD HH:mm:ss");
      now.add(this._timeZone, "minutes");
    }

    if (dateTime.isValid()) {
      this.dateTime = dateTime;
      this.dateTimeFormat =  dateTimeFormat;
      if (now) {
        formated = this.transformFormat(dateTimeFormat, dateTime, this._dateFormat, this._timeFormat, now)
      }
      else {
        formated = this.transformFormat(dateTimeFormat, dateTime, this._dateFormat, this._timeFormat)
      }

      if (formated == "Invalid date") formated = "";
    }
    this.strValue = formated;

    // if(this.dateTimeFormat == "fromNowDateTimeShortAuto"){
    //   let _this = this;
    //   setTimeout(()=>{
    //     _this.stateChanges.next();
    //   },5000);
    // }
    
   
    return this.strValue;
  }
  /**
   * transform datatime moment by format string
   * @param type 
   * @param moment 
   * @param dateFormat 
   * @param timeFormat 
   */
  private transformFormat(type: string, dateTime: moment.Moment, dateFormat: string, timeFormat: string, dateTimeNow?: moment.Moment) {
    let str = "";
    let now, years, months, days, hours, minutes, seconds;

    switch (type) {
      case "date":
        str = dateTime.format(dateFormat);
        break;
      case "time":
        str = dateTime.format(timeFormat);
        break;
      case "dateTime":
        str = dateTime.format(dateFormat + " " + timeFormat);
        break;
      case "datetime":
        str = dateTime.format(dateFormat + " " + timeFormat);
        break;
      case "fromNow":
        if (dateTimeNow) {
          str = moment(dateTime).from(moment(dateTimeNow));
        }
        else {
          str = dateTime.fromNow();
        }
        break;
      case "toNow":
        if (dateTimeNow) {
          str = moment(dateTime).to(moment(dateTimeNow));
        }
        else {
          str = dateTime.fromNow();
        }
        // str = dateTime.toNow();
        break;
      case "calendar":
        if (dateTimeNow) {
          str = moment(dateTime).calendar(moment(dateTimeNow), {
            // sameDay: '[Today]',
            // nextDay: '[Tomorrow]',
            // nextWeek: 'dddd',
            // lastDay: '[Yesterday]',
            // lastWeek: '[Last] dddd',
            sameElse: dateFormat + " " + timeFormat
          });
        }
        else {
          str = dateTime.calendar();
        }
        // str = dateTime.calendar(dateTime);
        break;
      case "fromNowDate":
        now = moment(new Date());
        if (dateTimeNow) {
          now = dateTimeNow;
        }
        years = now.diff(dateTime, "years");
        dateTime.add(years, "years");
        months = now.diff(dateTime, "months");
        dateTime.add(months, "months");
        days = now.diff(dateTime, "days");

        if (Math.abs(years) > 0) {
          str += Math.abs(years) + "y ";
        }
        if (Math.abs(months) > 0) {
          str += Math.abs(months) + "m ";
        }
        if (Math.abs(days) > 0 && Math.abs(years) <= 0) {
          str += Math.abs(days) + "d ";
        }
        str = str == "" ? this.translate.instant('MOMENT.TO_DAY') : str;
        break;
      case "fromNowDateTime":

        now = moment(new Date());
        if (dateTimeNow) {
          now = dateTimeNow;
        }
        years = now.diff(dateTime, "years");
        dateTime.add(years, "years");
        months = now.diff(dateTime, "months");
        dateTime.add(months, "months");
        days = now.diff(dateTime, "days");
        dateTime.add(days, "days");
        hours = now.diff(dateTime, "hours");
        dateTime.add(hours, "hours");
        minutes = now.diff(dateTime, "minutes");
        dateTime.add(minutes, "minutes");
        seconds = now.diff(dateTime, "seconds");

        if (Math.abs(years) > 0) {
          str += Math.abs(years) + "y ";
        }
        if (Math.abs(months) > 0) {
          str += Math.abs(months) + "m ";
        }
        if (Math.abs(days) > 0) {
          str += Math.abs(days) + "d ";
        }
        if (Math.abs(hours) > 0) {
          str += Math.abs(hours) + "h ";
        }
        if (Math.abs(minutes) > 0) {
          str += Math.abs(minutes) + "min ";
        }
        if (Math.abs(seconds) > 0) {
          str += Math.abs(seconds) + "s ";
        }

        str = str == "" ? this.translate.instant('MOMENT.TO_DAY') : str;
        break;
      case "fromNowDateTimeShort":
        now = moment(new Date());
        if (dateTimeNow) {
          now = dateTimeNow;
        }
        str = this.getFromNowDateTimeShort(dateTime, now);
        break;
      case "fromNowDateTimeShortAuto":
        now = moment(new Date());
        if (dateTimeNow) {
          now = dateTimeNow;
        }
        str = this.getFromNowDateTimeShort(dateTime, now);
        break;
      case "fromNowDateTimeShort3":
        now = moment(new Date());
        if (dateTimeNow) {
          now = dateTimeNow;
        }
        str = this.getFromNowDateTimeShort(dateTime, now, 3);
        break;
      // case "durationTime":

      //   break;
      default:
        str = dateTime.format(type);
        break;
    }
    return str;
  }
  getFromNowDateTimeShort(dateTime, dateTimeNow, maxCount?: number) {

    let str = "";
    let count = 1;
    // let maxCount = 2;
    if (!maxCount) maxCount = 2;
    let now, years, months, days, hours, minutes, seconds;

    now = dateTimeNow;
    years = now.diff(dateTime, "years");
    dateTime.add(years, "years");
    months = now.diff(dateTime, "months");
    dateTime.add(months, "months");
    days = now.diff(dateTime, "days");
    dateTime.add(days, "days");
    hours = now.diff(dateTime, "hours");
    dateTime.add(hours, "hours");
    minutes = now.diff(dateTime, "minutes");
    dateTime.add(minutes, "minutes");
    seconds = now.diff(dateTime, "seconds");


    if (Math.abs(years) > 0) {
      str += Math.abs(years) + "y ";
      count++;
    }
    if (Math.abs(months) > 0 && count <= maxCount) {
      str += Math.abs(months) + "m ";
      count++;
    }
    if (Math.abs(days) > 0 && count <= maxCount) {
      str += Math.abs(days) + "d ";
      count++;
    }
    if (Math.abs(hours) > 0 && count <= maxCount) {
      str += Math.abs(hours) + "h ";
      count++;
    }
    if (Math.abs(minutes) > 0 && count <= maxCount) {
      str += Math.abs(minutes) + "min ";
      count++;
    }
    if (Math.abs(seconds) > 0 && count <= maxCount) {
      str += Math.abs(seconds) + "s ";
      count++;
    }

    str = str == "" ? this.translate.instant('MOMENT.TO_DAY') : str;

    return str;
  }
  ngOnDestroy() {
    if (this.source) {
      this.source.unsubscribe();
      this.source = undefined;
     
    }
    this.stateChanges.complete();
  }

}
export class TimeZone {
  countryCode: string;
  countryName: string;
  timeZone: string;
  GMTOffset: string;
  GMTOffsetMinute: string;
}
export class DateFormat {
  type: string;
  format: string
}
export class TimeFormat {
  type: string;
  format: string
}
export class LocalMoment {
  type: string;
  local: string;
}
export class RelativeTime {
  local: string;
  relativeTime: {}
  future: "in %s";
  past: "%s ago";
  s: 'day';
  ss: 'day';
  m: "day";
  mm: "day";
  h: "day";
  hh: "day";
  d: "a day";
  dd: "%d days";
  M: "a month";
  MM: "%d months";
  y: "a year";
  yy: "%d years"
}