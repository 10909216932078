// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { filter, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { defer, Observable, of, from } from 'rxjs';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
// Auth actions
import { AuthActionTypes, Login, Logout, Register, UserLoaded, UserRequested } from '../_actions/auth.actions';
import { AuthService } from '../_services/index';
import { AppState } from '../../reducers';
import { environment } from '../../../../environments/environment';
import { isUserLoaded } from '../_selectors/auth.selectors';
import { User } from '../_models/user.model';
import { AllPermissionsRequested } from '../_actions/permission.actions';
import { CurrentUserService } from '../../../shared/services/current-user.service';

@Injectable()
export class AuthEffects {
    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType<Login>(AuthActionTypes.Login),
            tap(action => {
                localStorage.setItem(environment.authTokenKey, action.payload.authToken);
                this.store.dispatch(new UserRequested());
            }),
        ),
        {dispatch: false}
    );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType<Logout>(AuthActionTypes.Logout),
            tap(() => {
                localStorage.removeItem(environment.authTokenKey);
                // this.router.navigate(['/auth/login'], {queryParams: {returnUrl: this.returnUrl}});
                this.router.navigate(['/auth/login']);
            })
        ),
        {dispatch: false}
    );

    register$ = createEffect(() =>
        this.actions$.pipe(
            ofType<Register>(AuthActionTypes.Register),
            tap(action => {
                localStorage.setItem(environment.authTokenKey, action.payload.authToken);
            })
        ),
        {dispatch: false}
    );

    loadUser$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType<UserRequested>(AuthActionTypes.UserRequested),
                withLatestFrom(this.store.pipe(select(isUserLoaded))),
                filter(([action, _isUserLoaded]) => !_isUserLoaded),
                mergeMap(([action, _isUserLoaded]) => this.auth.profile()),
                tap((data: any) => {
                    let _user = data.data as User;
                    if (_user) {
                        this.store.dispatch(new UserLoaded({ user: _user }));
                        // load permision after loaded user api
                        this.store.dispatch(new AllPermissionsRequested());
                        this.currentUserService.setCurrentUser(_user);
                    } else {
                        this.store.dispatch(new Logout());
                    }
                })
            )
    },{ dispatch: false }
    );

    // @Effect()
    init$: Observable<Action> = createEffect(() =>
        defer(() => {
            const userToken = localStorage.getItem(environment.authTokenKey);
            let observableResult = of({ type: 'NO_ACTION' });
            if (userToken) {
                observableResult = of(new Login({ authToken: userToken }));
            }
            return observableResult;
        })
    )
        ;

    private returnUrl: string;

    constructor(private actions$: Actions,
        private router: Router,
        private auth: AuthService,
        private store: Store<AppState>,
        private currentUserService: CurrentUserService) {

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.returnUrl = event.url;
            }
        });
    }
}
