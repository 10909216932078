// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store, select } from '@ngrx/store';// Auth
import { LoginPage } from '../../../../../@core/auth/_models/login-page';
import { AuthNoticeService, AuthService, Login, User } from '../../../../../@core/auth';
import { AppState } from '../../../../../@core/reducers';
import { LayoutConfigService, MenuAsideService, TranslationService } from '../../../../../@core/layout';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { MapUserService } from '../../../../../shared/services/map-user.service';
import { ConfigService } from '../../../../../shared/services/config.service';

interface LanguageFlag {
	lang: string;
	name: string;
	flag: string;
	active?: boolean;
}
/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
	EMAIL: '',
	PASSWORD: ''
};

@Component({
  selector: 'kt-login-v1',
  templateUrl: './login-v1.component.html',
  styleUrls: ['./login-v1.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LoginV1Component implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	language: LanguageFlag;

	languages: LanguageFlag[] = [
		{
			lang: 'vn',
			name: 'Việt Nam',
			flag: './assets/media/flags/001-vietnam.svg'
		},
		{
			lang: 'en',
			name: 'English',
			flag: './assets/media/flags/012-uk.svg'
		},
		// {
		// 	lang: 'es',
		// 	name: 'Spanish',
		// 	flag: './assets/media/flags/016-spain.svg'
		// },
	];

	private unsubscribe: Subject<any>;

	private returnUrl: any;
	public logo:string;
	public iosUrl:string;
	public androidUrl:string;
	public loginPage: LoginPage;
	public backgroundImageStyle:any;
	public backgroundImage:string = './assets/media/bg/bg-3.jpg';
	position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
	destroyByClick = true;
  	duration = 2000;
  	hasIcon = true;
  	preventDuplicates = false;
  	status: NbComponentStatus = 'primary';

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private translationService: TranslationService,
		private layoutConfigService: LayoutConfigService,
		private menuAsideService:MenuAsideService,
		private toastrService: NbToastrService
	) {
		this.unsubscribe = new Subject();	
		this.logo =  layoutConfigService.getLogo();
		this.loginPage = this.layoutConfigService.getLoginPage();
		if(this.loginPage.background && this.loginPage.background.length > 0){
			this.backgroundImage = this.loginPage.background[0]
			this.backgroundImageStyle = { 'background-image': 'url(' + this.backgroundImage + ')',
			'background-position': 'center',
			'background-repeat': 'no-repeat',
			'background-size': 'cover',
			'height':'100%'}
		}
		this.iosUrl = this.loginPage.iosUrl;
		this.androidUrl = this.loginPage.androidUrl;
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.initLoginForm();
		this.setSelectedLanguage();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params['returnUrl'] || '/';
		});
		
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	// language
	setLanguage(lang) {
		this.languages.forEach((language: LanguageFlag) => {
			if (language.lang === lang) {
				language.active = true;
				this.language = language;

			} else {
				language.active = false;
			}
		});
		this.translationService.setDefaultLang(lang);
		this.translationService.setLanguage(lang);
	}

	
	setSelectedLanguage(): any {
		this.setLanguage(this.translationService.getSelectedLanguage());
	}

	//end language

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {	
		this.loginForm = this.fb.group({
			email: ["", Validators.compose([
				Validators.required,
				// Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			],
			password: ["", Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const authData = {
			email: controls['email'].value,
			password: controls['password'].value
		};
		this.auth
			.newLogin(authData.email, authData.password)
			.pipe(
				tap(data => {
					let user = data.data as User;
					if (user.accessToken != null) {
						this.store.dispatch(new Login({ authToken: user.accessToken }));
						this.router.navigateByUrl(this.menuAsideService.getPageMainAfterLogin(user['pageMain'], this.returnUrl)); // Main page		
						this.auth.getAllPermissionStream();
					}
				}),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			)
			.subscribe();
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	private showToast(type: NbComponentStatus, title: string, body: string) {
		const config = {
		  status: type,
		  destroyByClick: this.destroyByClick,
		  duration: this.duration,
		  hasIcon: this.hasIcon,
		  position: this.position,
		  preventDuplicates: this.preventDuplicates,
		};
		this.toastrService.show(body,title,config);
	  }
}
