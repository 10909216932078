// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Module reducers and selectors
import { currentUserPermissions, isLoggedIn } from '../_selectors/auth.selectors';
import { Permission } from '../_models/permission.model';
import { find } from 'lodash';
// import { UserTreeService } from '@core/common/_service/user-tree.service';
// import { ListDeviceService } from '@core/common';
import { AppState } from '../../reducers';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ModuleGuard implements CanActivate {
    constructor(private store: Store<AppState>, private router: Router,
        // private userTreeService:UserTreeService,
        // private listDeviceService:ListDeviceService
        ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        // this.userTreeService.userTreeMainDisplay = false;
        // this.listDeviceService.showOnMobile = false;
        if (route.routeConfig.path == "login") {
            let returnUrl = route.queryParams['returnUrl']||'/';
            
            this.store.pipe(
                select(isLoggedIn),
                tap(loggedIn => {
                    if (loggedIn && localStorage.getItem(environment.authTokenKey) != null) {
                        this.router.navigate([returnUrl]);
                        return  of(true);
                    }
                })
            ).subscribe().unsubscribe();
        }

        const moduleName = route.data['permisison'] as string;
        if (!moduleName) {
            // this.router.navigateByUrl('/error/403');
            return of(true);
        }

        return this.store
            .pipe(
                select(currentUserPermissions),
                map((permissions: Permission[]) => {
                    const _perm = find(permissions, (elem: Permission) => {
                        return elem.title.toLocaleLowerCase() === moduleName.toLocaleLowerCase();
                    });
                    return _perm ? true : false;
                }),
                tap(hasAccess => {

                    if (!hasAccess) {
                        this.router.navigateByUrl('/error/403');
                    }
                })
            );
    }
}
