// Angular
import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store, select } from '@ngrx/store';
// Object-Path
import * as objectPath from 'object-path';
import { LayoutConfigModel, LayoutConfigService } from '../../../@core/layout';
import { environment } from '../../../../environments/environment';

const versionLogin = 'versionLogin';
declare var require: any;
const LAYOUT_LOGINS = ['login-v1', 'login-v2', 'login-v3'];
@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],

	// encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	public loading: boolean = true;
	public host: any = {};
	public configLayout: LayoutConfigModel;
	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private layoutConfigService: LayoutConfigService
	) {
		this.configLayout = this.layoutConfigService.getConfig();
		// switch (this.configLayout.self.login) {
		// 	case 'login-v1':
		// 		require('style-loader!../../../../assets/css/default/pages/login/login-1.css');
		// 		break;
		// 	case 'login-v2':
		// 		require('style-loader!../../../../assets/css/default/pages/login/login-1.css');
		// 		break;
		// 	case 'login-v3':
		// 		require('style-loader!../../../../assets/css/default/pages/login/login-6.css');
		// 		break;
		// }
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		let version;
		if (
			localStorage.getItem(versionLogin) &&
			localStorage.getItem(versionLogin) != environment.version
		) {
			localStorage.setItem(versionLogin, environment.version);
			if (localStorage.getItem(versionLogin)) location.reload();
		} else {
			version = environment.version;
		}
		// console.log('version-login', version);
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {}
}
