<div ngbDropdown style="cursor: pointer" [placement]="'bottom-right'" class="kt-header__topbar-item kt-header__topbar-item--langs">
  <div ngbDropdownToggle class="kt-header__topbar-wrapper">
		<span class="kt-header__topbar-icon"
          [ngClass]="{ 'kt-header__topbar-icon--brand' : iconType === 'brand' }">
			<img class="" src="{{language?.flag}}" alt=""/>
		</span>
  </div>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
    <nb-list>
      <nb-list-item style="cursor: pointer" ngbDropdownToggle *ngFor="let language of languages" (click)="setLanguage(language.lang)" [ngClass]="{'kt-nav__link--active': language.active}">
							<img style="width: 20px" src="{{language.flag}}">
          <span class="ml-2">{{language.name}}</span>
      </nb-list-item>
    </nb-list>
  </div>
</div>
