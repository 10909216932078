import { Deserialize } from './_deserializable';

export class BaseModelCT implements Deserialize {
    constructor(){};
    clear(){
    };
    deserialize(input?: any,option?:{tranform?:{        
    }})
    {
        this.clear();
        if(input){
            if(option.tranform){
                Object.keys(option.tranform).forEach(function(key){
                    if(input[option.tranform[key]]!=undefined){
                        input[key]=input[option.tranform[key]];
                    }
                    
                })
            }
            Object.assign(this,input);
        }
        return this;
    };
}