<!--begin::Body-->
<div class="kt-login__body">
        <!--begin::Signin-->
        <div class="kt-login__form">
            <div class="kt-login__title">
                <h3>{{ 'AUTH.RESET.TITLE' | translate }}</h3>
            </div>
    
            <kt-auth-notice></kt-auth-notice>
    		<form class="" [formGroup]="resetPasswordForm" (ngSubmit)='submitResetPassword(resetPasswordForm)'>

                <div class="form-group">
                    <mat-form-field>
                        <mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
                        <input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}" formControlName="password" autocomplete="off"/>
                        <small *ngIf="checkPassword" class="text-danger">
                                {{'MANAGE.USER.MESSAGE.PASS_MIN_CHAR' | translate}}
                            </small>
                        <mat-error *ngIf="isControlHasError('email','required')">
                            <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="isControlHasError('email','email')">
                            <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="isControlHasError('email','minLength')">
                            <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                        </mat-error>
                        <mat-error *ngIf="isControlHasError('email','maxLength')">
                            <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group">
                        <mat-form-field>
                            <mat-label>{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}</mat-label>
                            <input matInput type="password" placeholder="{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}" formControlName="passAgain" autocomplete="off"/>
                            <mat-error *ngIf="isControlHasError('email','required')">
                                <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                            </mat-error>
                            <mat-error *ngIf="isControlHasError('email','email')">
                                <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
                            </mat-error>
                            <mat-error *ngIf="isControlHasError('email','minLength')">
                                <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                            </mat-error>
                            <mat-error *ngIf="isControlHasError('email','maxLength')">
                                <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                <!--begin::Action-->
                <div class="kt-login__actions">
                    <button type="button" routerLink="/auth/login" [disabled]="loading" id="kt_login_signup_cancel" class="btn btn-secondary btn-elevate kt-login__btn-secondary">{{ 'AUTH.GENERAL.SIGNIN' | translate }}</button>
                    <button 
                     type="submit"
                     [disabled]="resetPasswordForm.invalid" 
                     class="btn btn-primary btn-elevate kt-login__btn-primary"
                     [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</button>
                </div>
                <!--end::Action-->
            </form>
        </div>
    </div>
    
    