
// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { ActivatedRoute } from '@angular/router';
import { AuthNoticeService, AuthService } from '../../../@core/auth';
import { ToastService, ValidatorCustomService } from '../../../@core/layout';


@Component({
	selector: 'kt-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
	// Public params
    resetPasswordForm: FormGroup;
    public checkPassword : boolean = false;
	loading = false;
    errors: any = [];
    public token:string = '';
	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authService
	 * @param authNoticeService
	 * @param translate
	 * @param router
	 * @param fb
	 * @param cdr
	 */
	constructor(
		private authService: AuthService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private toast: ToastService,
        private validate:ValidatorCustomService


	) {
        this.unsubscribe = new Subject();
        this.token = this.route.snapshot.queryParamMap.get('token');
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegistrationForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegistrationForm() {
		this.resetPasswordForm = this.fb.group({
			password: ['',Validators.required],
            passAgain : ['',Validators.required]
		});
    }
    
    isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.resetPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}
	/**
	 * Form Submit
	 */
	submitResetPassword(form) {
        let pass_new = form.value.password;
        let pass_again = form.value.passAgain;

        let params = {
            password : form.value.password,
            token : this.token
		}
	
      if(pass_new != '' && pass_new != null && pass_new != undefined)
      {
	   this.checkPassword = false;
	   let respon =  this.validate.password(pass_new,pass_again);
	   if(respon.password == false) 
	   {
		this.checkPassword = true;
		return;
	   }
	   if(respon.hasOwnProperty('confirm'))
	   {
		this.toast.show(
            {
              message:this.translate.instant('MANAGE.USER.MESSAGE.PASSWORD_INCORRECT'),
              type : 'error',
            })
           return; 
	   }
        params.password = pass_new;
      }
	    this.loading = true;
		this.authService.resetPassword(params,{ notifyGlobal: true }) 
		.pipe(
			finalize(() => {
				this.loading = false;
				this.cdr.markForCheck();
			})
		)
		  .subscribe((result: any) => {
			if(result.status == 200) this.loading = false;
			setTimeout(() => {
				this.router.navigate(['/auth/login']);

			}, 2000);

		  })
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */

}
