// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, NavigationEnd } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { isLoggedIn } from '../_selectors/auth.selectors';
import { Logout } from '../_actions/auth.actions';
import { AppState } from '../../reducers';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private store: Store<AppState>, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store
            .pipe(
                select(isLoggedIn),
                tap(loggedIn => {
                    if (!loggedIn) {
                        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: sessionStorage.getItem("lastUrl") } });
                    }
                    else {
                        sessionStorage.setItem("lastUrl", state.url);
                    }
                })
            );
    }
}
