import {ChangeDetectorRef, Component, EventEmitter, OnInit} from '@angular/core';
import {NbDialogRef, NbDialogService} from "@nebular/theme";
import {Gallery, ImageItem} from "@ngx-gallery/core";
import {MapUserService} from "../../services/map-user.service";
import {ConfirmDialogComponent} from "../../confirm-dialog/confirm-dialog.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'ngx-charge-station-image-view',
  templateUrl: './charge-station-image-view.component.html',
  styleUrls: ['./charge-station-image-view.component.scss']
})
export class ChargeStationImageViewComponent implements OnInit {
  chargePhotoInfos: any[];
  fileImage: any;
  deviceId: any;
  title: string
  enableAddImage: boolean = false;
  ngOnInit() {
    this.gallery.ref().setConfig({thumb: false})
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private mapUserService: MapUserService,
    private dialogService: NbDialogService,
    private translateService: TranslateService,
    private gallery: Gallery,
    protected dialogRef: NbDialogRef<ChargeStationImageViewComponent>,
) {
  }

  showImage(item) {
    const dataGallery = item.map(e => new ImageItem( {
        src: e.url,
        thumb: e.url
      }
    ))
    this.gallery.ref().load(dataGallery)
  }

  addImage(element) {
    this.fileImage = null;
    element.click();
  }

  onImageUpload(data) {
    if (data.target.files[0]) {
      const uploadFormData = new FormData();
      uploadFormData.append('data', JSON.stringify({
        deviceId: this.deviceId,
        caption: data.target.files[0].name,
        description: data.target.files[0].name
      }))
      uploadFormData.append('image', data.target.files[0]);
      this.mapUserService.chargePhoto(uploadFormData, {}).subscribe((res: any) => {
        if (res.data)
        this.chargePhotoInfos.push(res.data);
        this.cdr.detectChanges();
        // this.dialogRef.close('ok')
      })
    }

  }
  delete(item, index) {
    const ref = this.dialogService.open(ConfirmDialogComponent, {
      autoFocus: false,
      context: {
        title: this.translateService.instant('COMMON.ACTIONS.DELETE') + ' ' + this.translateService.instant('MAP.LABEL.photo'),
        message: 'COMMON.MESSAGE.COMFIRM_DELETE',
      },
    });
    ref.onClose.subscribe(res => {
      if (res) {
        this.mapUserService.deleteChargePhoto( { id: item.id , deviceId: this.deviceId}).subscribe((data: any) => {
          if (data.errorCode === '200') {
              this.chargePhotoInfos.splice(index, 1);
              this.cdr.detectChanges();
          }
        });
      }
    });
  }
}

