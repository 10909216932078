// USA
export const locale = {
	lang: 'en',
	data: {
		TRANSLATOR: {
			SELECT: 'Select your language',
		},
		THEME: {
			LIGHT: 'Light',
			DARK: 'Dark',
		},
		MENU: {
			NEW: 'New',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create new',
			PAGES: 'Page',
			FEATURES: 'Features',
			APPS: 'Apps',
			DASHBOARD: 'Dashboard',
			MAP: 'Map',
			REPORT: 'Reports',
			REPORT_FUEL: 'Change',
			MANAGE: 'Manage',
			MANAGER_USER: 'Account',
			MANAGER_DEVICE: 'Devices',
			GROUP_DEVICE: 'Device group',
			COMMAND: 'Send command',
			ADMIN: 'Administrator',
			SETTING_SYSTEM: 'System configuration',
			MANAGE_PERMISSIONS: 'Permission management',
			MANAGE_ROLES: 'Role management',
			MANAGER_DRIVER: 'Driver',
			MANAGER_POI: 'Landmarks',
			TRIP: 'Trip',
			LOGIN_PAGE: 'Manage login page',
			SETTING_DEVICE: 'Device configuration',
			SIM_TYPE: 'Sim type',
			DEVICE_TYPE: 'Device type',
			TRANSPORT_TYPE: 'Transport type',
			TRANSPORT_TYPE_QCVN: 'Transport type QCVN',
			SENSOR_MODEL: 'Sensor model',
			ICON: 'Device icon',
			USER_ALIAS: 'User alias',
			ROLE_ALIAS: 'Role alias',
			ALERT: 'Alert rules',
			REPORT_DEVICE: 'Equipment status',
			MANAGE_GEOFENCE: 'Geofences',
			MANAGE_GEOFENCE_GROUP: 'Geofence group',
			SCHEDULE_REPORT: 'Scheduled report',
			REPORT_ENGINE: 'Engine hours',
			REPORT_ROUTE: 'Routes',
			REPORT_STOP_POINT: 'Stop point',
			REPORT_ROUTE_SIMPLE: 'Simple routes',
			REPORT_GENERAL: 'Summary (devices)',
			REPORT_SUMMARY_DAY: 'Summary by days',
			REPORT_GEOFENCE_SUMMARY: 'Summary geofence',
			REPORT_GEOFENCE_DETAIL: 'Detail geofence',
			REPORT_TEMPERATURE: 'Temperature',
			REPORT_STATUS_HISTORY: 'Status history',
			REPORT_TOLL_FREE: 'Toll fee',
			MANAGE_ALERTS: 'Notifications',
			REPORT_TASK_REMIND: 'Task remind',
			UTILITIES: 'Utilities',
			FUEL: 'Fuel',
			CARD_SERVICE: 'Card service',
			INSERT_CARD: 'Insert card',
			ACTIVITY_LOG: 'Activity log',
			SYSTEM_LOG: 'System change log',
			NOTIFI_GLOBAL: 'Notices',
			MESSAGE: 'Message',
			POI_TYPE: 'Landmarks type',
			TOLL_STATION: 'Toll station',
			TOLL_ROAD: 'Toll road',
			TOLL_SEGMENT: 'Toll segment',
			TOLL_FEE: 'Toll fee calculation',
			TRACKING_GEOFENCES: 'Multi-tracking by geofences',
			TRACKING_LANDMARKS: 'Multi-tracking by landmarks',
			POINTS_MANAGE: 'Point management',
			POINT_MANAGE: 'Manage points',
			RENEWS_HISTORY: 'Renews history',
			TRANSACTION_HISTORY: 'Transaction history',
			BALANCE: 'Balance',
			SYNTHESIS_REPORT: 'Synthesis report',
			// NOTIFI_GLOBAL:"System notification",
			TRACKINGS: 'Trackings',
			FEEDBACK: 'Feedback',
			DATA_LOG: 'Data log',
			MAP_API_KEY: 'Map API key',
			TECHNICAL_SUPPORT: 'Technical support',
			DEBUG: 'Raw log',
			SIM_INFO: 'SIM info history',
			DEVICE_LOGIN: 'Connection history',
			LOST_SIGNAL: 'Lost signal',
			DATA_GROUP_MANAGEMENT: "Data group management",
			CAMERA: 'Manage camera',
			INFO: 'Info',
			CONNECTOR: 'Connector',
			RFID_TAG: 'RFID Tag',
			WALLET: 'Wallet',
			CHARGE_TRANSACTION: 'Charge transaction',
			BOOKING_HISTORY: 'Booking history',
			CHANGE_BALANCE: 'Change balance',
			REPAIR: 'Repair costs',
			ROLE: 'Role',
			PERMISSION: 'Permission',
			DEVICE_ICON: 'Device icon',
			CONNECTOR_TYPE: 'Connector type',
			CONNECTOR_ICON: 'Connector icon',
			TECHNICAL_ASSISTANCE: 'Technical assistance',
			RAW: 'Raw data history',
			GATEWAY_CONNECTION: 'Gateway connection',
			DEVICE_CONFIGURATION: 'Device configuration',
			SYSTEM_CONFIGURATION: 'System configuration',
			BOOKING: 'Booking',
			CHARGE_STATION: 'Charge station',
			SERVICE_PRICE: 'Service price',
			BANK: 'Bank',
			TERMINAL: 'Business Unit',
			SYSTEM: 'System',
			DEVICE_STATUS: 'Device status',
			VOUCHER: 'Voucher'
		},
		AUTH: {
			GENERAL: {
				SIGNIN: 'Login',
				OR: 'or',
				SUBMIT_BUTTON: 'Submit',
				NO_ACCOUNT: 'No account yet?',
				SIGNUP_BUTTON: 'Sign up',
				FORGOT_BUTTON: 'Forgot password?',
				BACK_BUTTON: 'Back',
				PRIVACY: 'About us',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
				WELCOME: 'Welcome to Navitrack',
				DESCRIPTION:
					'Vietnam Electronics & Telecommunication Technology Joint Stock Company (Navitrack) is one of the leading companies in the field of distribution and manufacturing of positioning devices in Vietnam.',
				DONT_HAVE_ACCOUNT: "You don't have an account?",
			},
			LOGIN: {
				TITLE: 'Account login',
				BUTTON: 'Sign in',
			},
			FORGOT: {
				TITLE: 'Forgot password?',
				DESC: 'Enter your email to reset your password',
				SUCCESS: 'Successfully reset the password',
			},
			RESET: {
				TITLE: 'Reset password?',
			},
			REGISTER: {
				TITLE: 'Subscribe',
				DESC: 'Enter email',
				SUCCESS: 'The account has been successfully registered.',
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Full name',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Re-enter password',
				USERNAME: 'Account',
			},
			VALIDATION: {
				INVALID: '{{name}} is invalid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} is at least {{min}} characters',
				AGREEMENT_REQUIRED: 'Accept the required terms and conditions',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The account or password is incorrect',
				REQUIRED_FIELD: 'Do not leave blank',
				MIN_LENGTH_FIELD: 'Minimum length:',
				MAX_LENGTH_FIELD: 'Maximum length:',
				INVALID_FIELD: 'Invalid value',
			},
			MESSAGE: {
				TEST: 'Experimental',
			},
			DOWNLOAD: {
				TITLE: 'Get the app'
			}
		},
		DASHBOARD: {
			NOT_FOUND: 'No data',
			STATUS: 'Status',
			STATICIC: 'Statistic',
			DEVICE: 'Device',
			NO_DEVICE: 'Account not device',
			USER: 'User',
			CATEGORY: 'Category',
			INFO_DISTRIBOUTOR: 'Agency information',
			TOTAL_POINT: 'Total score',
			OPTION: 'Screen option',
			AVERAGE: 'Average',
			PARAMS: {
				ONLINE: 'Online',
				OFFLINE: 'Offline',
				STOCK: 'No sold',
				EXPIRED: 'Expired',
				INACTIVE: 'Inactive',
				ACTIVE: 'Active',
				TOTAL: 'Total',
				ALERTS: 'Alerts',
				SELL: 'Sold',
				CHART_STATUS: 'Status chart',
			},
			STATIC: {
				DURATION: 'Duration',
				DISTANCE: 'Distance',
				TOP_10_STOP: 'Stops',
				TOP_10_IDLING: 'Idlings',
				TOP_10_DISTANCE: 'Moves',
				IDLING_LABEL: 'Duration (minute)',
				MIN: 'minute',
				SECONDS: 'seconds',
				HOURS: 'hour',
				DURATION_LABEL: 'Duration (hour)',
				DISTANCE_LABEL: 'Distance (km)',
			},
			SUMMARY_USER: {
				TIME: 'Duration',
				AMOUNT: 'Amount',
				POWER: 'Electricity (kWh)',
				TRANSACTION: 'Number of transactions',
				DEVICE_CONNECT: 'Device online',
				TOTAL_DEVICE: 'Total device'
			},
			SUMMARY_BOOKING: {
				TITLE: 'Summary of booking schedules',
				STATUS: 'Status'
			},
			SUMMARY_CASH_FLOW: {
				TITLE: 'Revenue by month',
				CASH_IN: 'Cash in',
				CASH_OUT: 'Cash out',
				SAVE_AS_IMAGE: 'Save as image',
				LINE_CHART: 'Line chart',
				BAR_CHART: 'Bar chart',
				REVENUE: 'Revenue'
			},
			SUMMARY_ELECTRIC: {
				TITLE: 'Electric used (kWh)',
				SAVE_AS_IMAGE: 'Save as image',
				LINE_CHART: 'Line chart',
				BAR_CHART: 'Bar chart',
				ELECTRIC_POWER: 'Electric used'
			},
			TOP5_EV_CHARGER: {
				TITLE: 'List of charged stations',
				CHARGER_NAME: 'Station name',
				NUMBER_OF_CHARGING_TIME: 'Charging times',
				TOTAL_AMOUNT: 'Total amount'
			},
			TOP_TERMINAL_REVENUE: {
				TITLE: 'Top 10 units with the highest revenue',
				REVENUE: 'Revenue'
			},
			USER_REVENUE_PER_DAY: {
				TITLE: 'Revenue by day'
			},
			MANAGER: {
				SUMMARY_STATION: {
					TITLE: 'Status of devices',
					NOT_ACTIVE: 'Not active',
					ACTIVE: 'Active',
					EXPIRED: 'Expired',
					DISCONNECT: 'Disconnect'
				},
				SUMMARY_CONNECTOR: {
					TITLE: 'Status of charging terminals',
					AVAILABLE: 'Available',
					MAINTENANCE: 'Maintenance',
					INUSE: 'Charging'
				},
				SUMMARY_DAILY_REVENUE: {
					CHARGING_TYPE: 'Charging type',
					REVENUE: 'Revenue',
					BOOKING: 'Book',
					ANONYMOUS: 'Anonymous',
					NUMBER_OF_CHARGING_TIME: 'Times'
				},
				SUMMARY_REVENUE:{
					MONEY: 'Money (VNĐ)',
					TITLE: 'Revenue (VNĐ)',
					REVENUE: 'Revenue',
					PERSONAL: 'Personal revenue',
					TOTAL_REVENUE: 'Total revenue'
				}
			},
			USER_TYPE: {
				CUSTOMER: 'Personal',
				MANAGER: 'Manager',
				TITLE: 'Type',
				PERSONAL_EXPENDITURE: 'Personal expenditure',
				PERSONAL_REVENUE: 'Personal revenue',
				TOTAL_REVENUE: 'Total revenue'
			}
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Number of selected records:',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields',
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Customers',
				CUSTOMERS_LIST: 'Customers list',
				NEW_CUSTOMER: 'New Customer',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Customer Delete',
					DESCRIPTION:
						'Are you sure to permanently delete this customer?',
					WAIT_DESCRIPTION: 'Customer is deleting ...',
					MESSAGE: 'Customer has been deleted',
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Customers Delete',
					DESCRIPTION:
						'Are you sure to permanently delete selected customers?',
					WAIT_DESCRIPTION: 'Customers are deleting ...',
					MESSAGE: 'Selected customers have been deleted',
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected customers',
					MESSAGE:
						'Selected customers status has successfully been updated',
				},
				EDIT: {
					UPDATE_MESSAGE: 'Customer has been updated',
					ADD_MESSAGE: 'Customer has been created',
				},
			},
			MESSAGE: {},
		},
		ADMIN: {
			GENERAL: {
				SIM_TYPE: 'sim type',
				DEVICE_TYPE: 'device type',
				TRANSPORT_TYPE: 'transport type',
			},
			SIM_TYPE: {
				GENERAL: {
					TITLE_FORM_ADD: 'Create new sim type',
					TITLE_FORM_EDIT: 'Edit sim type',
					NAME_KEY: 'Code',
					NAME: 'Name',
					CREATED_DATE: 'Created date',
					SORT_ORDER: 'Order',
					ACTIONS: 'Actions',
				},
				VALIDATION: {},
				MESSAGE: {},
			},
			PERMISSION: {
				GENERAL: {
					TITLE_FORM_ADD: 'Create new permissions',
					TITLE_FORM_EDIT: 'Edit permissions',
					DESCRIPTION: 'Description',
					NAME: 'Name',
					GROUP: 'Groups',
					PERMISSION_NAME: 'Permissions name',
					PERMISSION: 'permissions',
					UPDATE_PERMISSION_FAILED: 'Update permissions failed!',
					DELETE_PERMISSION_FAILED: 'Delete permission failed!',
					DELETE_PERMISSION_SUCCESS: 'Delete permission successful!',
					UPDATE_PERMISSION_NAME: 'Update permissions',
					ADD_PERMISSION_NAME: 'Add permissions',
					INHERIT: 'Inherit',
				},
			},
			ROLE: {
				GENERAL: {
					TITLE_FORM_ADD: 'Create new role',
					TITLE_FORM_EDIT: 'Edit role',
					DESCRIPTION: 'Description',
					NAME: 'Name',
					NAME_PARENT: "Father's name",
					ROLE_NAME: 'Role name',
					ROLE: 'role',
					CHOICE_PERMISSIONS: 'Select permissions',
					KEY: 'Key'
				},
				MESSAGE: {
					UPDATE_ROLE_SUCCESS: 'Update role successfully',
					ADD_ROLE_SUCCESS: 'Create a new role successfully',
					DELETE_ROLE_SUCCESS: 'Delete role successfully',
					UPDATE_ROLE_FAILED: 'Update role failed!',
					ADD_ROLE_FAILED: 'Adding new roles failed!',
					DELETE_ROLE_FAILED: 'Delete the failed role!',
				},
			},
			CONNECTOR: {
				NAME: 'Name',
				DEVICE: 'Device',
				TYPE: 'Connector type',
				OUTPUT_CURRENT_TYPE:'Output current type',
				SUPPLY_INPUT: 'Supply input',
				MAXIMUM_OUTPUT_CURRENT: 'Maximum output current (kWh)',
				MAXIMUM_OUTPUT_POWER: 'Maximum output power (kWh)',
				MAXIMUM_OUTPUT_VOLTAGE: 'Maximum output voltage',
				GENERAL:{
					TITLE_FORM_ADD: 'Create new connector',
					TITLE_FORM_EDIT: 'Edit connector',
					CONNECTOR: 'connector'
				},
				CONNECTOR_NUMBER: 'Charging connector location',
				NUMBER_OF_CONNECTOR: 'Number of charging ports',
				CREATE_MULTI_CONNECTOR: 'Create multiple connector',
				CONNECTOR_NAME_DEFAULT: 'Connector'
			},
			CONNECTOR_TYPE: {
				GENERAL: {
					NAME: 'Name',
					SHORT_NAME: 'Short name',
					TITLE_FORM_ADD: 'Create new connector type',
					TITLE_FORM_EDIT: 'Edit connector type',
					IMAGE: 'Image',
					CONNECTOR_TYPE: 'connector type'
				}
			},
			SENSOR: {
				GENERAL: {
					TITLE_FORM_EDIT: 'Edit sensor',
					SENSOR_TYPE: 'Sensor type',
					SENSOR_NAME: 'Sensor name',
					SENSOR: 'sensor',
					UPDATE_SENSOR_FAILED: 'Sensor update failed!',
					UPDATE_SENSOR_NAME: 'Update sensor',
					ADD_SENSOR_NAME: 'Create new sensor',
					DELETE_SENSOR_NAME: 'Delete sensor',
					KEY_LANGUAGE: 'Key language',
				},
				MESSAGE: {
					UPDATE_SENSOR_SUCCESS: 'Sensor update successfully',
					ADD_SENSOR_SUCCESS: 'New sensor successfully created',
					DELETE_SENSOR_SUCCESS: 'Sensor successfully deleted',
					UPDATE_SENSOR_FAILED: 'Sensor update failed!',
					ADD_SENSOR_FAILED: 'Adding new sensor failed!',
					DELETE_SENSOR_FAILED: 'Failed to delete sensor!',
					SENSORTPL__NAME_NOT_VALID: 'Invalid sensor name',
					SENSORTPL__ALREADY_EXIST: 'Sensor name already exists',
				},
			},
			DEVICE_ICON: {
				GENERAL: {
					TITLE_FORM_EDIT: 'Edit device icon',
					TITLE_FORM_ADD: 'Add device icon',
					NAME_KEY: 'Key name',
					ICON: 'Icon',
					ICON_SVG: 'Symbols (SVG)',
					ICON_ON_MAP_SVG: 'Map icon (SVG)',
					COUNT: 'Order',
					ICON_ON_MAP: 'Map icon',
					ICONTYPE_NAME: 'Device icon name',
					ICONTYPE: 'device icon',
				},
				MESSAGE: {
					UPDATE_ICONTYPE_SUCCESS: 'Icon update successful',
					ADD_ICONTYPE_SUCCESS: 'New icon creation success',
					DELETE_ICONTYPE_SUCCESS: 'Successfully deleted the icon',
					UPDATE_ICONTYPE_FAILED: 'Icon update failed!',
					ADD_ICONTYPE_FAILED: 'Fail to add new symbol!',
					DELETE_ICONTYPE_FAILED: 'Delete icon failed!',
				},
			},
			TRANSPORT_TYPE: {
				GENERAL: {
					TRANSPORT_TYPE: 'Transport type',
					TITLE_FORM_EDIT: 'Edit transport type',
					TITLE_FORM_ADD: 'Add transport type',
					DRAG_DROP: 'Drag and drop to organize',
				},
				COLUMN: {
					DEVICE: 'Equipment',
					LIMITSPEDD: 'Speed limit',
					DEVICE_TYPE: 'Media type',
					QNCN_CODE: 'QCVN - Type of operation',
				},
			},
			TRANSPORT_TYPE_QCVN: {
				GENERAL: {
					TRANSPORT_TYPE_QCVN: 'type of transport QCVN',
					TITLE_FORM_EDIT: 'Edit QCVN type of transport',
					TITLE_FORM_ADD: 'Add QCVN type of transport',
				},
				COLUMN: {
					DEVICE: 'Equipment',
					LIMITSPEDD: 'Speed limit',
					DEVICE_TYPE: 'Media type',
					QNCN_CODE: 'QCVN - Type of operation',
				},
			},
			LOGIN_PAGE: {
				GENERAL: {
					TITLE: 'Manage login page',
					TITLE_ADD: 'add login page',
					TITLE_FORM_EDIT: 'Edit login page',
					TITLE_FORM_ADD: 'Add login page',
					CHOOSE_THEME: 'Choose theme',
					LOGIN_PAGE: 'Login page',
					DELETE_FORM_EDIT: 'Delete login page',
				},
				COLUMN: {
					HOST: 'Host',
					WEBSITE: 'Website',
					COMPANY_NAME: 'Company name',
					THEME: 'Theme',
					LOGO: 'Logo',
					IOS: 'IOS app',
					ANDROID: 'Android app',
					ICON: 'Icon',
				},
			},
			DEVICETYPE: {
				GENERAL: {
					TITLE_CROP_PICTURE: 'Edit picture',
					COMMAND: 'command',
				},
				COLUMN: {
					MANUFACTURER: 'Manufacturer',
					PROTOCOL: 'Protocol',
					MODEL_NAME: 'Model name',
					LINK: 'Link',
					IMAGE: 'Image',
					PARAMETER_SENSOR: 'IO Setting',
					EXTENSION: 'Extension',
					MODEL: 'Model',
				},
			},
			SYSTEM_LOG: {
				GENERAL: {
					TITLE_FORM_EDIT: 'Edit version',
					TITLE_FORM_ADD: 'Add version',
					VERSION: 'Version',
				},
				COLUMN: {
					VERSION_NAME: 'Version name',
					VERSION_TYPE: 'Version type',
					CONTENT: 'Content',
				},
			},
			POI_TYPE: {
				GENERAL: {
					TITLE_FORM_EDIT: 'Edit Landmarks type',
					TITLE_FORM_ADD: 'Add Landmarks type',
					VERSION: 'Version',
					POI: 'Landmarks type',
					KEY: 'Key language',
				},
				LANGUAGE: {
					OTHER: 'Other',
					RESTAURANT: 'Restaurant',
					STADIUM: 'Stadium',
					SCHOOL: 'School',
					HOSPITAL: 'Hospital',
					SUPERMARKET: 'Supermarket',
					COMPANY: 'Company',
					HOME: 'Home',
				},
			},
			TOLL_STATION: {
				COLUMN: {
					TYPE_GEOFENCE: 'Type geofence',
					CITY: 'Province',
					OPTION: 'Highway',
					STANDARD: 'Standard',
					NAME: 'toll station',
					TYPE_STATION: 'Type',
					CHOISE_CITY: 'Select province',
					BEGIN_WORK: 'Start date',
					END_WORK: 'End date',
					PRICE: 'Price',
					TYPE_DEVICE: 'Device type',
					CIRCLE: 'Circle',
					RECTANGLE: 'Rectangle',
					TAB_PRICE: 'Price list',
					CURRENCY_UNIT: 'Currency unit',
					COUNTRY: 'Country',
					SELECT_COUNTRY: 'Select country',
				},
				MESSAGE: {
					TAP_DEVICE: 'Click to see all types of transport',
					TAP_CURRENCY: 'Click change currency',
					CREATE_TOLL_ONMAP: 'Please create toll station on map!',
					PlEASE_COUNTRY: 'Please select province',
					PLEASE_ROAD: 'Please select road',
				},
			},

			TOLL_ROAD: {
				GENERAL: {
					TITLE_FORM_EDIT: 'Edit toll road',
					TITLE_FORM_ADD: 'Add toll road',
				},
				COLUMN: {
					PROVINCE_ROAD: 'Province road',
					HIGHWAY: 'High way',
					NATIONAL_ROAD: 'National road',
				},
			},

			TOLL_SEGMENT: {
				COLUMN: {
					ROAD: 'Road',
					START_TOLL: 'Entry station',
					END_TOLL: 'Exit station',
					IS_WAY: 'Two way',
					SELECT_ROAD: 'Select road',
					SELECT_TOLL_STATION: 'Select toll station',
					LIST_TOLL: 'Toll stations',
					LIST_TOLLED: 'Toll station selected',
				},
				GENERAL: {
					TITLE_FORM_EDIT: 'Edit toll segment',
					TITLE_FORM_ADD: 'Add toll segment',
				},
				MESSAGE: {
					PLEASE_TOLL_STATION: 'Please select toll station',
				},
			},

			FEEDBACK: {
				GENERAL: {
					FEEDBACK: 'Feedback',
					FEEDBACK_MANAGE: 'Feedback management',
					FEEDBACK_INFO: 'Feedback info',
					UPDATE_FEEDBACK: 'Update feedback',
				},
			},
			STORAGE: {
				GENERAL: {
					FTP_SERVER: "FTP Server",
					MINIO_SERVER: "Minio Server",
					STORAGE_GROUP: "Storage group",
					MINIO_ACCESS_KEY: "Minio access key",
					MINIO_SECRET_KEY: "Minio secret key",
					MINIO_BUCKET_NAME: "Bucket name",
					API_ADDRESS: "API address",
					USERNAME: "USERNAME",
					PASSWORD: "PASSWORD",
					TITLE_FORM_ADD: "Add storage group",
					TITLE_FORM_EDIT: "Edit storage group"
				}
			},
			NOTIFI_GLOBAL: {
				GENERAL: {
					TIME: 'Active date range',
					NAME: 'Notices',
					TITLE_FORM_EDIT: 'Detail notice',
					TITLE_FORM_ADD: 'Create notice',
					VERSION: 'Version',
					DELETE: 'Delete notice',
				},
			},
			SYSTEM: {
				NAME: 'Name',
				CODE: 'Code',
				USERNAME: 'Account',
				ACCOUNT_NAME: 'Account name',
				BANK_ACCOUNT: 'Account number',
				BANK_NAME: 'Bank name',
				TITLE: 'System',
				SEARCH: 'Search (name, code)',
				TITLE_FORM_ADD: 'Create system',
				TITLE_FORM_EDIT: 'Edit system',
				TYPE: "Type",
				CALL_BACK: "API address",
				API_KEY: "API key",
				DEVICE_CONTROL_URL: "Device control url"
			}
		},
		MANAGE: {
			GENERAL: {
				CHARGE_STATION: 'charge station',
				DEVICE: 'device',
				USER: 'user',
				GROUP_DEVICE: 'device group',
				COMMAND: 'send command',
				USER_ALIAS: 'user alias',
				ROLE_ALIAS: 'role alias',
				PROFILE: 'profile',
				PROFILE_UPERCASE: 'Profile',
				MAP_API_KEY: 'map API key',
				PERMISSION: 'permission',
				ROLE: 'role',
				CONNECTOR: 'connector',
				BANK: 'bank',
				TERMINAL: 'Business Unit',
				USER_INFO: 'Profile',
				VOUCHER: 'Voucher'
			},
			DEVICE: {
				STATUS: {
					NOT_ACTIVE: 'Not active',
					ACTIVE: 'Active',
					EXPIRED: 'Expired'
				},
				SELL: {
					GENERAL: {
						SELL_DEVICE: 'Move / Sell',
						TOTAL_DEVICE: 'Total devices',
						SELL_TO: 'Move to',
					},
				},
				SEND_COMMAND: {
					GENERAL: {
						SEND_COMMAND: 'Send command',
						DEVICE_TYPE: 'Device type',
						CHOOSE_DEVICE_TYPE: 'Select device type',
						COMMAND_TYPE: 'Command type',
						CHOOSE_COMMAND_TYPE: 'Choose command type',
						COMMAND_SEND: 'Send command',
						TOTAL_DEVICE: 'Total devices',
						COMMAND_NAME: 'Command name',
						IMEI_DEVICE: 'Command name',
					},
				},
				RENEWS: {
					GENERAL: {
						RENEWS: 'Renews',
						USER_RENEWS: 'Renews accounts',
						RENEWS_TO_DAY: 'Renews to date',
						TOTAL: 'Sum',
						DESCRIPTIONS: 'Descriptions',
						PAY: 'Pay',
						RENEWS_CONFIRM: 'Confirm Renewal',
						RENEWS_MESSAGE:
							'Are you sure you want to extend the device?',
						AMOUNT: 'Amount',
						USED_OLD_SERVICE_TERM: 'Old term of service used',
						MONTH:"Month",
						PRICE: 'Price (VNĐ/Month)'
					},
					VALIDATION: {
						POINTS_NOT_ENOUGH: 'Not enough points to renew',
						NO_DEVICES_HAVE_BEEN_SELECTED: 'No devices selected',
						NO_DEVICES_HAVE_BEEN_RENEWED:
							'No devices have been renewed',
					},
					MESSAGE: {
						NOTE_1: 'Extension of service - 1 month with 1 point',
						NOTE_2:
							'The service date will be counted from the current date or added if it is still valid',
						NOTE_3:
							'If the device has no warranty enabled, it will automatically be activated',
						DEVICE_ERROR: 'Invalid device',
					},
					PAY: {
						UNKNOWN: 'Unknown',
						PAID: 'Paid',
						UNPAID: 'Unpaid',
					},
					MONTHS: {
						ADD_1_MONTH: '1 month',
						ADD_6_MONTHS: '6 months',
						ADD_1_YEAR: '1 year',
						ADD_2_YEARS: '2 years',
						ADD_3_YEARS: '3 years',
					},
				},
				IMPORT: {
					GENERAL: {
						IMPORT_DEVICE: 'Add device',
						DOWNLOAD_TEMPLATE: 'Download template',
						CHOOSE_DOCUMENT: 'Select document',
						ADD_IMEI: 'Add imei',
						RESET_IMEI: 'Reset imei',
						REMOVE_DATA: 'Clear data',
						DEVICE_TYPE: 'Device type',
						CHOOSE_DEVICE_TYPE: 'Select device type',
						CHOOSE_ICON: 'Choose an icon',
						ICON: 'Icon',
						TOTAL_IMEI: 'Total imei',
						IMEI: 'Imei',
						UPLOAD_FILE: 'Upload file'
					},
					VALIDATION: {},
					MESSAGE: {
						NOTE_1: 'Add device by data form or import to imei',
						NOTE_2: 'Imei is about 6-20 characters long',
					},
				},
				EDIT: {
					GENERAL: {
						EDIT_DEVICE: 'Edit device',
						BASE: 'Basic',
						ADDVANCED: 'Advanced',
						EXTENSION: 'Extension',
						SENSOR: 'Sensor',
						INFO_DEVICE: 'Device information',
						IMEI: 'Imei',
						USER_NAME: 'Account',
						MACHINE_CODE__NUMBER_PLATE:
							'Machine code - license plate',
						NAME_DEVICE: 'Device name',
						ICON: 'Icon',
						CHOOSE_ICON: 'Choose an icon',
						GROUP_DEVICE: 'Device group',
						CHOOSE_GROUP_DEVICE: 'Select device groups',
						ADD_DEVICE_GROUP: 'Add device group',
						SIM_NO: 'SIM number',
						SIM_TYPE: 'SIM type',
						CHOOSE_SIM_TYPE: 'Choose sim type',
						DEVICE_TYPE: 'Device type',
						CHOOSE_DEVICE_TYPE: 'Select device type',
						SERVICE: 'Services',
						SERVICE_EXPIRE: 'Service term',
						ACTIVE_WARRANTY: 'Activate warranty',
						ACTIVE_WARRANTY_DATE: 'Warranty activation date',
						WARRANTY_EXPIRE: 'Warranty period',
						STATUS: 'Status',
						NOTE: 'Notes',
						NOTE_ADMIN: 'Notes (manager)',
						SENSOR_NAME: 'Sensor name',
						SENSOR_TEMPLATE: 'Sensor type',
						CHOOSE_SENSOR_TEMPLATE: 'Select sensor pattern',
						PARAMETER_NAME: 'Input',
						CHOOSE_PARAMETER_NAME: 'Choose a parameter',
						UNIT: 'Unit',
						OFFSET: 'Offset',
						FORMULA: 'Formula',
						CALIBRATION: 'Calibration',
						ROUND: 'Round',
						MIN: 'Minimum',
						MAX: 'Maximum',
						INCREASE: 'Increase minimum',
						DECREASE: 'Decrease minimum',
						SORT_ORDER: 'Sort',
						DESCRIPTION: 'Description',
						SHOW_CHART_ON_MAP: 'Show map on map',
						INFO_VEHICEL: 'Vehicle information',
						NUMBER_PLATE: 'License plate',
						FRAME_NUMBER: 'Frame number',
						VIN_NUMBER: 'VIN number',
						TRANSPORT_TYPE: 'Type of transport',
						CHOOSE_TRANSPORT_TYPE: 'Choose transport type',
						DRIVER: 'Driver',
						CHOOSE_DRIVER: 'Choose to driver',
						DISTANCE_OFFSET: 'Distance factor',
						DEPARMENT: 'Ministry of Transportation ',
						SEND_TO_DEPARMENT: 'Send data',
						DEPARMENT_MANAGE: 'Province',
						CHOOSE_DEPARMENT_MANAGE: 'Choose a manager',
						TRANSPORT_TYPE_QCVN: 'Transport type QCVN',
						CHOOSE_TRANSPORT_TYPE_QCVN:
							'Choose transport type QCVN',
						ADD_DRIVER: 'Add driver',
						NOTE_NUMBER_PLATE:
							'Please enter the license plate number as required.',
						ENGINE_NUMBER: 'Phone number',
						SELL_INFO: 'Sell info',
						SELLED: 'SOLD',
						SELL_DATE: 'Sell date',
						UPDATE_TIME: 'Updated time',
						SENSOR_TYPE: 'Sensor type',
						CALCULATION_METHOD: 'Calculation method',
						CHOOSE_CALCULATION_METHOD: 'Choose calculation method',
						NOTE_MINUTE: 'Minutes',
						EXTENSION_CONFIG: 'Config',
						EXTENSION_FUEL: 'Fuel',
						EXTENSION_ADVANCED_FEATURES: 'Advanced Features',
						CONSUMPTION_RATE: 'Consumption rate',
						TIMEOUT_NUMBER: 'Timeout GPRS',
						MINUTE: 'minute',
						SECOND: 'second',
						LITRE: 'Litre',
						MIN_SPEED: 'Min speed filter',
						DEBUG_MODE: 'Record raw data',
						CAMERA: 'Camera',
						TOLL_STATION: 'Toll station',
						TIMEZONE: 'Time zone',
						MIN_STOP_DURATION: 'Min stop duration',
						MIN_STOP_DURATION_RANGE_PICKER:
							'Please enter more than 5s and less than 60 minutes',
						CORRIDOR: 'Corridor',
						DEVICE_ID_OEM: 'Device id oem',
						ID: "ID",
						GROUP_CAMERA: 'Camera group',
						LOAD_GROUP_CAMERA: 'Load group camera',
						LIVESTREAM: 'Livestream',
						IMEI_OEM: "Imei oem",
						STORAGE_GROUP: 'Storage group(Image)',
						PROVINCE: 'City/Province',
						DISTRICT: 'District',
						VEHICLE_TYPE: 'Charging type',
						ELECTRIC_TYPE: 'Electric type',
						SERVICE_PRICE: 'Service price',
						DESCRIPTION_PRICE: 'Price description',
						WATTAGE: 'Wattage (kWh)',
						DESCRIPTION_OPERATING_VOLTAGE: 'Description of operating voltage',
						DESCRIPTION_CONNECTOR_WATTAGE: 'Description of charging port capacity',
						SUPPORT_PHONE_NUMBER: 'Support phone number',
						OPEN_247: 'Open 24/7',
						CONNECTOR: 'Connector',
						WATTAGE_FILTER: 'Wattage',
						PAYMENT_SIM: 'Payment sim',
						AMENITIES: 'Amenities',
						TERMINAL: 'Business point',
						BANK_ACCOUNT: 'Bank account',
						PULSE: 'The amount corresponds to 1 pulse',
						SYSTEM: "System",
						TRAN_SYNC: "Transaction synchronization",
						DEVICE_CONTROL: "Device control",
						CALL_BACK: "API address",
						API_KEY: "API key"
					},
					MESSAGE: {
						NOTE_1: 'Add device by data form or import to imei',
						NOTE_2: 'Imei is about 6-20 characters long',
					},
				},
				GENERAL: {
					DEVICE_HAVE_NO_DATA_PLAYBACK: 'Device has no data',
					DEVICE_NOT_ONLINE: 'Device is not online',
					PLEASE_SELECT_TIME: 'Please select time',
					TIME: 'Time',
					LIST_DEVICE: 'Device list',
					DEVICE: 'Equipment',
					IMEI: 'Imei',
					DEVICE_NAME: 'Device name',
					DEVICE_TYPE: 'Device type',
					SUB_ACCOUNT: 'Child account',
					MACHINE_CODE__VEHICLE_PLATE: 'Machine code / license plate',
					SIMNO: 'SIM number',
					SIM_TYPE: 'SIM type',
					ACTIVE_DATE: 'Activation time',
					ACTIVE_WARRANTY: 'Activate warranty',
					SERVICE_EXPIRE: 'Service term',
					WARRANTY_EXPIRE: 'Warranty period',
					TIME_START: 'Start time',
					TIME_END: 'End time',
					EXPIRE_SERVICE: 'Service term',
					EXPIRE_SOON: 'Almost expired',
					EXPIRED: 'Expired',
					SERVICE_IS_AVAIABLE: 'Active',
					STATUS: 'Status',
					INACTIVE: 'Not activated',
					ACTIVE: 'Activate',
					EXPORT: 'Export data',
					SELL_DEVICE: 'Move / Sell',
					DISABLE: 'Disable',
					ENABLE: 'Activate',
					SEND_COMMAND: 'Send command',
					IMPORT_DEVICE: 'Add device',
					RENEWS: 'Renewal',
					ADD_DEVICE_GROUP: 'Add device group',
					ADD_DRIVER: 'Add driver',
					DROP_DRAP_SORT_ORDER: 'Drag and drop to organize devices',
					SORT_ORDER: 'Sort',
					ADDRESS: 'Address',
					PAYMENT_SIM: "Payment sim"
				},
				MESSAGE: {
					NO_DEVICE_SELECTED: 'No devices have been selected',
					CHOICE_PERMISSIONS: 'Select permissions',
				},
			},
			USER: {
				GENERAL: {
					ROLE_NAME: 'Role name',
					FULL_NAME: 'Full name',
					PHONE: 'Phone number',
					EMAIL: 'Email',
					ACCOUNT_TYPE: 'User type',
					PARENT_USER: 'Parent account',
					USERNAME_OR_NAME: 'Account or name',
					NEW_PASSWORD: 'New password',
					OLD_PASSWORD: 'Old password',
					COMFIRM_PASSWORD: 'Confirm password',
					CHANGE_PASSWORD: 'Change password',
					START_TIME: 'Start time',
					END_TIME: 'End time',
					PAGE_MAIN: 'Page main',
					SUB_ACCOUNT: 'Include sub account',
					CUSTOMER: 'Customer'
				},
				MESSAGE: {
					PASS_MIN_CHAR: 'Password must be greater than 6 characters',
					PASSWORD_INCORRECT: 'The passwords do not match',
					ADD_USER_FAILED: 'Adding new user failed!',
					UPDATE_USER_FAILED: 'The user update failed!',
					DELETE_USER_FAILED: 'Delete user was not successful!',
					UPDATE_USER_SUCCESS: 'Update user successful!',
					ADD_USER_SUCCESS: 'New user successfully added!',
					DELETE_USER_SUCCESS: 'Delete user successfully!',
					RESET_PASSWORD_SUCCESS: 'Password reset succeeded!',
					RESET_PASSWORD_FAILED: 'Password reset failed!',
					USER__DELETED_FAIL_HAVE_CHILD: 'User has child accounts',
					USER__DELETED_FAIL_HAVE_DEVICE: 'User has device',
					USER__UPDATED_FAIL_PARENTID:
						'Unable to update parent account',
					USER__UPDATED_FAIL_NOT_CHILD:
						'No rights to update accounts other than child accounts',
					USER__UPDATED_ROLE_NOT_VALID:
						'No permission to assign Admin roles',
					USER__UPDATED_ROLE_NOT_FOUND: 'Unable to update user',
					USER__PARENTID_NOT_VALID:
						'Unable to select child accounts as a parent',
					UPDATE_ICONTYPE_FAILED: 'Device icon update failed!',
					UPDATE_ICONTYPE_NAME: 'Update device icon',
				},
				PARAMETER: {
					TIMEZONE: 'Time zone',
					LANGUAGE: 'Language',
					DISTANCE: 'Distance',
					VOLUME: 'Volume unit',
					TEMPERATURE: 'Temperature unit',
					WEIGHT: 'Weight unit',
					DATE_FORMAT: 'Date / month',
					TIME_FORMAT: 'Time',
					DECIMAL_SEPARATOR: 'Decimal',
					FIRST_WEEKDAY: 'First day of the week',
					DISTRIBUTOR: 'Distributor',
					USER: 'User',
					USER_ONLY_MOTORBIKE: 'User(Only motorbike)',
					PARTNER: 'Partner'
				},

				NOTIFICATIONS: {
					MOVEMEMT: 'Move',
					SCHEDULE: 'Schedule',
					FUEL_DRIVING: 'Fuel and driving',
					SAFETY: 'Safe',
					INPUTS_OUTPUTS: 'Access',
					Device: 'Device',
					BATTERY_LOW: 'The battery is low',
					EXTERNAL_POWER_CUT: 'External power cut',
					SOS: 'SOS',
					OVER_SPEED: 'Over speed',
					GEOFENCE_IN: 'Enter geofence',
					GEOFENCE_OUT: 'Exit geofence',
					NONE: 'Information',
					SHOCK: 'Shock',
					EXTERNAL_POWER_WEAK: 'External power is weak',
					OVERSPEED: 'Over speed',
					EXTERNAL_POWER_CONNECTED: 'External power connected',
					MOVING: 'Moving',
					DOOR_OPEN: 'Door open',
					DOOR_CLOSE: 'Door close',
					STOP_OVERTIME: 'Stop overtime',
					BATTERY_HIGHT: 'Battery is full',
					EXTERNAL_POWER_LOW: 'External power is low',
					EXTERNAL_POWER_HIGHT: 'External power exceeds',
					VIN_NUMBER_CHANGE: 'VIN number change',
					NOTIFICATION: 'Notification',
					TYPE: 'Type',
					ACTIVE: 'Active',
					INACTIVE: 'Inactive',
					MAP: 'Map',
					GEOFENCE: 'Geofence',
					FAVORITE: 'Favorite',
					DEVICE: 'Device',

					// SOS: 'Call for help',
					// BATTERY_LOW: 'The battery is low',
					// EXTERNAL_POWER_CUT: 'Disable external power',
					// GEOFENCE_IN: 'Enter geofence',
					// GEOFENCE_OUT: 'Exit geofence',
					VIBRATION: 'Vibration',

					// OVER_SPEED: 'Exceeded speed',
					// EXTERNAL_POWER_WEAK: 'External power is weak',
					// NONE: 'Information',
					// SHOCK: 'Alert Shock',
				},
			},
			COMMAND: {
				GENERAL: {
					SENDED: 'Sent',
					TiME_SEND: 'Time to send',
					RETURNED: 'Returned',
					TIME_RETURN: 'Return time',
					CONTENT_RETURN: 'Content returned',
					COMMAND: 'Send command',
					RESPONSED: 'Returned',
					TIME_RESPONSE: 'Return time',
					CONTENT_RESPONSE: 'Content returned',
					TITLE_FORM_ADD: 'Add submit command',
					TITLE_FORM_DETAIL: 'Order details',
					DEVICE_INFO: 'Device',
					SEND_INFO: 'Send',
					RESPONSE_INFO: 'Response',
				},
				MESSAGE: {},
			},
			DEVICE_GROUP: {
				GENERAL: {
					TITLE_FORM_EDIT: 'Edit device group',
					TITLE_FORM_ADD: 'Add device group',
					DEVICE_GROUP: 'Device group',
					ADD_DEVICE_GROUP: 'Add device group',
					GROUP_NAME: 'Group name',
				},
				COLUMN: {
					COUNT_DEVICE: 'Number of devices',
				},
			},
			USER_ALIAS: {
				TITLE_FORM_EDIT: 'Edit user alias',
				TITLE_FORM_ADD: 'Add user alias',
				GENERAL: {
					BASE: 'Base',
					DEVICES: 'Devices',
				},
			},
			ROLE_ALIAS: {
				TITLE_FORM_EDIT: 'Edit role alias',
				TITLE_FORM_ADD: 'Add role alias',
			},
			ALERT_RULE: {
				GENERAL: {
					ALERT_RULE: 'Alert rules',
					MANAGE_ALERT: 'Alert rules',
					ALERT: 'alert',
					NAME_ALERT: 'Alert name',
					TYPE_ALERT: 'Alert type',
					NOTIFICATION_TEXT: 'Text mess',
					GEOFENCE: 'Geofence',
					NOTIFICATION: 'Notifications',
					TITLE_FORM_EDIT: 'Edit alert',
					TITLE_FORM_ADD: 'Add new alert',
					DEVICE: 'Device',
					ALWAYS_SELECT_ALL_DEVICE: 'Always select all devices',
					ALWAYS_SELECT_ALL_GEOFENCE: 'Always select all geofence',
					ALWAYS_SELECT_ALL_SENSOR: 'Always select all sensors',
					SENSOR: 'Sensor'
				},
				TYPE_GROUP_ALERT: {
					MOVEMEMT: 'Move',
					SCHEDULE: 'Schedule',
					FUEL_DRIVING: 'Fuel and driving',
					SAFETY: 'Safe',
					INPUTS_OUTPUTS: 'Access',
					DEVICE: 'Device',
				},
				PARAMS_VALUE: {
					OVER_SPEED: 'Over speed',
				},
				NOTIFICATION: {
					CONTENT: 'Content to receive notifications',
					TIME_NOTIFI: 'Time to receive notification',
					DAY: 'Day',
					TO: 'To',
					FROM: 'From',
					TIME: 'Time',
					OPERATION: 'Active',
					OPERATION_ALWAYS: 'Always active',
					OPERATION_TIME: 'Activity in range',
					FROM_DAY: 'From date',
					TO_DAY: 'To date',
					SMS: 'SMS Notification',
					SMS_LABEL: '(List of phones separated by "", or ";")',
					EMAIL: 'Email notification',
					EMAIL_LABEL: '(Email list separated by "", or ";")',
					PUSH: 'Receive notifications',
					PUSH_TOOLTIP: 'Get notified via mobile app',
					EMERGENCY: 'Emergency notification',
					EMERGENCY_TOOLTIP: 'Urgent notice',
					NOTIFICATION: 'Notifications',
					NO_FOUND_NOTI: 'No new notifications',
					MARK_ALL_READ: 'Mark all as read',
					VIEW_ALL: 'View all',
					MARK_AS_READ: 'Mark as read',
					MARK_AS_UNREAD: 'Mark as unread',
					CLICK_HERE_FOR_VIEW_DETAIL: 'Click here for view detail',
					SEE_MORE: 'See more',
					TIME_RANGE: 'Time range',
				},
				GEOFENCE: {
					OUT: 'Out geofence',
					IN: 'In geofence',
					NONE: 'Ignore',
				},
				TYPE_ALERT: {
					MOVEMEMT: 'Move',
					SCHEDULE: 'Schedule',
					FUEL_DRIVING: 'Fuel and driving',
					SAFETY: 'Safe',
					INPUTS_OUTPUTS: 'Access',
					Device: 'Device',
					BATTERY_LOW: 'The battery is low',
					EXTERNAL_POWER_CUT: 'External power cut',
					SOS: 'SOS',
					OVER_SPEED: 'Over speed',
					GEOFENCE_IN: 'Enter geofence',
					GEOFENCE_OUT: 'Exit geofence',
					NONE: 'Information',
					SHOCK: 'Shock',
					EXTERNAL_POWER_WEAK: 'External power is weak',
					OVERSPEED: 'Over speed',
					EXTERNAL_POWER_CONNECTED: 'External power connected',
					EXTERNAL_POWER_CONNECT: 'External power connected',
					MOVING: 'Moving',
					DOOR_OPEN: 'Door open',
					DOOR_CLOSE: 'Door close',
					STOP_OVERTIME: 'Stop overtime',
					BATTERY_HIGHT: 'Battery is full',
					EXTERNAL_POWER_LOW: 'External power is low',
					EXTERNAL_POWER_HIGHT: 'External power exceeds',
					EXTERNAL_POWER_HIGH: 'External power exceeds',
					VIN_NUMBER_CHANGE: 'VIN number change',
					TRIP_DEVIATION: 'Trip deviation',
					THIEVES_FUEL: 'Thieves fuel',
					COMMING_GEOFENCE: 'Comming geofence',
					DISTANCE: 'Distance alert',
					LOST_FUEL: 'Remove fuel',
					TASK_NORMAL: 'Remind',
					MAINTENANCE_BY_DISTANCE: 'Maintenance by distance',
					MAINTENANCE_BY_HOURS: 'Maintenance by hours',
					CAMERA_COVER_ALARM: 'Camera cover alarm',
					IO1: 'IO1',
					IO2: 'IO2',
					IO3: 'IO3',
					IO4: 'IO4',
					IO5: 'IO5',
					IO6: 'IO6',
					IO7: 'IO7',
					IO8: 'IO8',
					IO9: 'IO9',
					IO10: 'IO10',
					IO11: 'IO11',
					IO12: 'IO12',
					IO13: 'IO13',
					IO14: 'IO14',
					IO15: 'IO15',
					IO16: 'IO16',
					EMERGENCY_BUTTON_ALARM: 'Emergency button alarm',
					ACC_ALARM: 'ACC alarm',
					ENTER_OR_EXIT_FENCE: 'Enter or exist fence',
					EYES_CLOSED: 'Eyes closed',
					COLLISION_ALARM: 'Collision alarm',
					DRIVER_FATIGUE: 'Driver fatigue',
					NO_DRIVER: 'No driver',
					PHONE_DETECTION: 'Phone detection',
					SMOKING: 'Smoking',
					DISTRACTION: 'Distraction',
					LANE_DEPARTURE: 'Lane Departure',
					CONTINUOUS_OVERSPEED: 'Continuous overspeed',
					DISTANCE_TOO_CLOSE: 'Distance too close',
					PEDESTRIAN_DETECTION: 'Pedestrian detection',
					YAWNING: 'Yawning',
					LEFT_SIDE_BSD: 'Left side BSD',
					RIGHT_SIDE_BSD: 'Right side BSD',
					LINE_FENCE_OVERSPEED: 'Line fence overspeed',
					HARSH_ACCELERATION: 'Harsh acceleration',
					HARSH_BRAKE: 'Harsh brake',
					HARSH_LEFT_TURN: 'Harsh left turn',
					HARSH_RIGHT_TURN: 'Harsh right turn',
					OVERTIME_DRIVING_WARN: 'Overtime Driving Warn',
					OVERTIME_DRIVING_ALARM: 'Overtime Driving Alarm',
					IGNITION: 'Ignition',
					IGNITION_STOP: 'Ignition stop',
					IGNITION_ON: 'Ignition on',
					IGNITION_OFF: 'Ignition off',
					FUEL_LEAK: 'Fuel leak',
					FUEL_ADD: 'Fuel add',
					ENTER_GPS_DEAD: 'Enter gps dead',
					EXIT_GPS_DEAD: 'Exit gps dead',
					TEMPERATURE_HIGH: 'Temperature high',
					TEMPERATURE_LOW: 'Temperature low',
					TEMPERATURE: 'Temperature'
				},
				GROUP_TYPE_ALERT: {
					DEVICE: 'Device',
					ACTION: 'Action',
					IN_OUT: 'In out',
					OBD: 'OBD',
					NORMAL: 'Normal	',
				},
				PARAMS: {
					INTERVAL: 'Repeat later',
					TIME: 'Time',
					SPEED: 'Speed',
					PERCENT: 'Percent',
					VALUE: 'Value',
					POWER_VOL: 'Power voltage',
				},
				DAY: {
					MO: 'Mo',
					TU: 'Tu',
					WE: 'We',
					TH: 'Th',
					FR: 'Fr',
					SA: 'Sa',
					SU: 'Su',
				},
			},
			TASK_REMIND: {
				GENERAL: {
					TASK_REMIND: 'Task remind',
					NORMAL: 'task remind',
					TYPE_TASK: 'Remind type',
					TITLE_FORM_EDIT: 'Edit task remind',
					TITLE_FORM_ADD: 'Add task remind',
					TITLE_FORM_DELETE: 'Delete task remind',
					TIME_ON_DAY: 'Time on day (hh:mm:ss)',
					REPEAT: 'Repeat',
					TIME: 'Receiving time',
				},
				PARAMS_VALUE: {
					ODOMETER: 'Odometer (km)',
					ODOMETER_BEFORE: 'Previous maintenance (km)',
					ODOMETER_AFTER: 'Next maintenance (km)',
					DAY_PASS: 'Recent days',
					DAY_NEXT: 'Next maintenance day',
					TOTAL_HOURS: 'Total hours (H)',
					HOURS_BEFORE: 'Last maintenance (H)',
					HOURS_AFTER: 'Next maintenance (H)',
				},
			},
			ALERT: {
				GENERAL: {
					MESSAGE_CONTENT: 'Content message',
					MANAGE_ALERT: 'Notifications ',
					TITLE_FORM_DETAIL: 'Detail alert',
					READED: 'Readed',
					UNREAD: 'Unread',
					OBJECT: 'Object',
					CLICK_DOWNLOAD: 'Click tap to download',
					GENERATION_DONE: 'Report generation done',
					TYPE_ALERT: 'Notifications type',
				},
			},
			DRIVER: {
				GENERAL: {
					DRIVER: 'driver',
					NAME: 'Name',
					PHONE: 'Phone number',
					LICENSE_NUMBER: 'License number',
					EMAIL: 'Email',
					DESCRIPTION: 'Description',
					BEGIN_DATE: 'Issued date',
					EXPRIE_DATE: 'Expired date',
					DRIVER_CODE: 'Code',
					ADDRESS: 'Address',
					TITLE_FORM_ADD: 'Add driver',
					TITLE_FORM_EDIT: 'Edit driver',
				},
				GROUP: {
					INFO: 'Information',
					NOTE: 'Notes',
					LICENSE: 'License',
				},
			},
			GEOFENCE: {
				GENERAL: {
					NAME: 'Name',
					GEOFENCE: 'geofence',
					GEOFENCE_MANAGE: 'Geofences',
					SEARCH_GEOFENCE: 'Search geofence',
					GEOFENCES: 'Geofences',
				},
				TOOL: {
					INFO_GEOFENCE: 'Geofence information',
					NAME: 'Name',
					DESCRIPTION: 'Description',
					RADIUS: 'Radius',
					LATITUDE: 'Latitude',
					LONGITUDE: 'Longitude',
					COLOR: 'Color',
					OPACITY: 'Opacity',
					FILL_COLOR: 'Fill color',
					FILL_OPACITY: 'Fill opacity',
					SHOW__HIDE_DEVICES: 'On/Off List of devices',
					PLEASE_CREATE_GEOFENCE: 'Please create geofence on map',
					PLEASE_CHOOSE_GEOFENCE: 'Please select geofence group'
				},
			},
			GEOFENCE_GROUP: {
				GENERAL: {
					NAME: 'Name',
					GEOFENCE_GROUP: 'geofence group',
					TITLE_FORM_EDIT: 'Edit geofence group',
					TITLE_FORM_ADD: 'Add new geofence group',
					COUNT: 'Count',
					CHOISE: 'Select group',
				},
			},
			POI: {
				GENERAL: {
					NAME: 'landmarks',
					TYPE: 'Landmarks type',
					TYPE_POI: 'Select type',
					ACTIVE: 'Active',
					INACTIVE: 'Inactive',
				},
				MESSAGE: {
					NOT_MARKET: 'Please create Landmarks on map',
					NOT_TYPE: 'Please select Landmarks type',
				},
			},
			REPORT_SCHEDULE: {
				GENERAL: {
					REPORT_SCHEDULE: 'scheduled report',
					TITLE_FORM_EDIT: 'Edit Scheduled report',
					TITLE_FORM_ADD: 'Add Scheduled report',
					TITLE_FORM_DELETE: 'Delete Scheduled report',
					SELECT_REPORTS: 'Select reports',
					GEOFENCE_NOT_FOUND: 'Geofence not found'
				},
				COLUMN: {
					NAME: 'Name',
					TYPE: 'Report type',
					FORMAT: 'Format',
					DEVICE: 'Device',
					SCHEDULE: 'Scheduled',
					SEND_TO_EMAIL: 'Send to email',
					DAILY: 'Daily',
					WEEKLY: 'Weekly',
					DAILY_WEEKLY: 'Daily, weekly',
				},
				MESSAGE: {
					TYPE_REPORT: 'Please select report type',
					WEEKLY_MESSAGE: 'Report will be sent every Monday',
				},
			},
			ACTIVITY_LOG: {
				GENERAL: {
					BASIC: 'Platform',
					USER_AGENT: 'Login',
					ACTION_TYPE: 'Action type',
					OBJECT_TYPE: 'Object type',
					OBJECT_NAME: 'Object name',
					EVENT_DESC: 'Event description',
					DETAIL: 'Details',
				},
				ACTIONS: {
					DELETE: 'Delete',
					UPDATE: 'Update',
					EXTEND: 'Extend',
					MOVE: 'Move',
					EXTENSION: 'Extension',
					CHANGE_STATUS: 'Change status',
					SELL: 'Sell',
					SORT_ORDER: 'Sort order',
					UPDATE_PROFILE: 'Update profile',
					UPDATE_PATH_CHILD: 'Update path child',
					LOGIN: 'Login',
					CREATE: 'Create',
					CREATE_ALIAS: 'Create new alias',
					UPDATE_ALIAS: 'Update alias',
					DELETE_ALIAS: 'Delete alias',
					STREAM_CAMERA: 'Camera stream',
					VIEW_CAMERA: 'Camera',
				},
				OBJECT: {
					DEVICES: 'Devices',
					DEVICE_ICON: 'Device icon',
					DEVICE_GROUP: 'Device group',
					PERMISSION: 'Permission',
					CARD: 'Card',
					USER_SESSION: 'User session',
					LOGIN_PAGE: 'Login page',
					USER: 'User',
					SENSOR_TEMPLATE: 'Sensor template',
					DEVICE_TYPES: 'Device Types',
					BASIC: 'Platform',
					USER_AGENT: 'Login',
					ACTION_TYPE: 'Action type',
					OBJECT_TYPE: 'Object type',
					OBJECT_NAME: 'Object name',
					EVENT_DESC: 'Event description',
					ROLES: 'Roles',
					SIM_TYPE: 'SIM type',
					TRANSPORT_TYPE: 'Transport type',
					ROLE_ALIAS: 'Roles alias',
					USER_SETTING: 'User setting',
					POI_TYPE: 'POI type',
					POI: 'Landmarks type',
					TOLL_STATION: 'Toll station',
					LATITUDE: 'Latitude',
					LONGITUDE: 'Longitude',
					CAMERA: 'Camera',
					SENSOR: 'Sensor',
					GEOFENCE: 'Geofence',
					MAP_KEY: 'Map Key',
				},
				EVENT: {
					REMOVE_PERMISSION: 'Remove permission',
					ADD_PERMISSION: 'Add permission',
					FROM: 'from',
					TO: 'to',
					CHANGE: 'Change',
					CREATE: 'Create new',
					DELETE: 'Delete',
					LOGIN: 'login',
					PAGE: 'Page',
				},
			},
			MAP_API_KEY: {
				API_NAME: 'Name',
				API_KEY: 'API key',
				TYPE: 'Type',
				GOOGLE_TYPE: 'Google Map API key',
				API_KEY_OWNER: 'Owner',
				ACTIVE: 'Active',
				INACTIVE: 'Inactive',
				USER_USE: 'User',
				CHOOSE_USER_USE: 'Target user',
				SEARCH_USER_USE: 'Search target user',
				SELECTED_USER_USE: 'User can use',
				DESCRIPTION: 'Description',
				RESET: 'Reset',
				PARENT_ACCOUNT: 'Parent User',
				ALREADY_EXIST_MAP_USERS: 'The selected users already exist',
			},
			HELP: {
				GENERAL: {
					SYSTEM_CHANGE: 'System change log',
					DOCS_PAGE: 'Documents page',
					PRIVATE_POLICY: 'Private policy',
				},
			},
			MESSAGE: {
				COLUMN: {
					TITLE: 'Title',
					COUNT_USER: 'Count user receive',
					CONTENT: 'Content',
				},
				RECEIVE: {
					MESSAGE: 'Message',
					NOT_FOUND: 'Not found message',
					DELETE_ALL: 'Delete all',
					DELETE: 'Delete message',
					DELETE_MESSAGE:
						'Are you sure you want to delete all messages?',
					MARK_ALL: 'Are you sure to mark all messages?',
					MARK: 'Mark message',
				},
				SEND: {
					MESSAGE: 'Send message',
					DETAIL: 'Detail message',
					CUSTOMER: 'Customer',
					SEND_TO: 'Send to',
					CHARACTER: 'Character',
					LIMIT_CHARACTER:
						'The content of the message must not exceed 300 characters.',
				},
			},
			TRIP: {
				GENERAL: {
					TITLE_FORM_ADD: 'Add new trip',
					TITLE_FORM_EDIT: 'Edit trip',
					NAME: 'trip',
				},
				COLUMN: {
					LIST: 'List geofences',
				},
			},
			BOOKING: {
				INFO: 'Information',
				CONFIRM_INFO: 'Confirm information',
				CONFIRM: 'Confirm',
				NEXT: 'Next',
				SUCCESS: 'Booking success',
				FAIL: 'Booking fail',
				NEW: 'New booking',
				RESULT: 'Result',
				CHOOSE_CONNECTOR: 'Choose connector',
				PREVIOUS: 'Previous',
				PICK_DATE: 'Pick date',
				CHARGING_HOURS: 'Charging hours',
				HOUR: 'Hour',
				CHOOSE_TIME_FRAME:'Choose time frame',
				CONNECTOR: 'Connector',
				BOOKED_AT:'Booking date',
				TIME_SLOT:'Time slot',
				CONNECTOR_NAME: 'Connector name',
				WATTAGE:'Wattage (kWh)',
				ELECTRIC_TYPE: 'Electric type',
				VEHICLE_TYPE: 'Charger for vehicles',
				ELECTRIC_PRICE: 'Electric price (VNĐ/kWh)',
				PARKING_PRICE: 'Parking price (VNĐ/h)',
				OCCUPIED_CHARGE_PRICE: 'Occupied charge price (VNĐ/h)',
				ADDRESS: 'Address',
				CHARGE_TIME: 'Charging time',
				PROVISIONAL_COSTS:'Provisional costs (VNĐ)',
				MINUTE: 'Minute',
				CHARGER_NAME: 'Charger name',
				START_TIME: 'Start time',
				END_TIME: 'End time',
				STATUS: 'Status',
				BOOKED: 'Booked',
				CREATED: 'Unconfimred',
				CANCELED: 'Canceled',
				COMPLETED: 'Completed',
				CHARGING: 'Charging',
				EXPIRED: 'Expired',
				COMFIRM_MESSAGE: 'Do you want to confirm this booking?',
				CANCEL_MESSAGE: 'Are you sure you want to cancel this booking?',
				CONFIRM_TITLE: 'Confirm booking',
				CANCEL_TITLE: 'Cancel booking',
				CONNECTOR_TYPE: 'Connector type',
				DETAIL_TITLE: 'Booking detail',
				VALIDATE_TIME_START: 'The start time is outside the selected time frame',
				SELECT_TIME: 'Choose time',
				DEPOSIT: 'Deposit (VNĐ)',
				IMEI: 'Imei'
			},
			RFID_TAG: {
				TAG_ID: 'ID tag',
				TAG_NAME: 'Name tag',
				EXPIRY_DATE: 'Expiry date',
				DESCRIPTION: 'Description',
				USERNAME: 'Ownership account',
				TITLE: 'RFID tags',
				UNKNOWN: 'Unknown',
				COMFIRM_DELETE: 'Are you sure you want to delete this RFID tag?',
				TITLE_FORM_ADD: 'Create RFID tag',
				TITLE_FORM_EDIT: 'Edit RFID tag',
			},
			SERVICE_PRICE: {
				NAME: 'Name',
				ELECTRICITY_PRICE: 'Electricity price',
				PARKING_PRICE: 'Parking price',
				DESCRIPTION: 'Description',
				OCCUPIED_CHARGER_PRICE: 'The price occupies the connector',
				TITLE: 'Service price',
				COMFIRM_DELETE: 'Are you sure you want to remove the price for this service?',
				TITLE_FORM_ADD: 'Create service price',
				TITLE_FORM_EDIT: 'Edit service price',
				TITLE_DELETE: 'Delete service price',
				MINUTE: 'Minute',
				PRICE: 'Price',
				PRICE_BY_TIME: 'Price over time',
				NO_DATA: 'No data',
				DISCOUNT_HOUR: 'Discount hours',
				START_TIME: 'Start time (HH:mm)',
				END_TIME: 'End time (HH:mm)',
				DISCOUNT: 'Discount (%)',
				START_TIME_INVALID: 'The start time is not in the correct format HH:mm',
				END_TIME_INVALID: 'The end time is not in the correct format HH:mm'
			},
			WALLET_TRANSACTION: {
				TRANSACTION_TYPE: 'Transaction type',
				AMOUNT: 'Amount',
				STATUS: 'Status',
				BOOKING: 'Booking',
				SUCCESS: 'Success',
				REFUND: 'Refund',
				FAIL: 'Fail',
				PENDING: 'Pending',
				CANCEL_BOOKING: 'Cancel booking',
				EXTEND: 'Device extension',
				TOP_UP: 'Top up'
			},
			WALLET: {
				TYPE: 'Type',
				USERNAME: 'Username',
				DOMAIN: 'Domain',
				NAME: 'Name',
				PASSWORD: 'Password',
				PORT: 'Port',
				SEARCH_TEXT: 'Search (Name, username, domain)',
				TEXT: 'wallet',
				TITLE_FORM_ADD: 'Create new wallet',
				TITLE_FORM_EDIT: 'Edit wallet'
			},
			BANK: {
				ACCOUNT_NAME: 'Account name',
				BANK_ACCOUNT: 'Bank account',
				BANK_NAME: 'Bank name',
				SEARCH_TEXT: 'Search (Account, Bank)',
				WALLET: 'Wallet',
				TEXT: 'Bank',
				TITLE_FORM_ADD: 'Create new bank',
				TITLE_FORM_EDIT: 'Edit bank',
				TRANS_FEE: 'Transaction fees (%)',
				SECRET_KEY: 'Secret key'
			},
			TERMINAL: {
				NAME: 'Name',
				CODE: 'Code',
				TOTAL_DEVICE: 'Total device',
				TITLE_FORM_ADD: 'Create business unit',
				TITLE_FORM_EDIT: 'Edit business unit'
			},
			VOUCHER: {
				NAME: 'Voucher name',
				PARTNER_NAME: 'Partner name',
				USERNAME: 'Username',
				AMOUNT: 'Surplus (VNĐ)',
				SEARCH_TEXT: 'Search',
				PERSONAL: 'Personal',
				MONEY_TRANSFER_ACCOUNT: 'Money transfer account',
				RECEIVING_ACCOUNT: 'Receiving account',
				TRANSFER_AMOUNT: 'Transfer amount'
			}
		},
		UTILITIES: {
			CARD_SERVICE: {
				GENERAL: {
					TITLE_FORM_ADD: 'Add new code',
					CARD: 'card',
					USED: 'Used',
					NOT_USED: 'Not used',
					STATISTICS: 'Statistics',
					MONTH: 'month',
				},
				COLUMN: {
					NAME_CARD: 'Code',
					DENOMINATIONS: 'Denominations',
					QUANTITY: 'Quantity',
				},
			},
		},
		TECHNICAL_SUPPORT: {
			IMEI: 'IMEI',
			DEBUG: {
				DEBUG: 'Raw log',
				RAW_DATA: 'Raw data',
			},
			SIM_INFO: {
				SENDER: 'Sender',
				DEVICE_NAME: 'Device name',
				NUMBER_PLATE: 'License plate',
				SIM_NO: 'SIM no',
				CONTENT: 'Content',
			},
			DEVICE_LOGIN: {
				SOCKET_VERSION: 'Socket version',
				SOCKET_IP: 'Socket IP',
				PORT: 'Port',
				FIRMWARE_VERSION: 'Firmware version',
				LOGIN_DATA: 'Login data',
			},
			LOST_SIGNAL: {
				TYPE: 'Lost signal type',
				LOST_GPRS: 'GPRS',
				LOST_GPS: 'GPS',
				DEVICE_TYPE: 'Device type',
				DEVICE_NAME: 'Device name',
				TIME_LOST: 'Time',
				TIME_LOST_GPS: 'GPS (minute)',
				TIME_LOST_GPRS: 'GPRS (minute)',
				TYPE_NOT_EMPTY: 'The type is not empty',
				TIME_NOT_EMPTY: 'The time is not empty',
				NUMBER_PLATE: 'Number plate',
			},
		},
		POINTS: {
			MANAGE: {
				GENERAL: {
					POINT_MANAGE: 'Manage points',
					ADD_POINT: 'Add point',
					USER_NAME: 'Account name',
					POINT: 'Point',
					ACCOUNT_INFORMATION_SEND: 'Send account information',
					CURRENT_POINT: 'Current points',
					ACCOUNT_INFORMATION_RECEIVE: 'Account information receive',
					TRANSACTION_INFORMATION: 'Transaction information',
					AMOUNT_POINT: 'Amount points',
					PAY: 'Pay',
					DATE_PAY: 'Date pay',
					BILL_IMG: 'Photo of invoice',
					DESCRIPTION: 'Note',
					TRANSACTION_MANAGE: 'Transaction manage',
					TYPE: 'Type',
					DEVICE_IMEI: 'Device IMEI',
					TRANSACTION_HISTORY: 'Transaction history',
					CANCEL_TRANSACTION: 'Cancel transaction',
					REQUEST_CANCEL_TRANSACTION: 'Request cancel transaction',
					EDIT_TRANSACTION: 'Update transaction',
					DETAIL_TRANSACTION: 'Detail transaction',
					RENEWAL_MANAGE: 'Renewal manage',
					AMOUNT: 'Amount',
					RENEWAL_HISTORY: 'Renewal history',
					TOTAL_DEVICE_RENEWS: 'Total renewal device',
					COUNT_DEVICE_RENEWS: 'Count renewal device',
					ADD_POINTS: 'Add points',
					MOVE_POINTS: 'Move points',
					RENEWS: 'Renewal',
					COUNT_TRANSACTION: 'Count transaction',
				},
				VALIDATE: {
					NOT_ENOUGH_POINTS: 'Current points is not enough',
					TYPE_INVALID: 'Invalid transaction type or status',
					FIRMWARE_VERSION: 'Firmware version',
				},
			},
			TYPE: {
				ADD: 'Add',
				MOVE: 'Move',
				RENEWS: 'Renewal',
				ALL: 'All',
			},
			PAY: {
				UNKNOWN: 'Unknown',
				PAID: 'Paid',
				UNPAID: 'Unpaid',
				CANCEL: 'Cancel',
				REQUEST_CANCEL: 'Request cancel',
			},
			SEARCH: {
				ACCOUNT_OR_NAME: 'Account or name',
				PHONE: 'Phone',
				EMAIL: 'Email',
				SUB_ACCOUNT: 'Sub account',
				CHOOSE_TYPE: 'Choose type',
				ACCOUNT_TRANSACTION: 'Account transaction',
				DATE: 'Date',
				CHOOSE_PAY_STATUS: 'Payment status',
				CHOOSE_SERVER: 'Choose server',
			},
		},
		REPORT: {
			REPORTS: {
				GENERAL: {
					REPORTS: 'Reports',
					SCHEDULE: 'Scheduled report',
					ADD_REPORT: 'Add report',
					REMOVE_REPORT: 'Hide report',
					CHOISE_REPORT: 'Select report',
					HIDE_REPORT: 'Hide',
					REPORT_FAVORITE: 'Favorite reports',
					QCVN: 'Ministry of transport',
					ADD_FAVORITE: 'Add favorite reports',
					REMOVE_FAVORITE: 'Hide favorite reports',
				},
				MESSAGE: {
					MESSEAGE_ADD_SUCSSEC: 'Add successfully',
					MESSEAGE_REMOVE_SUCSSEC: 'Hide successfully',
					MESSAGE_REMOVE: 'Do you want to hide favorite report?',
					DONE: 'Report generation done',
				},
			},
			TRANSACTION_HISTORY: {
				DEVICE_NAME: 'Device name',
				AMOUNT: 'Amount (VNĐ)',
				BANK_ACCOUNT: 'Bank account',
				BANK_NAME: 'Bank name',
				IMEI: 'Device code',
				SYSTEM: 'System',
				TERMINAL_NAME: 'Business unit',
				TIME: 'Time',
				USER_BANK_NAME: 'Account name',
				TOTAL_AMOUNT: 'Total amount: '
			},
			DEVICE_STATUS: {
				IMEI: 'Imei',
				START_TIME: 'Start time',
				END_TIME: 'End time',
				VERSION: 'Version',
				START_SIGNAL: 'Start signal',
				END_SIGNAL: 'End signal',
				STATUS: 'Status',
				ONLINE: 'Online',
				OFFLINE: 'Offline'
			}
		},
		SIM: {
			VIETTEL_3G: 'Viettel 3G',
			VINAPHONE_PAY_PAID: 'Vinaphone prepaid',
			MOBIPHONE_PAY_PAID: 'Prepaid Mobiphone',
			VIETTEL_PAY_PAID: 'Viettel prepaid',
			VINAPHONE_POST_PAID: 'Postpaid Vinaphone',
			MOBIPHONE_POST_PAID: 'Postpaid Mobiphone',
			VIETTEL_POST_PAID: 'Viettel postpaid',
			VINAPHONE_3G: 'Vinaphone 3G',
			MOBIPHONE_3G: 'Mobiphone 3G',
			VNET_DATA_M1: 'VNET Data M1',
			VNET_DATA_M2: 'VNET Data M2',
			UNKNOWN: 'Unknown',
		},
		SENSOR_TYPE: {
			FUEL: 'Fuel',
			FUEL_SENSOR: 'Extra fuel tank',
			TEMPERATURE: 'Temperature',
			HUMID: 'Humid',
			PRESSURE: 'Pressure',
			OTHER: 'Other',
		},
		SENSOR_TEMPLATE: {
			CALIBRATION: 'Calibration',
			ORIGINAL: 'Original',
			LINEAR: 'Linear',
		},
		MOMENT: {
			IN_DAY: 'of the day',
			TO_DAY: 'today',
		},
		LIBARY: {
			DATE_RANGE_PICKER: {
				TO_DAY: 'Today',
				ADD_1_WEEK: '1 week later',
				ADD_2_WEEK: '2 weeks later',
				ADD_1_MONTH: '1 month later',
				ADD_2_MONTH: '2 months later',
				ADD_3_MONTH: '3 months later',
				ADD_6_MONTH: '6 months later',
				ADD_1_YEAR: 'Next year',
				ADD_2_YEAR: '2 years later',
				YESTERDAY: 'Yesterday',
				THIS_WEEK: 'This week',
				LAST_WEEK: 'Last week',
				LAST_7_DAYS: 'Last 7 days',
				THIS_MONTH: 'This month',
				LAST_MONTH: 'Last month',
				LAST_30_DAY: 'Last 30 days',
				CUSTOM_RANGE_LABLE: 'Optional',
				APPLY_LABLE: 'Apply',
				CANCEL_LABLE: 'Cancel',
				FROM_LABLE: 'From',
				TO_LABLE: 'To',
				DAY_OF_WEEK: {
					SU: 'Su',
					MO: 'Mo',
					TU: 'Tu',
					WE: 'We',
					TH: 'Th',
					FR: 'Fr',
					SA: 'Sa',
				},
				MONTH_NAME: {
					JANUARY: 'January',
					FEBRUARY: 'February',
					MARCH: 'March',
					APRIL: 'April',
					MAY: 'May',
					JUNE: 'June',
					JULY: 'July',
					AUGUST: 'August',
					SEPTEMBER: 'September',
					OCTOBER: 'October',
					NOVEMBER: 'November',
					DECEMBER: 'December',
				},
			},
		},
		COMMON: {
			GENERAL: {
				BASE: 'Basic',
				COMMAND: 'Send command',
				SEARCH_ADVANCED: 'Advanced search',
				RESET: 'Reset',
				SEARCH: 'Search',
				PARAMETER: 'Parameters',
				EXAMPLE: 'For example',
				ACTIVE: 'Activate',
				INACTIVE: 'Not activated',
				BLOCK: 'Lock',
				EXPORT_TO: 'Export data',
				DOWNLOAD: 'Download',
				DOWNLOAD_ALL: 'Download all',
				SELECT_ALL: 'Select all',
				SIGN_OUT: 'Sign out',
				WELCOME_TO_TOP_GPS: 'Welcome to TOP GPS',
				CHANGED: 'Charging',
				NO_BATTERY: 'No battery',
				ALL: 'All',
				TOTAL: 'Total',
				DATETIME: 'Date and time',
				POWER_ENGINE: 'Power engine',
				BATTERY: 'Battery',
				CLOSE: 'Close',
				OPEN: 'Open',
				UNKNOWN: 'Unknown',
				EXPORT_EXCEL: 'Export excel',
				EXPORT_TXT: 'Export txt',
				EXPORT_PDF: 'Export pdf',
				CLICK_SHOW_IMAGE: 'Click show image',
				SERVERS: 'Servers',
				INSERT_TEXT: 'Insert text here',
				PASSWORD: 'Password',
				REFESH: 'Refresh',
				IN: 'In',
				ALL_STATE: 'All status'
			},
			ACTIONS: {
				ACTIONS: 'Actions',
				DELETE: 'Delete',
				EDIT: 'Edit',
				ADD: 'Add',
				ADD_NEW: 'Create new',
				SAVE_CHANGES: 'Save changes',
				CLOSE: 'Close',
				CONFIRM: 'Confirm',
				CANCEL: 'Cancel',
				SEND: 'Submit',
				DETAIL: 'Details',
				ACTIVE: 'Activate',
				SOLD: 'Sell',
				LOCK: 'Lock',
				UNLOCK: 'Unlock',
				LOCK_ON: 'Lock open',
				SAVE: 'Save',
				MOVE_POINT: 'Move point',
				MOVE: 'Move',
				CANCEL_TRANSACTION: 'Cancel transaction',
				REQUEST_CANCEL: 'Request cancel',
				SERVICE_EXTENSION: 'Service extension',
				TRANSFER_MONEY: 'Transfer money',
				LINK: 'Link',
				UNLINK: 'Unlink'
			},
			COLUMN: {
				ONLY_DEVICE: 'Only device',
				NAME: 'Name',
				DESCRIPTION: 'Description',
				MODIFIELD_BY: 'Edited by',
				MODIFIED_BY: 'Edited by',
				UPDATED_DATE: 'Updated date',
				SORT_ORDER: 'Order',
				ACTIONS: 'Actions',
				CREATED_BY: 'Creator',
				CREATED_DATE: 'Created date',
				UPDATED_BY: 'The fixer',
				IMEI: 'IMEI',
				USERNAME: 'Account',
				TYPE: 'Type',
				SIM: 'SIM',
				STARTTIME: 'Start time',
				ENDTIME: 'End time',
				SERVICE_EXPIRE: 'Service expire',
				SERVICE_EXPIRE_OLD: 'Service expire old',
				SERVICE_EXPIRE_NEW: 'Service expire new',
				WARRANTY_EXPIRE: 'Warranty expire',
				STATUS: 'Status',
				CANCEL: 'Cancel',
				VALUE: 'Value',
				ADDRESS: 'Address',
				DELETE: 'Delete',
				ADD_DAY: 'Add days',
				POINT: 'Points',
				POINT_OLD: 'Points old',
				POINT_NEW: 'Points new',
				UNIT: 'Unit',
				CREATE_BY: 'Creator',
				COORDINATE: 'Coordinate',
				ACTION: 'Functions',
				NAME_KEY: 'Key name',
				STATUS_CAR: 'Vehicle status',
				VIN: 'VIN',
				BATTERY: 'Battery',
				UPDATE_TIME: 'Update time',
				POWER: 'Power',
				SIGNAL: 'Signal',
				ACTIVE: 'Activate',
				IS_SELL: 'Sold',
				KEY_LANGUAGE: 'Key language',
				KEY_TRANSLATED: 'Key translated',
				USER_TRANSACTION: 'Account transaction',
				PAY: 'Pay',
				NOTE: 'Note',
				IMPLEMENTER: 'Implementer',
				PAY_AT: 'Pay at',
				BILL_IMG: 'Bill image',
				USER_RENEWAL: 'User renewal',
				ACCOUNT_DEVICE: 'Account device',
				NUMBER_PLATE: 'Number plate',
				DEVICE_NAME: 'Device name',
				EMAIL: 'Email',
				PERMISSIONS: 'Permission',
				LISTPERMISSION: 'List permission',
				TIMEFORMAT: 'Time format',
				UNITVOLUME: 'Unit volume',
				UNITWEIGHT: 'Unit weight',
				SENSORS: 'Sensor',
				LANGUAGE: 'Language',
				ROLENAME: 'Role name',
				WEEKFIRSTDAY: 'Week first day',
				ROLEID: 'Role Id',
				ICONURL: 'Icon url',
				ICONMAPURL: 'Icon map url',
				ICONSVG: 'IconSvg',
				WORK: 'Action',
				PHONE: 'Phone number',
				DEVICE_TYPE: 'Device type',
				AMOUNT: 'Amount',
				ACCOUNT_TRANSACTION: 'Account transaction',
				DETAIL: 'Detail',
				ACCOUNT: 'Account',
				SEVER_NAME: 'Server name',
				SERVER: 'Server',
				DISTRIBUTOR: 'Distributor',
				SUB_USERNAME: 'Account 3',
				TOTAL: 'Total',
				ADD_POINT: 'Add point',
				MOVE_POINT: 'Move point',
				RENEWS: 'Renews',
				COUNT_TRANSACTION: 'Count transaction',
				TOTAL_POINT: 'Total point',
				AMOUNT_PAID: 'Amount Paid',
				TOTAL_AMOUNT: 'Total amount',
				RENEWS_COUNT: 'Renews count',
				DATE: 'Date',
				TITLE: 'Title',
				USERNAME_MAIN: 'Username main',
				COMMAND_NAME: 'Command name',
				INFO: 'Info',
				FEEDBACK: 'Feedback',
				CONTENT: 'Content',
				FLATFORM: 'Flatform',
				PROCESS: 'Process',
				IP_ADDRESS: 'Ip address',
				ACCESS: 'Access',
				FEEDBACK_INFO: 'Feedback info',
				PROCESS_INFO: 'Process info',
				ACCESS_INFO: 'Access info',
				IMAGE: 'Image',
				IMAGES: 'Images',
				USER_AGENT: 'User-Agent',
				TIME: 'Time',
				LAT_LNG: 'Latitude, Longitude',
				SPEED: 'Speed',
				GSM: 'GSM',
				GPS: 'GPS',
				BASIC: 'Basic',
				TIME_DEVICE: 'Time device',
				LINK_DOCUMENT: 'Link document',
				KEY: 'Key',
				COMMAND: 'Command',
				NAMEKEY: 'Key name',
				CHANNEL: 'Channel',
				CHANNEL_LIVESTREAM: 'Channel for live stream camera.',
				INTEGRATE_DEVICE: 'Integrate device',
				OPEN_SOURCE_LICENSES_LINK: 'Open source licenses link',
				SUPPORT_MAIL: "Support email",
				SUPPORT_SERVICE_PHONE_NUMBER: "Support service phone number",
				SUPPORT_TECH_PHONE_NUMBER: "Support tech phone number",
				TEAM_OF_SERVICE_LINK: "Policy",
				CHARGER_TYPE: 'Charge type',
				ABOUT_US_WEB_LINK: 'About us link'
			},
			VEHICLE_TYPE:{
				MOTORBIKE: 'Motorbike',
				CAR: 'Car',
				OTHERS: 'Other',
				ALL: 'All'
			},
			VALIDATION: {
				REQUIRED_FIELD: 'please do not leave blank',
				EMAIL: 'incorrect email format',
				MIN_LENGTH: 'please enter at least {{min}} characters',
				MAX_LENGTH: 'please enter a maximum of {{max}} characters',
				REQUIRED_FIELD_NAME: 'please do not leave blank',
				PLEASE_CHOOSE_TYPE: 'Please select a category',
				PLEASE_ENTER_CORRECT_FORM: 'please enter the correct form',
				INVALID: 'invalid value',
				LEASE_ENTER_NUMBER: 'please enter the number',
				PLEASE_ENTER_MIN_LENGTH:
					'please enter at least {{min}} characters',
				PLEASE_ENTER_MAX_LENGTH:
					'please enter up to {{max}} characters',
				PLEASE_ENTER_RANGE_LENGTH:
					'please enter from {{min}} to {{max}} characters',
				PLEASE_ENTER_RANGE:
					'please enter the number from {{min}} to {{max}}',
				PLEASE_ENTER_MIN: 'please enter a number greater than {{min}}',
				PLEASE_ENTER_MAX: 'please enter a number less than {{max}}',
				PLEASE_ENTER_PHONE: 'please enter the phone number',
				PLEASE_ENTER_EMAIL: 'please enter email',
				PLEASE_ENTER_NUMBER: 'please enter the number',
				REQUIRED_DEVICE: 'List devices required',
				INVALID_EMAIL: " Email '{{email}}' invalid ",
			},
			MESSAGE: {
				INTERNAL_SERVER_ERROR: 'Server side error',
				NO_RECORDS_FOUND: 'No data found',
				NOT_FOUND_DATA: 'No data found',
				COMFIRM_DELETE: 'Are you sure you want to delete this item?',
				COMFIRM_RESET: 'Reset password?',
				PASSWORD_DEFAULT: "The default password is '68686868'",
				COPIED: 'Copied',
				COPY: 'Copy',
				COPY_FALSE: 'Failed to copy',
				NOTHING_SELECTED: 'Not selected',
				CONFIRM_CANCEL_TRANSACTION:
					'Are you sure you want to cancel this transaction',
				CONFIRM_REQUEST_CANCEL_TRANSACTION:
					'Are you sure you want to request cancel this transaction',
				SELECT_DEVICE_TO_DISPLAY: 'Select device to display',
				SELECT_CAMERA_TO_DISPLAY: 'Select camera to display',
				PLEASE_CHOOSE_DEVICE: 'Please choose device',
				SELECT_FENCE_TO_DISPLAY: 'Select fence to display',
				DEVICE_NOT_SUPPORT_DEBUG:
					'This device is not supported to log data',
				PLEASE_ENTER_DEVICE: 'Please enter IMEI',
				PLEASE_SELECT_DEVICE_TO_VIEW_AGAIN: 'Please select device for streaming again',
				PLEASE_SEARCH_FOR_PLAYBACK_AGAIN: 'Please search for playback',
				PERMISSION_COMFIRM_DELETE: 'Are you sure you want to delete this permission?',
				IMPORT_SUCCESS: 'Import success',
				LINK_ACCOUNT: 'Promotional wallet link',
				COMFIRM_LINK_ACCOUNT: 'Do you want to link your promotional wallet to your account ',
				UNLINK_ACCOUNT: 'Unlink promotional wallet',
				COMFIRM_UNLINK_ACCOUNT: 'Do you want to unlink the promotional wallet from your account '
			},
			DATA_TABLE: {
				SELECT_PAGE_SIZE: 'Select the number of records to display',
				SHOW: 'show',
				OF: 'of',
				SEARCH: 'Search',
				RESET: 'Reset',
				REFRESH: 'Refresh',
				PLEASE_WAIT: 'Please wait ...',
				LAST_PAGE: 'Last page',
				NEXT_PAGE: 'Next page',
				FIRST_PAGE: 'First page',
				PREVIOUS_PAGE: 'Previous page',
				CREATED_DATE: 'Created date',
				SUCCESS: 'Success!',
				FAILED: 'Failed!',
			},
			USER_TREE: {
				USER_NAME__ACCOUNT: 'Username / Account',
				POINT: 'Points',
				STOCK: 'Warehouse',
				TOTAL: 'Sum',
			},
			USER_INFO: {
				USER_INFO: 'Information user',
				DETAIL: 'Details',
				GO_TO_USER_MANAGE_PAGE: 'Go to the user manage page',
				GO_TO_DEVICE_MANAGE_PAGE: 'Go to the device manage page',
				GO_TO_MAP_PAGE: 'Go to the map page',
				CONFIRM_DELETE: 'Are you sure you want to delete this item?',
			},
			DEVICE: {
				CONFIRM_DELETE: 'Are you sure you want to delete this charge station?',
			},
			LIST_DEVICE: {
				GENERAL: {
					OFF: 'Off',
					CLOSED: 'Closed',
					ON: 'On',
					OPEN: 'Open',
				},
				TRKTIME: 'Update time',
        		CONNECTING: 'Connector',
        		IMAGE: 'Image',
				NOT_UPDATE_DATA: 'Not updated data',
				DEVICE_NOT_SET_COORDINATES:
					'Not updated location or poor gps signal',
				DEVICE_EXPIRED: 'The device has expired',
				ALL_DEVICE: 'All devices',
				ALL: 'All',
				STOP: 'Stop',
				RUN: 'Run',
				EXPIRED: 'Expired',
				LOST_GPS: 'GPS week',
				LOST_SIGNAL: 'Lost signal',
				FILTER: 'Filter',
				FILTERS: 'Filters',
				ALL_GROUPS: 'All groups',
				ALL_TYPES: 'All categories',
				RESET: 'Filter',
				DEVICE_GROUP: 'Device group',
				DEVICE_TYPE: 'Device type',
				DEVICE_INACTIVE: 'The device has not yet activated the service',
				IMEI: 'IMEI',
				GROUPS: 'Groups',
				ADDRESS: 'Address',
				DISTANCE_TODAY: 'Distance of day',
				UPDATE_TIME: 'Update time',
				NUMBER_PLATE: 'License plate',
				VIN_NUMBER: 'VIN number',
				BATTERY: 'Battery',
				CHANGED: 'Charging',
				DRIVING_TODAY: 'Driving time',
				VIN: 'VIN',
				SIMNO: 'SIM number',
				GEOFENCE: 'Geofence',
				FOLLOW_POPUP: 'Open device monitoring window',
				FOLLOW_NEW_WINDOW: 'Open the device tracking page',
				GSM: {
					LOST_GSM: 'Lost',
					WEAK: 'Weak',
					MEDIUM: 'Medium',
					GOOD: 'Good',
					EXCELLENT: 'Excellent',
				},
				POWER_VOLTAGE: 'Power',
				DRIVER: 'Driver',
				FOLLOW: 'Follow',
				UNFOLLOW: 'Unfollow',
				SHOW_ALL_ON_MAP: 'Show all on the map',
				HIDE_ALL_ON_MAP: 'Hide all on the map',
				HIDE_ON_MAP: 'Hide on map',
				SHOW_ON_MAP: 'Show on map',
				HISTORY_TRIPS: 'History trips',
				FAVORITE: 'Favorite',
				DISFAVOUR: 'Disfavour',
				NOTIFICATIONS: 'Notifications',
				VIEW_FAVORITE_MODE: 'View favorite mode',
				DETAIL: 'Detail',
				SORT_CHECK: 'Sort the selected device',
				SEND_COMMAND: 'Send command',
				LAST_PHOTO: 'Last photos',
				DEVICE_NAME: 'Device name',
				POWER_UP_TIME: 'Power up time',
				POWER_UP_DURATION: 'Duration power up'
			},
			ERROR: {
				SOMTHING_WENT_WRONG: 'An error occurred!',
				RETURN_BACK: 'Go back',
				NOT_PERMISSION_TO_VIEW_THIS_PAGE:
					'You are not authorized to view this page!',
			},
			ALERT_POPUP: {
				EVENT_TYPE: 'Event type',
				TIME_IN_OUT: 'Time in and out',
				TIME: 'Time',
				DEVICE: 'Device',
				GEOFENCE: 'Geofence',
				ADDRESS: 'Address',
				NOTIFICATION: 'Notification',
			},
			MAP_SETTING: {
				MAP_CONFIG: 'Map config',
				LIST_DEVICE: 'List device',
				INFO_DEVICE: 'Device info box',
				TRACKING: 'Tracking',
				HISTORY_TRIPS: 'History trips',
				CHART: 'Chart',
				ICON: 'Icon',
				DURATION: 'Duration',
				SPEED: 'Speed',
				BATTERY: 'Battery',
				GSM: 'GSM',
				NUMBER_PLATE: 'Number plate',
				IMEI: 'IMEI',
				SIM_NO: 'SIM no',
				VIN: 'VIN',
				POWER_VOLTAGE: 'Power voltage',
				DISTANCE_TODAY: 'Distance today',
				DRIVER: 'Driver',
				DRIVING_TODAY: 'Driving today',
				UPDATE_TIME: 'Update time',
				GROUP: 'Group',
				GEOFENCE: 'Geofence',
				ADDRESS: 'Address',
				OTHER_DATA: 'Other data',
				BASE: 'Base',
				ADVANCE: 'Advance',
				DRIVING: 'Driving',
				STOP: 'Stop',
				DISTANCE: 'Distance',
				SUMMARY: 'Summary',
				AIR: 'Air',
				DOOR: 'Door',
				ENGINE: 'Engine',
				MAP_MULTI_CONFIG: 'Multiple vehicle tracking config',
				DISPLAY: 'Display info box',
				SHOW_FULL: 'Show open',
				BEN: 'Ben',
				ACC: 'Acc',
				SENSOR: 'Sensor'
			},

			USER_PROFILE: {
				POINTS: 'points',
				NOTE_POINT: '1 point <=> 1 month',
				AVAILABLE_BALANCE: 'Available balance',
			},
			EXCEL: {
				START_TIME: 'Start time',
				END_TIME: 'End time',
				TIMEZONE: 'Timezone',
				COUNT: 'Count',
				TOTAL: 'Total',
				AMOUNT: 'Amount',
				USER_LOGIN: 'User login',
				TOTAL_POINT: 'Total point',
				TOTAL_COUNT: 'Total count',
				USER_NAME: 'User name',
			},
			FEEDBACK: {
				GENERAL: {
					FEEDBACK: 'Feebback',
					CONTENT: 'Content',
					CONTENT_NOTE: 'Describe the problem or share ideas',
					SHOTSCREEN: 'Shotscreen',
					IMAGE: 'Image',
					CHOOSE_STATUS: 'Choose status',
				},
				MESSAGE: {
					BROWSER_NOT_SUPPORT:
						'The browser does not support this feature!',
				},
				STATUS: {
					CHOOSE_STATUS: 'Choose status',
					PENDDING: 'Pendding',
					COMPLETE: 'Complete',
					SKIP: 'Skip',
				},
			},
			UPLOAD_FILE: {
				GENERAL: {
					CROP_IMAGE: 'Crop image',
					PREVIEW: 'Preview',
				},
				VALIDATION: {
					MAX_COUNT: 'Please select up to {{maxCount}} files',
					LENGTH_FILE:
						'Please select a file smaller than {{maxLength}}MB',
				},
			},
			COMMAND: {
				RESET: 'Reset',
				RESET_FACTORY: 'Reset factory',
				INFO: 'Get info',
				TIME_ZONE: 'Get time zone',
				SET_TIME_ZONE: 'Set time zone',
				LOCK_CONFIG: 'Lock config',
				UNLOCK_CONFIG: 'Unlock config',
				APN: 'Get APN',
				SET_APN: 'Set APN',
				STATUS: 'Status',
				SET_STATUS: 'Set status',
				LOCATION: 'Get location',
				HEARBEAT_INTERVAL: 'Heartbeat interval',
				TRACKING_INTERVAL: 'Tracking interval',
				MODE: 'Get model',
				SET_MODE: 'Set model',
				SHOCK_MODE: 'Get shock mode',
				SLEEP_MODE: 'Get sleep mode',
				SMS_CENTER: 'Get sms center',
				SET_SMS_CENTER: 'Set sms center',
				SOS: 'SOS',
				IP_PORT: 'Get ip port',
				SET_IP_PORT: 'Set ip port',
				HOST: 'Get host',
				SET_HOST: 'Set host',
				TURN_OFF_ENGINE: 'Turn of engine',
				TURN_ON_ENGINE: 'Turn on engine',
				SAVES_BATTERY: 'Saves battery',
				TURN_OFF: 'Turn off',
				TURN_ON: 'Turn on',
				CHANGE_PASSWORD: 'Change password',
				AUTHORIZATION: 'Authorization',
			},
			SHOW: 'show',
			OF: 'of',
			SEARCH: 'Search',
			RESET: 'Reset',
			REFRESH: 'Refresh',
			PLEASE_WAIT: 'Please wait ...',
			LAST_PAGE: 'Last page',
			NEXT_PAGE: 'Next page',
			FIRST_PAGE: 'First page',
			PREVIOUS_PAGE: 'Previous page',
			CREATED_DATE: 'Created date',
			SUCCESS: 'Success!',
			FAILED: 'Failed!',
			EDIT_BY: 'Edited by',
			UPLOAD: 'Upload',
			SAVE: 'Save',
		},
		PERMISSIONS: {
			GENERAL: {
				USER: 'User',
				DEVICE: 'Device',
				ROLE: 'Role',
				PERMISSION: 'Permission',
				MAP: 'Map',
				REPORT: 'Report',
				MANAGE: 'Manage',
				ADMIN: 'Admin',
				POINT: 'Point',
				UTILITIES: 'Utilities',
				MENU: 'Menu'
			},
			PROFILE: {
				OWN_VIEW: 'View profile',
			},
			USER: {
				LIST: 'List user',
				UPDATE: 'Update user',
				CREATE: 'Create user',
				DELETE: 'Delete user',
				TREE: 'View user tree',
				UPDATE_SERVICE_PRICE: 'Edit service price'
			},
			COMMAND: {
				MANAGE: {
					STATUS: 'Change status command',
				},
				ACTION: {
					LOCK: 'Lock command',
				},
			},
			DEVICE: {
				ACTION: {
					COMMAND: 'Command send function',
					IMPORT_DEVICE: 'Device import function',
					RENEWED: 'Renewal device',
					SELL: 'Sell device',
					UPDATE: 'Edit device',
					STATUS: 'Change status device',
					RENEWS: 'Renews device',
					SORT_ORDER: 'Sort order device',
					DEVICE_GROUP: 'Device group create function',
					DRIVER: 'Driver create function',
				},
				EDIT: {
					SIM: 'Edit SIM of device',
					GROUP: 'Edit device group',
					ICON: 'Edit device icon',
					ACTIVE_STATUS: 'Change active status device',
					ACTIVE_CUSTOMER: 'Active customer',
					DESCRIPTION_ADMIN: 'Descriptions for admin',
					TYPE: 'Edit type device',
					ADVANCED: 'Edit advanced',
					SOLD: 'Edit sold',
					SERVICE_EXPIRE: 'Edit service expire',
					EXTENSION: 'Config extension',
					DEBUG: 'Record raw data',
					TOLLFEE: 'Toll fee',
					IMEI: 'Edit imei',
					CORRIDOR: 'Corridor',
					ADVANCED_FEATURE: 'Advanced feature',
					GROUP_CAMERA: 'Edit camera group',
					LIVESTREAM: 'Livestream'
				},
				// MANAGE: 'Manage device',
				DELETE: 'Delete device',
				RENEWED: {
					ONE_MONTH: 'Renews with one month',
					USER_OLD_SERVICE: 'Renews use old service',
				},
				DEBUG: 'Record raw data',
				SHOW: {
					ID: "Show device id"
				},
				MANAGE: {
					INFO: 'Manage device information',
					MAP: 'Manage devices on the map',
					CONNECTOR: 'Connector'
				}
			},

			PERMISSION: {
				MANAGE: 'Manage permission',
				LIST: 'List permission',
			},
			ROLE: {
				MANAGE: 'Manage role',
				LIST: 'List role',
			},
			MAP: {
				MAP: 'Map',
				TRACKINGS: 'Track multiple vehicles',
				PLAYBACK: 'Playback',
				MAP_LITE: 'Map lite',
				MAP_FULL: 'Map full',
				VIEW: 'Show map'
			},
			REPORT: {
				REPORT: 'Report',
				FUEL: 'Report fuel',
				DEVICE: 'Report device',
				ROUTE: 'Report route',
				ENGINE: 'Report engine',
				DATA_LOG: 'Data History',
				SUMMARY_DAY: 'Summary day',
				ROUTE_SIMPLE: 'Route simple',
				GENERAL: 'Summary',
				GEOFENCE_DETAIL: 'Detail geofence',
				TOLL_STATION: 'Toll station',
				ESTIMATED_FUEL: 'Estimated fuel',
				TEMPERATURE: 'Temperature',
				STATUS_HISTORY: 'Status history',
				TRIP: 'Trip',
				GEOFENCE_SUMMARY: 'Geofence summary',
				QCVN_ROUTE: 'Route',
				QCVN_DRIVING_10H: 'Driving 10 hours',
				QCVN_STOP: 'Stop',
				QCVN_OVER_SPEED: 'Over speed',
				QCVN_SUMMARY_BY_VEHICLE: 'Summary by vehicle',
				QCVN_DATA_TRANSMISSION: 'Data transmission',
				QCVN_SPEED: 'Speed',
				QCVN_DRIVING_4H: 'Driving 4 hours',
				QCVN_SUMMARY_BY_DRIVER: 'Summary by driver',
				STOP_POINT: 'Stop point',
				DEBUG: 'RAW LOG',
				DEVICE_SESSION_LOGIN: 'Device session log',
				SIM: 'SIM',
				SUPPORTS: 'Support',
				TRANSACTION_HISTORY: 'Transaction history',
				REPAIR_FEE: 'Repair fee',
				CHANGE_BALANCE: 'Balance change'
			},
			MENU: {
				SYSTEM_CONFIGURATION: 'System configuration',
				DEVICE_CONFIGURATION: 'Device configuration',
				USER_TREE: 'Administrator',
				DASHBOARD: 'Dashboard',
				MAP: 'Map',
				BOOKING: 'Booking',
				MANAGE: 'Manage',
				REPORT: 'Report',
				TECHNICAL_ASSISTANCE: 'Technical assistance',
				USER: 'Account',
				CHARGE_STATION: 'Charge station',
				CONNECTOR: 'Connector',
				RFID_TAG: 'RFID Tag',
				WALLET: 'Wallet',
				TRANSACTION_HISTORY: 'Charge transaction',
				BOOKING_HISTORY: 'Booking history',
				CHANGE_BALANCE: 'Change balance',
				REPAIR: 'Repair costs',
				ROLE:'Role',
				PERMISSION: 'Permission',
				LOGIN_PAGE: 'Login page',
				DEVICE_TYPE: 'Device type',
				DEVICE_ICON: 'Device icon',
				CONNECTOR_TYPE: 'Connector type',
				CONNECTOR_ICON: 'Connector icon',
				SIM_TYPE: 'Sim type',
				RAW: 'Raw data history',
				GATEWAY_CONNECTION: 'Gateway connection',
				SERVICE_PRICE: 'Service price',
				DEVICE: 'Device',
				PAYMENT_SIM: 'Payment sim',
				SERVICE_EXPIRATION: 'Service term',
				BANK: 'Bank',
				TERMINAL: 'Business Unit',
				SYSTEM: 'System',
				DEVICE_STATUS: 'Device status',
				VOUCHER: 'Voucher'
			},
			MANAGE: {
				MANAGE: 'Manage',
				USER: 'User',
				DEVICE: 'Device',
				DEVICE_GROUP: 'Device group',
				COMMAND: 'Commange',
				ALIAS: 'User alias',
				ALERT: 'Alert',
				GEOFENCE: 'Geofence',
				SCHEDULE_REPORT: 'Schedule report',
				ALERT_RULES: 'Alert rule',
				ROLE_ALIAS: 'Role alias',
				ACTIVITY_LOG: 'Activity log',
				POI: 'POI',
				DRIVER: 'Driver',
				MESSAGE: "Message",
				TRIP: 'Trip',
				API_KEY: "API key",
				GEOFENCE_GROUP: 'Geofence group',
				REMIND: 'Remind'
			},
			ADMIN: {
				ADMIN: 'Admin',
				LOGIN_PAGE: 'Login page',
				TRANSPORT_TYPE: 'Transport type',
				SIM_TYPE: 'SIM type',
				ROLE: 'Role',
				PERMISSION: 'Permission',
				DEVICE_TYPE: 'Device type',
				SENSOR_TEMPLATE: 'Sensor template',
				ICON: 'Icon',
				TOLL_STATION: 'Toll station',
				SYSTEM_LOG: 'System log',
				FEEDBACK: 'Feedback',
				UTILITIES: 'Utilities',
				NOTICES: 'Notices system',
				POI_TYPE: 'POI type',
				TRANSPORT_TYPE_QCVN: 'Transport type QCVN',
				TOLL_ROAD: 'Toll road',
				TOLL_SEGMENT: 'Toll segment'
			},
			POINT: {
				ADD: 'Add points',
				MOVE: 'Move points',
				EXTEND: 'Extend',
				TRANSACTION_HISTORY: 'Transaction history',
				RENEWS_HISTORY: 'Renews history',
				MANAGE: 'Manage',
				POINTS: 'Points',
				SYNTHESIS_REPORT: 'Synthesis report',
			},
			TRANSACTION: {
				UPDATE: 'Update transaction',
				REQUEST_CANCEL: 'Request cancel',
				CANCEL: 'Cancel',
			},
			UTILITIES: {
				UTILITIES: 'Utilites',
				TRACKINGS: 'Trackings',
			},
			FUEL: {
				SUMMARY: 'Summary',
				FUEL: 'FUEL',
				CHART: 'CHART'
			},
			SYSTEM: {
				CREATE: 'Create system',
				UPDATE: 'Update system',
				DELETE: 'Delete system',
				DETAIL: 'Detail system',
				SEARCH: 'Search system'
			},
			VOUCHER: {
				CREATE: 'Create voucher',
				UPDATE: 'Update voucher',
				DELETE: 'Delete voucher'
			}
		},
		SYSTEM: {
			UPDATED_VERSION: 'Updated new version',
		},
		ICON_TYPE: {
			TAXI: 'Taxi',
			CAR: 'Car',
			TRUCK: 'Truck',
			CONTAINER: 'Container',
			MOTORBIKE: 'Motorbike',
			MOTORCYCLES: 'Motorcycles',
			DIRECTION: 'Direction',
			OTHER: 'Other',
		},
		SENSORTPL: {
			GET_LIST_SUCCESS: 'Load the sensor list successfully!',
			NAME_NOT_VALID: 'Invalid sensor name',
			ALREADY_EXIST: 'Sensor name already exists',
			CREATED_SUCCESS: 'Sensor successfully created',
			CREATED_FAIL: 'Sensor creation failed',
			NOT_FOUND: 'Template sensor does not exist',
			UPDATED_FAIL: 'Sensor repair failed',
			UPDATED_SUCCESS: 'Sensor successfully repaired',
			DELETED_FAIL: 'Failed to delete sensor!',
			DELETED_SUCCESS: 'Sensor successfully deleted',
		},
		MAP: {
			STATUS: {
        Available: 'Available',
        InUse: 'InUse',
        Full: 'Full',
        Repair: 'Repair',
        NotActive: 'Not active',
        Closed: "Closed",
        Break: "Break",
		Maintenance: 'Maintenance'
			},
      LABEL: {
        open247: "Open 24/7",
        notOpen247: "Not open 24/7",
        bookmark: 'bookmark',
        addPhoto: 'add Photo',
        direction: 'direction',
        edit: 'edit',
        photo: 'Photos',
        nearbyLocation: 'Nearby Location',
        more: 'View more',
        legend: "legend",
        filter: "Filter",
        booking: "Booking",
      },
			FEATURES: {
				SEARCH: 'Search ...',
				CREATE_LANDMARK: 'Create new landmark',
				CREATE_GEOFENCE: 'Create new geofence',
				CENTER: 'Center point',
				EDIT: 'Edit',
				DELETE: 'Delete',
				LOCATE: 'Locate',
				ZOOM_IN: 'Zoom in',
				ZOOM_OUT: 'Zoom out',
				CLUSTER: 'Show cluster',
				FITBOUND: 'Collapse display area',
				STYLE_MARKER_ONE: 'Label',
				STYLE_MARKER_TWO: 'Label + Icon',
				MARKER_STYLE: 'Display type',
				MAP_TYPE: 'Map type',
				ROAD_MAP: 'Road map',
				ROAD_MAP_TOPGPS: 'Map Topgps',
				SATELLITE: 'Satellite map',
				GOOGLE_ROAD_MAP: 'Google road map',
				GOOGLE_SATELLITE: 'Google satellite map',
				LOCATION: 'Location',
				GEOFENCES: 'Geofence',
				LANDMARK: 'Landmark',
				FOLLOW: 'Follow',
				MARKER_POPUP: 'Show info when hover',
				FULL_SCREEN: 'Full screen',
				NORMAL_SCREEN: 'Normall screen',
				TRAFFIC: 'Traffic',
				BRIGHTNESS: 'Brightness',
				MAP: 'Map',
				INFO_BOX: 'Info box',
				PLAY: 'Play',
				PAUSE: 'Pause',
				SPEED: 'Speed',
				COLOR: 'Display color base on speed',
				CENTER_POINT: 'Center point',
				DISTANCE_TITLE: 'Measure distance',
				DISTANCE_GUIDE: 'Click on the map to add a point',
				DISTANCE_TOTAL: 'Total',
				NO_DATA: 'No data',
				DIRECTION: 'en',
				DIRECTION_TITLE: 'Directions',
				DIRECTION_START: 'Choose starting point, or click on the map',
				DIRECTION_ADD: 'Add destination',
				DIRECTION_END: 'Choose destination, or click on the map',
				DIRECTION_DIRECTION: 'Search on Google Maps',
				DIRECTION_TOLL_FEE_DIRECTION: 'Find route',
				DIRECTION_GOOGLE:
					'We are using Google Maps to improve your searching experience',
				DIRECTION_HINT: 'Type @ to show device list',
				DIRECTION_NO_ROUTE: 'Cannot find any routes',
				DIRECTION_GO: 'Via',
				DIRECTION_FEE: 'Esimated fee',
				DIRECTION_TIME: 'Esimated time',
				DIRECTION_DISTANCE: 'Esimated distance',
				DIRECTION_HIGHWAY: 'Highway',
				DIRECTION_NATIONAL_ROAD: 'National road',
				DIRECTION_PROVINCE_ROAD: 'Province road',
				DIRECTION_UNKNOWN: 'Unknown',
				DIRECTION_LOCATION: 'Get your location',
				DIRECTION_AVOID_FEE: 'Avoid tolls',
				DIRECTION_CHANGE: 'Drag to change route',
			},
			CLUSTER: {
				SEARCH: 'Search ...',
			},
			MARKER: {
				PIN: 'Pin info',
				LOCK_SCREEN: 'Lock screen',
				FOLLOW_DEVICE: 'Follow device',
			},
			GENERAL: {
				FOLLOW: 'Follow device',
			},
			TRACKING: {
				GENERAL: {
					ROUTE: 'Route',
					NOW: 'Now',
					NOTIFICATIONS: 'Notifications',
					NO_ITEM: "You seem haven't had anything to track",
					CREATE_ITEM: 'Create one',
					TRACKING: 'Tracking',
					LIST_TRACKING: 'List item',
					PLAYBACK: 'Playback',
				},
				ROUTE: {
					SUMMARY: 'Summary',
					DRIVING: 'Driving',
					STOP: 'Stop',
					DISTANCE: 'Distance',
				},
				INFO: {
					OTHER_DATA: 'Other data',
					AIR: 'Air',
					DOOR: 'Door',
					ENGINE: 'Engine',
					BEN: 'Ben',
					SUMMARY: 'Summary',
					DRIVING: 'Driving',
					STOP: 'Stop',
					DISTANCE: 'Distance',
					TODAY: 'Today',
					STOPED: 'Stoped',
					SENSORS: 'Sensors',
					TURN_ON_OFF: 'Turn on/off power',
					TURN_ON: 'Turn on power',
					TURN_OFF: 'Turn off power',
					CONFIRM:
						'You are trying to turn on/off power, are you sure?',
					YES: 'Confirm',
					NO: 'Cancel',
					ACC: 'Acc'
				},
				NOTIFICATIONS: {
					MARK_READ_ALL: 'Mark read all',
					REFRESH: 'Refresh',
					SEE_MORE: 'See more',
					MARK_AS_READ: 'Mark as read',
					MARK_AS_UNREAD: 'Mark as unread',
					SHOW_ON_MAP: 'Show on map',
					SHOW_DETAIL: 'Show detail',
				},
				CHART: {
					SPEED: 'Speed',
					SHOW_CHART: 'Show chart',
					NO_DATA: 'No data',
					DATA_LOG: 'Data log',
				},
			},
			INFO_BOX: {
				MAP_CONFIG: 'Map config',
				LIST_DEVICE: 'List device',
				TRACKING: 'Tracking',
				HISTORY_TRIPS: 'History trips',
				CHART: 'Chart',
				ICON: 'Icon',
				DURATION: 'Duration',
				SPEED: 'Speed',
				BATTERY: 'Battery',
				GSM: 'GSM',
				NUMBER_PLATE: 'Number plate',
				IMEI: 'IMEI',
				SIM_NO: 'SIM no',
				VIN: 'VIN',
				POWER_VOLTAGE: 'Power voltage',
				DISTANCE_TODAY: 'Distance today',
				DRIVER: 'Driver',
				DRIVING_TODAY: 'Driving today',
				UPDATE_TIME: 'Update time',
				GROUP: 'Group',
				GEOFENCE: 'Geofence',
				ADDRESS: 'Address',
				OTHER_DATA: 'Other data',
				BASE: 'Base',
				ADVANCE: 'Advance',
				DRIVING: 'Driving',
				STOP: 'Stop',
				DISTANCE: 'Distance',
				SUMMARY: 'Summary',
				AIR: 'Air',
				DOOR: 'Door',
				ENGINE: 'Engine',
				NAME: 'Name',
				DIRECTION: 'Direction',
				DEVICE_LABEL: 'Display name',
			},
		},
		MESSEAGE_CODE: {
			AUTH: {
				NOT_VALID: 'The account or password is incorrect',
				NOT_ACTIVE: 'The account has not been activated or locked',
				DELETED: 'The user has been deleted',
				SUCCESS: 'Authentication successful',
				NOT_FOUND_EMAIL: 'Email not found',
				EMAIL_SENT: 'Password reset email sent',
				LINK_NOT_FOUND: 'Sorry! This is an invalid password reset link',
				RESET_SUCCESS: 'Password reset successfully',
				EMAIL_NOT_VALID: 'Email not found',
				LINKNOT_FOUND: 'Link not found',
				EXPIRE_DATE: 'Account has expired',
				INACTIVE: 'The account has not been activated',
			},
			PERMISSION: {
				NAME_NOT_EMPTY: 'The name must not be blank',
				GROUP_NOT_EMPTY: 'The group name cannot be empty',
				ALREADY_EXIST: 'The permission name already exists',
				CREATED: 'Creating successful permission',
				NOT_FOUND: 'The permission name does not exist',
				DESCRIPSION_NOT_EMPTY: 'Description is not empty',
				NAME_ALREADY_EXIST: 'The permission name already exists',
				UPDATED: 'Update permission successful',
				DELETED: 'Successfully deleted',
				DELETED_FAIL: 'Deleting permission failed',
			},
			ROLE: {
				ALREADY_EXIST: 'Role already exists',
				NOT_CHILD: 'The current role is not the login account child',
				NOT_FOUND: 'The role does not exist',
				PARENTID_NOT_FOUND: 'The father role does not exist',
				PARENTID_NOT_VALID: 'The parent role is invalid',
				UPDATED_FAIL: 'Role update failed',
				UPDATED: 'Updated role successfully',
				DELETED_FAIL: 'Delete role failed',
				DELETED_PARENTID_FAIL:
					'The role cannot be deleted while a child is available',
				USED: 'Cannot delete role while child is available',
				DELETED: 'Delete role successfully',
				CREATED: 'Creating successful roles',
			},
			USER: {
				UPDATED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS:
					'Alias accounts do not have the right to update alias accounts',
				DELETED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS:
					'Alias accounts do not have the right to delete alias accounts',
				CREATED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS:
					'Alias accounts do not have the right to create alias accounts',
				NOT_LOAD_CHILD:
					'Only download sub users of the current login user',
				NOT_GET: '',
				PARENTID: '',
				DELETED_NOT_CHILD: 'Cannot delete non-child users',
				RESET_PASSWORD_NOT_CHILD:
					'Cannot reset another account password',
				PARENTID_NOT_EMPTY: '',
				NOT_VALID: '',
				ROLE_NOT_EMPTY: 'The role is not empty',
				ROLE_NOT_VALID: 'The role is invalid',
				ROLE_NOT_FOUND: 'No role found',
				PARENTID_NOT_FOUND: '',
				NAME_NOT_EMPTY: '',
				NAME_ALREADY_EXIST: 'The account name already exists',
				EMAIL_ALREADY_EXIST: 'Email already exists',
				CREATED: 'Successful account creation',
				DELETED: 'Successfully deleted the account',
				NOT_FOUND: '',
				DELETED_FAIL_HAVE_CHILD:
					'Cannot delete account with child accounts',
				DELETED_FAIL_DEVICE: 'Cannot delete account containing devices',
				DELETED_FAIL_DELETED: '',
				DELETED_FAIL_PARENTID: 'Cannot delete parent account',
				DELETED_FAIL_NOT_CHILD: '',
				PARENTID_NOT_VALID: 'The parent account is not valid',
				UPDATED: 'Account update successful',
				UPDATED_SUCCESS: 'Successfully updated the account information',
				RESET_FAIL: 'Reset the error password',
				RESET: 'Password reset successful',
				NOT_CHILD: '',
				UPDATED_FAIL_PARENTID: 'Unable to update parent account',
				UPDATED_FAIL_NOT_CHILD:
					'No permission to update accounts other than child accounts',
				UPDATED_ROLE_NOT_VALID: 'No permission to assign Admin roles',
				UPDATED_ROLE_NOT_FOUND: 'Unable to update user',
				ALREADY_EXIST: 'The username already exists',
				PHONE_ALREADY_EXIST: 'Phone number already exists',
				PHONE_NOT_VALID: 'Invalid phone number',
				EMAIL_NOT_VALID: 'Invalid email',
				UPDATED_PARENTID_NOT_VALID: 'The parent account is not valid',
				DELETED_SUCCESS: 'Successfully deleted the user',
				NOT_CREATED_USER_WITH_PARENT_ID_IS_END_USER:
					'Child of end user cannot create user',
				// ALIAS
				ALIAS_CREATED_SUCCESS: 'Successfully created user alias',
				UPDATED_ALIAS_SUCCESS: 'Update user alias successfully',
				ALIAS_DELETED_SUCCESS: 'Successfully deleted the user alias',
				FORBIDDEN: 'The function requires permission to perform',
				PASSWORD_OLD_NOT_VALID: 'Password old not valid',
				CHANGE_PASS_SUCCESS: 'Change password successfully',
			},
			DEVICE: {
				PHONE_ALREADY_EXIST: 'SIM ',

				MOVE_SUCCESS: 'Transfer device successfully',
				MOVE_FAIL: 'Device transfer failed',
				EQUIPMENT_NOT_VALID: 'Invalid device list',
				USER_NOT_FOUND: 'User not found',
				RECEIVE_NOT_CHILD: 'Invalid transfer account',
				NO_DEVICE_SELL: 'No devices have been renewed',
				NO_USER_RECEVIE: 'Unknown arrival account',

				CHANGE_STATUS_SUCCESS: 'Device status change successful',
				CHANGE_STATUS_FAIL: 'Device status change failed',

				EXTENSION_SUCCESS: 'Device renewal successful',
				USER_EXTENSION_NOT_VALID: 'Invalid renews account',
				USER_EXTENSION_NOT_CHILD: 'Invalid renews account',
				NO_DEVICE_EXTENSION: 'No device was renewed',
				USER_NOT_ENOUGH_POINT:
					'The account does not have enough points to renew',
				DATE_FORMAT_NOT_VALID: 'Invalid extension period',
				EXTENSION_FAIL: 'Device renewal failed',

				ALREADY_EXIST: 'Device already exists',
				GROUP_NOT_FOUND: 'Device group not found',
				TYPE_NOT_FOUND: 'Device type not found',
				IMEI_NOT_VALID: 'Invalid IMEI',
				CREATED: 'Add device successfully',
				USER_NOT_CHILD: 'Invalid account',
				GROUP_NOT_VALID: 'Invalid device group',
				SIM_NOT_FOUND: 'The type of sim does not exist',
				SIM_NOT_VALID: 'Invalid sim type',
				ICON_NOT_FOUND: 'No icon type found',
				ICON_NOT_VALID: 'Invalid icon type',
				NO_DEVICE_IMPORT: 'No devices have been added',

				DELETED_SUCCESS: 'Successfully deleted the device',
				FORBIDDEN: 'The function requires permission to perform',
				DELETED_FAIL: 'Device deletion failed',

				SORT_ORDER_SUCCESS: 'Device arrangement successful',
				SORT_ORDER_FAIL: 'Device arrangement failed',
				IMEI_ALREADY_EXIST: 'Imei already exists',
				UPDATED_SUCCESS: 'Device update was successful',
				SELL_SUCCESS: 'Sell device successfull',
				SELL_FAIL: 'Device sell failed',
				NO_SELL: 'No devices have been sell',
				SOLD: 'The device was sold',
				CAN_NOT_SMALLER_5_MINUTES: 'Can not smaller 5 minitus',
				IMEI_NOT_EMPTY: 'Please enter the IMEI',
				NUMBER_PLATE_ALREADY_EXIST: 'Number plate already exist',
				NUMBER_PLATE_NOT_VALID: "Number plate not valid"
			},
			SIM: {
				ALREADY_EXIST: 'SIM name already exists',
				NOT_VALID: 'Invalid sim name',
				CREATED_SUCCESS: 'Create a new type of sim successfully',
				NOT_FOUND: 'No sim type found',
				UPDATED_SUCCESS: 'Successfully updated the sim type',
				DELETED_SUCCESS: 'Successfully deleted the sim type',
				DELETED_FAIL: 'Removing the sim type failed',
			},
			DEVICETYPE: {
				NOT_FOUND: 'The device type does not exist',
				DELETED_SUCCESS: 'Successfully deleted the device type',
				DELETED_FAIL: 'Removing device type failed',
				NAME_NOT_VALID: 'Device type name is not empty',
				ALREADY_EXIST: 'Device type already exists',
				UPDATED_SUCCESS: 'Device type update successful',
				UPDATED_FAIL: 'Device type update failed',
				CREATED_SUCCESS: 'Successfully created new device type',
				CREATED_FAIL: 'New device type creation failed',
				PROTOCOL_NOT_EMPTY: 'Protocol not empty',
			},
			DEVICE_GROUP: {
				SEACH_NOT_VALID: 'Invalid search data',
				GET_LIST_FAIL: 'Failed to get list of device groups',
				CREATED_FAIL: 'Creating device group failed',
				CREATED_SUCCESS: 'Successfully created device group',
				ALREADY_EXIST: 'Device group name already exists',
				NAME_NOT_EMPTY: 'Device group name is not blank',
				NOT_FOUND: 'Device group does not exist',
				UPDATED_FAIL: 'Device group update failed',
				UPDATED_SUCCESS: 'Device group update successful',
				DELETED_FAIL: 'Deleting device group failed',
				DELETED_SUCCESS: 'Successfully deleted the device group',
			},
			SENSOR: {
				DELETED_SUCCESS: 'Sensor successfully deleted',
				NOT_FOUND: 'Sensor not found',
				DELETED_FAIL: 'Sensor deletion failed',
				UPDATED_SUCCESS: 'Sensor successfully updated',
				NAME_NOT_EMPTY: 'Sensor name is not empty',
				DEVICE_NOT_EMPTY: 'Unknown device',
				TYPE_SENSOR_NOT_EMPTY: 'Unknown sensor type',
				DEVICE_NOT_FOUND: 'Device not found',
				PARAMETER_NOT_VALID: 'Unknown parameter',
				UPDATED_FAIL: 'Sensor update failed',
				CREATED_SUCCESS: 'Sensor successfully created',
				CREATED_FAIL: 'Sensor creation failed',
				FORMULA_ERROR: 'Invalid formula',
				PARAMETER_NOT_FOUND: 'The parameter is invalid',
				CALIBRATION_FAIL: 'Calibration invalid',
				PARAMETER_NAME_NOT_EMPTY: 'The parameter may not be blank',
				SORT_ORDER_SUCCESS: 'Sensor arrangement successful',
			},
			DEVICE_ICON: {
				GET_LIST_SUCCES: 'Get the icon list successfully',
				GET_LIST_FAIL: 'Failed to get list of icons',
				NAME_NOT_EMPTY: 'Icon name cannot be blank',
				NAME_KEY_NOT_EMPTY: 'Icon key is not empty',
				ALREADY_EXIST: 'Device key name already exists.',
				CREATED_FAIL: 'Creating device icon failed',
				CREATED_SUCCESS: 'Successfully created device icon',
				UPDATED_FAIL: 'Device icon type fix failed',
				UPDATED_SUCCESS: 'Successfully repaired the device icon type',
				NOT_FOUND: 'Device icon does not exist',
				DELETED_FAIL: 'Removing device icon failed',
				DELETED_SUCCESS: 'Successfully deleted the device icon',
			},
			DRIVER: {
				SEACH_NOT_VALID: 'Invalid search data',
				GET_LIST_FAIL: 'Failed to get driving list',
				CREATED_SUCCESS: 'New successful driving',
				PHONE_NOT_VALID: 'Invalid phone number',
				EMAIL_NOT_VALID: 'Invalid email',
				PHONE_ALREADY_EXIST: 'Phone number already exists',
				EMAIL_ALREADY_EXIST: 'Email already exists',
				NAME_NOT_EMPTY: "Driver's name must not be blank",
				CREATED_FAIL: 'Creating a drive failed',
				USER_NOT_VALID: 'The specified account is not valid',
				USER_NOT_CHILD: 'The specified account is not valid',
				LICENSEN_NUMBER_ALREADY_EXIST:
					"Driver's license already exists",
				DRIVER_CODE_ALREADY_EXIST: 'The driver code already exists',
				UPDATED_SUCCESS: 'Update driver successfully',
				NOT_FOUND: 'Driving does not exist',
				BEGIN_DATE_NOT_VALID: 'Invalid date of issue',
				EXPIRE_DATE_NOT_VALID: 'The expiration date is invalid',
				UPDATED_FAIL: 'Driving update failed',
				DELETED_FAIL: 'Delete drive failed',
				DELETED_SUCCESS: 'Delete drive successfully',
			},
			SENSORTPL: {
				GET_LIST_SUCCESS: 'Load the sensor list successfully!',
				NAME_NOT_VALID: 'Invalid sensor name',
				ALREADY_EXIST: 'Sensor name already exists',
				CREATED_SUCCESS: 'Sensor successfully created',
				CREATED_FAIL: 'Sensor creation failed',
				NOT_FOUND: 'Template sensor does not exist',
				UPDATED_FAIL: 'Sensor repair failed',
				UPDATED_SUCCESS: 'Sensor successfully repaired',
				DELETED_FAIL: 'Failed to delete sensor!',
				DELETED_SUCCESS: 'Sensor successfully deleted',
			},
			DEVICEICON: {
				GET_LIST_SUCCES: 'Get the icon list successfully',
				GET_LIST_FAIL: 'Failed to get list of icons',
				NAME_NOT_EMPTY: 'Icon name cannot be blank',
				NAME_KEY_NOT_EMPTY: 'Icon key is not empty',
				DELETED_SUCCESS: 'Delete drive successfully',
				ALREADY_EXIST: 'Device key name already exists.',
				CREATED_FAIL: 'Creating device icon failed',
				CREATED_SUCCESS: 'Successfully created device icon',
				UPDATED_FAIL: 'Device icon type fix failed',
				UPDATED_SUCCESS: 'Successfully repaired the device icon type',
				NOT_FOUND: 'Device icon does not exist',
				DELETED_FAIL: 'Removing device icon failed',
			},
			TRANSPORT_TYPE: {
				CREATED_SUCCESS: 'Create a successful transport type',
				CREATED_FAIL: 'Creating a type of transport failure',
				NAME_KEY_NOT_EMPTY: 'The keyword must not be blank',
				LIMIT_SPEED_NOT_VALID: 'Invalid rate limit',
				QCVNCODE_NOT_VALID: 'Invalid QCVN',
				UPDATED_SUCCESS: 'Successful update of transport type',
				UPDATED_FAIL: 'Update type of transport failed',
				DELETED_SUCCESS: 'Successful removal of transport type',
				DELETED_FAIL: 'Delete type of transport failed',
				NAME_KEY_ALREADY_EXIST: 'Keyword already exists',
				SORT_ORDER_SUCCESS: 'Sort order success',
			},
			TRANSPORT_TYPE_QCVN: {
				CREATED_SUCCESS: 'Create a successful transport type',
				CREATED_FAIL: 'Creating a type of transport failure',
				NAME_KEY_NOT_EMPTY: 'The keyword must not be blank',
				LIMIT_SPEED_NOT_VALID: 'Invalid rate limit',
				QCVNCODE_NOT_VALID: 'Invalid QCVN',
				UPDATED_SUCCESS: 'Successful update of transport type',
				UPDATED_FAIL: 'Update type of transport failed',
				DELETED_SUCCESS: 'Successful removal of transport type',
				DELETED_FAIL: 'Delete type of transport failed',
				NAME_KEY_ALREADY_EXIST: 'Keyword already exists',
			},
			DEVICEGROUP: {
				CREATED_FAIL: 'Creating device group failed',
				CREATED_SUCCESS: 'Successfully created device group',
				ALREADY_EXIST: 'Device group already exists',
				NAME_NOT_EMPTY: 'The device group name cannot be empty',
				NOT_FOUND: 'Device group does not exist',
				UPDATED_FAIL: 'Edit device group failed',
				UPDATED_SUCCESS: 'Successfully repairing device group',
				DELETED_FAIL: 'Deleting device group failed',
				DELETED_SUCCESS: 'Successfully deleted the device group',
			},
			COMMAND: {
				UPDATED_SUCCESS: 'Updating status successful',
				CREATED_SUCCESS: 'Successful submission sent',
			},

			ALERT_RULE: {
				CREATED_SUCCESS: 'Create warning success',
				NAME_NOT_EMPTY: 'The warning name is incorrect',
				EVENT_TYPE_NOT_EMPTY: 'Incorrect alert type',
				DEVICE_ID_NOT_EMPTY: 'List of empty devices',
				GEOFENCE_IDS_NOT_VALID: 'Invalid geographic region list',
				SCHEDULE_NOT_VALID: 'Incorrect scheduling',
				EVENT_TYPE_NOT_FOUND: 'No alert type found',
				DELETED_SUCCESS: 'Delete warning successful',
				DELETED_FAIL: 'Clear failure warning',
				UPDATED_SUCCESS: 'Update warning successful',
				UPDATED_FAIL: 'Update failed warning',
				NOT_FOUND: 'Alert rule not found',
				GET_LIST_DEVICE_FAIL: 'Get list device false',
				DEVICE_NOT_VALID: 'Device not valid',
				PUSH_SETTING_NOT_EMPTY: 'Push setting not empty',
				PUSH_SETTING_NOT_VALID: 'Push setting not valid',
				EXTENDED_PARAM_NOT_VALID: 'Alert params not valid ',
			},
			GEOFENCES: {
				NOT_FOUND: 'Geofence not found',
				DELETED_FAIL: 'Deleting Geofence failed',
				DELETED_SUCCESS: 'Successfully deleted the geofence',
				UPDATED_FAIL: 'Update geofence failed',
				TYPE_NOT_FOUND: 'Unknown geofence type',
				NOT_CHILD: 'The specified account is not valid',
				UPDATED_SUCCESS: 'Geofence update successful',
				CREATED_SUCCESS: 'Geofence create successful',
				USER_NOT_VALID: 'The specified account is not valid',
				NAME_NOT_EMPTY: 'The name must not be blank',
				CREATED_FAIL: 'Geofence create failed',
				CHANGE_STATUS_SUCCESS: 'Change status geofence successful',
			},
			LOGIN_PAGE: {
				UPDATED_SUCCESS: 'Login Page update successful',
				CREATED_SUCCESS: 'Login Page create successful',
			},
			SCHEDULED_REPORT: {
				CREATED_SUCCESS: 'Create schedule report successfully',
				CREATED_FAIL: 'Create schedule report failed',
				DELETED_SUCCESS: 'Delete schedule report successfully',
				DELETED_FAIL: 'Delete schedule report failed',
				UPDATED_SUCCESS: 'Update schedule report successfully',
				UPDATED_FAIL: 'Update schedule report failed',
				TYPE_REPORT_ALREADY_EXIST: 'Report type already exist',
				DEVICE_NOT_VALID: 'Device not valid',
			},
			DEVICE_FAVORITE: {
				DELETED_FAIL: 'Remove favorite failed',
				DELETED_SUCCESS: 'Removed the favorite',
				NOT_CHILD: 'The specified account is not valid',
				CREATED_SUCCESS: 'Added a favorite',
				USER_NOT_VALID: 'The specified account is not valid',
				CREATED_FAIL: 'Favorite added failed',
			},

			ROLE_ALIAS: {
				UPDATED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS:
					'Alias accounts do not have the right to update alias accounts',
				DELETED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS:
					'Alias accounts do not have the right to delete alias accounts',
				CREATED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS:
					'Alias accounts do not have the right to create alias accounts',
				CREATED_SUCCESS: 'Create Role alias successfully',
				CREATED_FAIL: 'Create Role alias failed',
				DELETED_SUCCESS: 'Delete Role alias successfully',
				DELETED_FAIL: 'Delete Role alias failed',
				UPDATED_SUCCESS: 'Update Role alias successfully',
				UPDATED_FAIL: 'Update Role alias failed',
				NOT_UPDATED_ROLE_WHEN_YOU_ARE_A_ALIAS:
					'You cannot update roles when you are a user alias',
				NOT_UPDATED_ROLE_FOR_USED_NOT_CHILD:
					'You cannot update roles when you are a user alias',
				PERMISSION_DO_NOT_ALLOW_EXTEND:
					'The selected permission is not valid',
				USED: 'The role is being used',
			},

			POINT: {
				USER_EXTENSION_NOT_CHILD: 'Invalid account for renewal',
				NO_DEVICE_EXTENSION: 'No device was renewed',
				EQUIPMENT_NOT_VALID: 'The device list is not valid',
				USER_NOT_FOUND: 'Invalid account for renewal',
				EXTENSION_FAIL: 'Renewal failed',
				MONTH_NOT_VALID: 'Invalid renewal month',
				USER_NOT_ENOUGH_POINT:
					'The account does not have enough points to renewal',
				EXTENSION_SUCCESS: 'Renewal success',
				PAYAT_NOT_EMPTY: 'Pay at not empty',
				ADD_POINT_SUCCESS: 'Add point success',
				FORBIDDEN: 'Function requires permission',
				POINT_NOT_EMPTY: 'Point not empty',
				POINT_NOT_VALID: 'Invalid point',
				ADD_POINT_FAIL: 'Add point failed',
				SELL_POINT_SUCCESS: 'Sell point success',
				NOT_CHILD: 'Invalid account',
				POINT_NOT_ENOUGH_POINT: 'Not enough points',
				SELL_POINT_FAIL: 'Sell point failed',
				MONTH_DEVICE_EXTENSION_NOT_VALID: 'Invalid renewal month',
				USER_REVICE_NOT_CHILD: 'Invalid account for renewal',
				USER_REVICE_NOT_CHILD_EXTENSION: 'Invalid account for renewal',
			},
			TRANSACTION: {
				FORBIDDEN: 'Function requires permission',
				CANCEL_FAIL: 'Cancel transaction failed',
				CANCEL_SUCCESS: 'Transaction successfully canceled',
				CAN_NOT_CANCEL: 'Invalid transaction type or status',
				UPDATED_SUCCESS: 'Update transaction completed successfully',
				PAYAT_NOT_VALID: 'The payment date is not valid',
				UPLOAD_BILL_IMAGE_FAIL: 'Invalid invoice',
				UPDATED_FAIL: 'Update transaction failed',
				CAN_NOT_UPDATED_CANCEL: 'Transaction update failed',
				REQUEST_CANCEL_SUCCESS:
					'Request to cancel transaction successfully',
				CAN_NOT_REQUEST_CANCEL:
					'Request to cancel the transaction failed',
			},
			CARD: {
				CREATED_SUCCESS: 'Create new code successfully',
				CREATED_FAIL: 'Create new code failed',
			},
			SYSTEM_LOG: {
				CREATED_SUCCESS: 'Create version successfully',
				CREATED_FAIL: 'Create version failed',
				UPDATED_SUCCESS: 'Update version successfully',
				UPDATED_FAIL: 'Update version failed',
			},
			MESSAGE: {
				SEND_SUCCESS: 'Send message successfully',
				SEND_FAIL: 'Send message failed',
				DELETED_SUCCESS: 'Delete message successfully',
				DELETED_FAIL: 'Delete message failed',
				MARK_READ_ALL_SUCCESS: 'Mark read all successfully	',
			},
			NOTICE: {
				CREATED_SUCCESS: 'Create notice successfully',
				CREATED_FAIL: 'Create notice failed',
				DELETED_SUCCESS: 'Delete notice successfully',
				DELETED_FAIL: 'Delete notice failed',
				UPDATED_SUCCESS: 'Update notice successfully',
				UPDATED_FAIL: 'Update notice failed',
			},
			POI_TYPE: {
				CREATED_SUCCESS: 'Create POI type successfully',
				CREATED_FAIL: 'Create POI type failed',
				DELETED_SUCCESS: 'Delete POI type successfully',
				DELETED_FAIL: 'Delete POI type failed',
				UPDATED_SUCCESS: 'Update POI type successfully',
				UPDATED_FAIL: 'Update POI type failed',
				ALREADY_EXIST: 'POI type already exist',
			},
			POI: {
				CREATED_SUCCESS: 'Create Landmarks successfully',
				CREATED_FAIL: 'Create Landmarks failed',
				DELETED_SUCCESS: 'Delete Landmarks successfully',
				DELETED_FAIL: 'Delete Landmarks failed',
				UPDATED_SUCCESS: 'Update Landmarks successfully',
				UPDATED_FAIL: 'Update Landmarks failed',
				NAME_ALREADY_EXIST: 'Landmarks already exist',
				POI_TYPE_NOT_EMPTY: 'Please select Landmarks type',
			},
			GEOFENCE_GROUPS: {
				CREATED_SUCCESS: 'Create geofence group successfully',
				CREATED_FAIL: 'Create geofence group failed',
				DELETED_SUCCESS: 'Delete geofence group successfully',
				DELETED_FAIL: 'Delete geofence group failed',
				UPDATED_SUCCESS: 'Update geofence group successfully',
				UPDATED_FAIL: 'Update geofence group failed',
				ALREADY_EXIST: 'Geofence group already exist',
			},
			TOLL_STATION: {
				CREATED_SUCCESS: 'Create toll station successfully',
				CREATED_FAIL: 'Create toll station failed',
				DELETED_SUCCESS: 'Delete toll station successfully',
				DELETED_FAIL: 'Delete toll station failed',
				UPDATED_SUCCESS: 'Update toll station successfully',
				UPDATED_FAIL: 'Update toll station failed',
				ALREADY_EXIST: 'Toll station already exist',
				TOLL_ROAD_NOT_VALID: 'Please select toll road',
				TYPE_TOLL_STATION_TYPE_TOLL_ROAD_IS_HIGHWAY:
					'Type toll and toll road different',
			},
			USER_MAP_KEY: {
				CREATED_SUCCESS: 'Create map key successfully',
				CREATED_FAIL: 'Create map key failed',
				DELETED_SUCCESS: 'Delete map key successfully',
				DELETED_FAIL: 'Delete map key failed',
				UPDATED_SUCCESS: 'Update map key successfully',
				UPDATED_FAIL: 'Update toll station failed',
				KEY_ALREADY_EXIST: ' Map key already exist',
				TYPE_NOT_EMPTY: 'The key type must not be blank',
				KEY_NOT_VALID: 'Invalid API key',
				CHANGE_STATUS_SUCCESS: 'Map API key status change successful',
				USER_NOT_EMPTY: 'No new user is selected',
				KEY_NOT_EMPTY: 'The key must not be blank',
			},
			CAMERA: {
				CREATED_SUCCESS: 'Create camera successfully',
				CREATED_FAIL: 'Create camera failed',
				DELETED_SUCCESS: 'Delete camera successfully',
				DELETED_FAIL: 'Delete camera failed',
				UPDATED_SUCCESS: 'Update camera successfully',
				UPDATED_FAIL: 'Update camera failed',
				ALREADY_EXIST: 'Camera already exist',
				CAMERA_ID_NOT_VALID: 'Camera id not valid',
				NAME_ALREADY_EXIST: 'Camera name already exist',
				CAMERA_ID_ALREADY_EXIST: 'Camera id already exist',
			},
			TOLL_ROAD: {
				CREATED_SUCCESS: 'Create toll road successfully',
				CREATED_FAIL: 'Create toll road failed',
				DELETED_SUCCESS: 'Delete toll road successfully',
				DELETED_FAIL: 'Delete toll road failed',
				UPDATED_SUCCESS: 'Update toll road successfully',
				UPDATED_FAIL: 'Update toll road failed',
				ALREADY_EXIST: 'Toll road already exist',
				CAN_NOT_UPDATED_TYPE_TOLL_ROAD_ALREADY_EXIST_TOLL_SEGMENT:
					'Road has been assigned to a toll road',
			},
			TOLL_SEGMENT: {
				CREATED_SUCCESS: 'Create toll segment successfully',
				CREATED_FAIL: 'Create toll segment failed',
				DELETED_SUCCESS: 'Delete toll segment successfully',
				DELETED_FAIL: 'Delete toll segment failed',
				UPDATED_SUCCESS: 'Update toll segment successfully',
				UPDATED_FAIL: 'Update toll segment failed',
				ALREADY_EXIST: 'Toll segment already exist',
				TOLL_ROAD_STATION_EXIT_STATION_ALREADY_EXIST:
					'Toll station start and end already exist',
				ENTRY_STATION_EXIT_STATION_ALREADY_EXIST:
					'Entry station and exit station already exist',
			},
			REMIND: {
				CREATED_SUCCESS: 'Create task remind successfully',
				CREATED_FAIL: 'Create task remind failed',
				DELETED_SUCCESS: 'Delete task remind successfully',
				DELETED_FAIL: 'Delete task remind failed',
				UPDATED_SUCCESS: 'Update task remind successfully',
				UPDATED_FAIL: 'Update task remind failed',
				NAME_ALREADY_EXIST: 'Task remind already exist',
			},
			STORAGE: {
				DELETED_SUCCESS: "Delete successful",
				DELETED_FAIL: "Delete fail",
				UPDATED_SUCCESS: "Update successful",
				UPDATED_FAIL: "Update fail",
				CREATED_SUCCESS: "Create successful",
				CREATED_FAIL: "Create fail",
				NAME_ALREADY_EXIST: "Name already exist"
			}
		},
		STREAMAX: {
			STATUS_RESPONSE: {
				SUCCESS: 'Success',
				ILLEGAL_REQUEST: 'Illegal request',
				SERVER_ERROR: 'Server error',
				NO_OPERATION_RIGHTS: 'No operation rights',
				AUTHORIZATION_FAILED: 'Authorization failed',
				ACCOUNT_EXPIRED: 'Account expired',
				USERNAME_OR_PASSWORD_WRONG: 'Username or password wrong',
				REQUEST_PARAMETER_COUNT_ERROR: 'Request parameter count error',
				REQUEST_FORMAT_ERROR: 'Request format error',
				AUTHORIZATION_KEY_NOT_DETECTED:'Authorization key not detected',
				AUTHORIZATION_KEY_WRONG: 'Authorization key wrong',
				PARAMETER_ERROR: 'Parameter error',
				DATABASE_CONNECT_ERROR: 'Database connect error',
				DATABASE_OPERATION_ABNORMAL: 'Database operation abnormal',
				INTERNAL_INTERFACE_PARAMETER_ERROR:'Internal interface parameter error',
				DEVICE_SEARCH_RECORDING_CALENDAR_FAILED:'Device search recording calendar failed',
				DEVICE_IS_NOT_ONLINE: 'Device is not online',
				DEVICE_QUERY_SERVICE_BUSY: 'Device query service busy',
				DEVICE_EXECUTION_FAILED: 'Device execution failed',
				DEVICE_DO_NOT_EXISTS: 'Device do not exist',
				QUERY_DEVICE_FAILED: 'Query device failed',
			},
		},
		DATA: {
			SENSOR: {
				ERROR: 'Undefined value'
			}
		}
	},
};
