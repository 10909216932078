import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class FunctionService {
  constructor(
    // private validate: ValidatorCustomService,
    private translate: TranslateService
  ) { }
  public convertDate(date) {
    if (parseInt(date.month) < 10) {
      date.month = '0' + parseInt(date.month);
    }
    if (parseInt(date.day) < 10) {
      date.day = '0' + parseInt(date.day);
    }
    let dateTime = date.year + '-' + date.month + '-' + date.day;
    return dateTime;
  }

  public convertDateTimeToDatePicker(date: Date) {
    let datePicker = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    }
    return datePicker;
  }

  public convertStringToArray(text: string) {
    if (text == '') return [];
    let list = text.split(',');
    return list;
  }

  public validateListEmail(listEmailString: string) {
    let listEmail = this.convertStringToArray(listEmailString);
    if (listEmail.length > 0) {
      for (var i = 0; i < listEmail.length; i++) {
        if (!this.validateEmail(listEmail[i])) {
          return {
            message: this.translate.instant('COMMON.VALIDATION.INVALID_EMAIL', { email: listEmail[i] }),
            status: true
          };
        }
      }
      return {
        message: '',
        status: false
      };
    }
    return {
      message: '',
      status: false
    };
  }

  private validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
    // return this.validate.checkEmail(email);
  }

  setLocalStorage(key,value){
    localStorage.setItem(key,  JSON.stringify(value));
}

checkLocalStorage(key){
    if (localStorage.getItem(key) === null)
    {
        return false;
    }
    return true;
}

getLocalStorage(key)
{
    return JSON.parse(localStorage.getItem(key));
}

checkDiffInDays(first,last,day)
{
  let firstDate = moment(first);
  let secondDate = moment(last);
  let diffInDays = Math.abs(firstDate.diff(secondDate, 'days')); 
  if(diffInDays > day) return false;
  if(diffInDays <= day) return true;
}

  pagination(dataFull,pageNo,pageSize,step,field?){
    let data = [];
    let start =  (pageNo-1)*pageSize;
    let end   =  pageNo*pageSize;
    if(step ==1 )
    {
      data = dataFull.filter((item,i)=>{
        return i >= start && i<= end;
      });
    }

    return data;
  }
}