
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TypesUtilsService } from '../../@core/models';

const API_URL = environment.api.host;

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private http: HttpClient, private utils: TypesUtilsService) { }
  initConfig() {
    this.http.get(API_URL + '/settings/devices/config').subscribe((data:any) => {
        localStorage.setItem("SYSTEM_CONFIG", JSON.stringify(data.data.data));
    });
  }

  getConfig(){
    return JSON.parse(localStorage.getItem("SYSTEM_CONFIG"));
  }
}

