<form [formGroup]="saveForm" (ngSubmit)="onSubmit(saveForm)">
  <nb-card class="modal-card">
    <nb-card-header>
      <div class="d-flex justify-content-between">
        <h5>
          {{ 'COMMON.USER_INFO.USER_INFO' | translate }}
        </h5>
        <button (click)="dialogRef.close()" class="close" nbButton ghost status="basic"><nb-icon
            icon="close-outline"></nb-icon></button>
      </div>
    </nb-card-header>
    <nb-card-body>
      <nb-tabset>
        <nb-tab tabTitle="{{'COMMON.GENERAL.BASE' | translate}}">
          <div class="row">
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'COMMON.COLUMN.USERNAME' | translate }} <span
                  class="text-danger">*</span></label>
              <input [readOnly]="true" type="text" nbInput fullWidth formControlName="username">
              <ngx-inline-message [message]="'COMMON.COLUMN.USERNAME' | translate"
                [formName]="saveForm.controls.username"></ngx-inline-message>
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.USER.GENERAL.FULL_NAME'|translate }} <span
                  class="text-danger">*</span></label>
              <input type="text" nbInput fullWidth formControlName="name">
              <ngx-inline-message [message]="'MANAGE.USER.GENERAL.FULL_NAME' | translate"
                [formName]="saveForm.controls.name"></ngx-inline-message>
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.USER.GENERAL.PHONE'|translate }}</label>
              <input type="text" pattern="[0-9]*" nbInput fullWidth formControlName="phone">
              <ngx-inline-message [message]="'MANAGE.USER.GENERAL.PHONE' | translate"
                [formName]="saveForm.controls.phone"></ngx-inline-message>
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.USER.GENERAL.EMAIL'|translate }}</label>
              <input type="email" nbInput fullWidth formControlName="email">
              <ngx-inline-message [message]="'MANAGE.USER.GENERAL.EMAIL' | translate"
                [formName]="saveForm.controls.email"></ngx-inline-message>
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'COMMON.COLUMN.DESCRIPTION'|translate }}</label>
              <textarea formControlName="description" rows="5" nbInput fullWidth></textarea>
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'COMMON.COLUMN.ADDRESS'|translate }}</label>
              <textarea formControlName="address" rows="5" nbInput fullWidth></textarea>
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.USER.GENERAL.PAGE_MAIN'|translate }}</label>
              <nb-select formControlName="pageMain" fullWidth>
                <nb-option *ngFor="let page of listPageMain" [value]="page.link">{{page.title | translate}}</nb-option>
              </nb-select>
            </div>

          </div>
        </nb-tab>
        <nb-tab tabTitle="{{'COMMON.GENERAL.PARAMETER' | translate}}">
          <div class="row">
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.USER.PARAMETER.TIMEZONE'|translate }}</label>
              <nb-select formControlName="timezone" fullWidth>
                <nb-option *ngFor="let item of listTimeZone" [value]="item.timeZone">{{item.timeZone}}
                  ({{item.GMTOffset}})</nb-option>
              </nb-select>
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.USER.PARAMETER.LANGUAGE'|translate }}</label>
              <nb-select formControlName="language" fullWidth>
                <nb-option *ngFor="let language of languageList" [value]="language.key">{{language.name}}</nb-option>
              </nb-select>
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.USER.PARAMETER.DISTANCE'|translate }}</label>
              <nb-select formControlName="distance" fullWidth>
                <nb-option *ngFor='let distance of unitDistance' [value]="distance.id">{{distance.name |
                  translate}}</nb-option>
              </nb-select>
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.USER.PARAMETER.VOLUME'|translate }}</label>
              <nb-select formControlName="volume" fullWidth>
                <nb-option *ngFor='let volume of unitVolume' [value]="volume.id">{{volume.name | translate}}</nb-option>
              </nb-select>
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.USER.PARAMETER.TEMPERATURE'|translate }}</label>
              <nb-select formControlName="temperature" fullWidth>
                <nb-option *ngFor='let temperature of unitTemperature' [value]="temperature.id">{{temperature.name |
                  translate}}</nb-option>
              </nb-select>
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.USER.PARAMETER.WEIGHT'|translate }}</label>
              <nb-select formControlName="weight" fullWidth>
                <nb-option *ngFor='let weight of unitWeight' [value]="weight.id">{{weight.name | translate}}</nb-option>
              </nb-select>
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.USER.PARAMETER.DATE_FORMAT'|translate }}</label>
              <nb-select formControlName="date" fullWidth>
                <nb-option *ngFor='let date of dateFormat' [value]="date.type">{{date.name | translate}}</nb-option>
              </nb-select>
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.USER.PARAMETER.TIME_FORMAT'|translate }}</label>
              <nb-select formControlName="time" fullWidth>
                <nb-option *ngFor='let time of timeFormat' [value]="time.type">{{time.name | translate}}</nb-option>
              </nb-select>
            </div>
          </div>
        </nb-tab>
      </nb-tabset>
    </nb-card-body>
    <nb-card-footer>
      <div class="justify-content-end d-flex">
        <button [status]="'primary'" type="submit" nbButton [disabled]="saveForm.invalid" class="mr-2">
          {{ 'COMMON.ACTIONS.SAVE_CHANGES' | translate }}
        </button>
        <button type="button" nbButton class="btn btn-secondary" (click)="dialogRef.close()">{{
          'COMMON.ACTIONS.CANCEL' | translate }}
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</form>